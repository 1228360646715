import * as React from "react";
import { useState } from "react";
import { Card, CardContent } from "@mui/material";
import { Title } from "react-admin";
import { useSync, usePrismicSync, usePublish, useAmplifyPrdRebuild, useAmplifyAccRebuild } from "./../hooks/useSync";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { __ENV__, ENV, processENV } from "../api";

const Settings = () => {
  const mutateSyncStripe = useSync();
  const mutateSyncPrismic = usePrismicSync();
  const mutatePublish = usePublish();
  const mutateAmplifyAccRebuild = useAmplifyAccRebuild();
  const mutateAmplifyPrdRebuild = useAmplifyPrdRebuild();

  const [syncStripe, setSyncStripe] = useState({
    v2ProductsSync: {
      productSynced: 0,
    },
    pricesSync: {
      pricesSynced: 0,
    },
    taxRateSync: {
      taxRateSynced: 0,
    },
    v2BundleSync: {
      bundleSynced: 0,
    },
  });

  const syncStripeHandle = () => {
    setSyncStripe({
      v2ProductsSync: {
        productSynced: 0,
      },
      pricesSync: {
        pricesSynced: 0,
      },
      taxRateSync: {
        taxRateSynced: 0,
      },
      v2BundleSync: {
        bundleSynced: 0,
      },
    });
    mutateSyncStripe.mutate(
      {},
      {
        onSuccess: (data) => {
          setSyncStripe(data);
        },
      }
    );
  };
  const [syncPrismic, setSyncPrismic] = useState({
    prismicProductsSync: {
      productsSynced: 0,
    },
    prismicBundlesSync: {
      bundlesSynced: 0,
    },
  });

  const syncPrismicHandle = () => {
    setSyncPrismic({
      prismicProductsSync: {
        productsSynced: 0,
      },
      prismicBundlesSync: {
        bundlesSynced: 0,
      },
    });
    mutateSyncPrismic.mutate(
      {},
      {
        onSuccess: (data) => {
          setSyncPrismic(data);
        },
      }
    );
  };
  const [publish, setPublish] = useState({
    v2BundleSync: {
      bundleSynced: 0,
    },
    v2Publish: {
      productPublished: 0,
      bundlePublished: 0,
    },
  });

  const publishHandle = () => {
    setPublish({
      v2BundleSync: {
        bundleSynced: 0,
      },
      v2Publish: {
        productPublished: 0,
        bundlePublished: 0,
      },
    });
    mutatePublish.mutate(
      {},
      {
        onSuccess: (data) => {
          setPublish(data);
        },
      }
    );
  };

  const [responseAcc, setResponseAcc] = useState("");
  const [responsePrd, setResponsePrd] = useState("");
  const amplifyAccRebuildHandle = () => {
    setResponseAcc("");
    mutateAmplifyAccRebuild.mutate(
      {},
      {
        onSuccess: (data) => {
          setResponseAcc(data.amplifyAccRebuild.response);
        },
      }
    );
  };
  const amplifyPrdRebuildHandle = () => {
    setResponsePrd("");
    mutateAmplifyPrdRebuild.mutate(
      {},
      {
        onSuccess: (data) => {
          setResponsePrd(data.amplifyPrdRebuild.response);
        },
      }
    );
  };
  return (
    <div style={{ margin: "20px 0px 0 0" }}>
      <Card>
        <Title title="Playbrush Store" />
        <CardContent>
          <h1>Settings</h1>
          <Typography variant="body1">REACT_APP_KIDS_API_URL:{processENV.REACT_APP_KIDS_API_URL}</Typography>
          <Typography variant="body1">REACT_APP_SITE_URL:{processENV.REACT_APP_SITE_URL}</Typography>
          <Typography variant="body1">REACT_APP_DEV:{processENV.REACT_APP_DEV}</Typography>
          <Typography variant="body1">REACT_APP_ENV_NAME:{processENV.REACT_APP_ENV_NAME}</Typography>
          {/* <Typography variant="body1">
            REACT_APP_STRIPE_CLIENT_SECRET:{processENV.REACT_APP_STRIPE_CLIENT_SECRET}
          </Typography> */}
        </CardContent>
      </Card>
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Typography variant="body1"></Typography>
          {mutateSyncStripe.isLoading ? (
            <Button variant="contained">...</Button>
          ) : (
            <Button variant="contained" onClick={syncStripeHandle}>
              Start Sync Stripe
            </Button>
          )}
          <Typography variant="h4">Results</Typography>
          <Typography variant="h5">productsSync: {syncStripe.v2ProductsSync.productSynced}</Typography>
          <Typography variant="h5">pricesSync: {syncStripe.pricesSync.pricesSynced}</Typography>
          <Typography variant="h5">taxRateSync: {syncStripe.taxRateSync.taxRateSynced}</Typography>
          <Typography variant="h5">bundleSync: {syncStripe.v2BundleSync.bundleSynced}</Typography>
        </CardContent>
      </Card>
      {/* {(__ENV__ === ENV.PRODUCTION || __ENV__ === ENV.DEV) && (
        <Card sx={{ mt: 5 }}>
          <CardContent>
            <>
              {mutateSyncPrismic.isLoading ? (
                <Button variant="contained">...</Button>
              ) : (
                <Button variant="contained" onClick={syncPrismicHandle}>
                  Start Sync Prismic
                </Button>
              )}
              <Typography variant="h4">Results</Typography>
              <Typography variant="h5">productsSync: {syncPrismic.prismicProductsSync.productsSynced}</Typography>
              <Typography variant="h5">bundlesSync: {syncPrismic.prismicBundlesSync.bundlesSynced}</Typography>
            </>
          </CardContent>
        </Card>
      )} */}
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Button variant="contained" onClick={publishHandle}>
            Publish Products and Bundles
          </Button>
          <Typography variant="h4">Results</Typography>
          <Typography variant="h5">bundleSynced: {publish.v2BundleSync.bundleSynced}</Typography>
          <Typography variant="h5">bundlePublished: {publish.v2Publish.bundlePublished}</Typography>
          <Typography variant="h5">productPublished: {publish.v2Publish.productPublished}</Typography>
        </CardContent>
      </Card>
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Button variant="contained" onClick={amplifyAccRebuildHandle}>
            Trigger ACC rebuild
          </Button>
          <Typography variant="h4">Results</Typography>
          <Typography variant="h5">Response Status:{responseAcc}</Typography>
        </CardContent>
      </Card>
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Button variant="contained" onClick={amplifyPrdRebuildHandle}>
            Trigger PRD rebuild
          </Button>
          <Typography variant="h4">Results</Typography>
          <Typography variant="h5">Response Status:{responsePrd}</Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Settings;
