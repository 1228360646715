import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { request, gql, ClientError, GraphQLClient } from "graphql-request";
import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
import moment from "moment";

// export function useCheckoutSessionCreate(): UseMutationResult<
//   GQL.CheckoutSessionCreate,
//   ClientError,
//   GQL.MutationCheckoutSessionCreateArgs
// > {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`
//             mutation( $itemList: [LineItemInput]!){
//               checkoutSessionCreate(customerId: "${dataMutation.customerId}", listItem: $itemList ) {
//                 id
//                 paymentIntent
//                 amountSubtotal
//                 amountTotal
//                 success
//                 clientSecret
//               }
//             }
//         `,
//         { itemList: dataMutation.listItem }
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     }
//     // ,{
//     //   onSuccess: (data, variables, context) => {},
//     //   onError: (error: ClientError, variables, context) => {},
//     // }
//   );
// }

// export function useOrder(): UseMutationResult<GQL.Order, ClientError, GQL.MutationOrderArgs> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`
//             mutation( $itemList: [LineItemInput]!){
//               order(
//                 currency: "${dataMutation.currency}"
//                 orderId: "${dataMutation.orderId}"
//                 customerId: "${dataMutation.customerId}",
//                 listItem: $itemList
//                 couponCode: "${dataMutation.couponCode}"
//               ) {
//                 orderId
//                 currency
//                 amountSubtotal
//                 amountTotal
//                 amountDiscount
//                 amountShipping
//                 amountTax
//               }
//             }
//         `,
//         { itemList: dataMutation.listItem }
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     }
//     // ,{
//     //   onSuccess: (data, variables, context) => {},
//     //   onError: (error: ClientError, variables, context) => {},
//     // }
//   );
// }

// export function useOrderSubmit(): UseMutationResult<GQL.OrderSubmit, ClientError, GQL.MutationOrderSubmitArgs> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`
//             mutation{
//               orderSubmit(
//                 orderId: "${dataMutation.orderId}"
//               ) {
//                 clientSecret
//               }
//             }
//         `,
//         {}
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     }
//     // ,{
//     //   onSuccess: (data, variables, context) => {},
//     //   onError: (error: ClientError, variables, context) => {},
//     // }
//   );
// }

export function useCart(): UseMutationResult<GQL.Cart, ClientError, GQL.MutationCartArgs> {
  return useMutation(
    async (dataMutation) => {
      const { cart: data } = await graphQLClient.request(
        gql`
            mutation( $itemList: [LineItemInput]){
              cart(
                currency: "${dataMutation.currency}"
                country: "${dataMutation.country}"
                cartId: "${dataMutation.cartId}"
                customerId: "${dataMutation.customerId}",
                lineItems: $itemList 
                couponCode: "${dataMutation.couponCode}"
                language: "${dataMutation.language}"
                emailType: "${dataMutation.emailType}"
              ) {
                cartId
                currency
                currencySymbol
                couponCode
                customerId
                lineItems {
                  currencySymbol
                  lineId
                  image
                  isSubscription
                  lineContent{
                    currencySymbol
                    lineId
                    image
                    priceAmount
                    priceDefaultAmount
                    priceAmountAfterTrial
                    priceOriginalAfterTrial
                    recurringInterval
                    recurringIntervalCount
                    recurringTrialPeriodDays
                    product
                    productName
                    productNameOrder
                    productNameMarketing
                    infoExplainer
                    infoDelivery
                    infoPayment
                    description
                    quantity
                    isSubscription
                    tags
                  }
                  priceAmount
                  priceDefaultAmount
                  priceAmountOneTime
                  priceAmountRecurring
                  priceOriginalOneTime
                  priceOriginalRecurring
                  priceAmountAfterTrial
                  priceOriginalAfterTrial
                  recurringInterval
                  recurringIntervalCount
                  recurringTrialPeriodDays
                  product
                  productName
                  productNameOrder
                  productNameMarketing
                  infoExplainer
                  infoDelivery
                  infoPayment
                  description
                  quantity
                  tags
                }
                amountSubtotal
                amountTotal
                amountDiscount
                amountShipping
                amountTax
                language
                hasSubscription
                country
                taxRate
              }
            }
        `,
        { itemList: dataMutation.lineItems }
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}

export function useCheckoutOpen(): UseMutationResult<GQL.CheckoutOpen, ClientError, GQL.MutationCheckoutOpenArgs> {
  return useMutation(
    async (dataMutation) => {
      const { checkoutOpen: data } = await graphQLClient.request(
        gql`
            mutation{
              checkoutOpen(
                cartId: "${dataMutation.cartId}"
              ) {
                checkoutId
                currency
                currencySymbol
                couponCode
                customerId
                lineItems {
                  currencySymbol
                  lineId
                  image
                  isSubscription
                  lineContent{
                    currencySymbol
                    lineId
                    image
                    priceAmount
                    priceDefaultAmount
                    priceAmountAfterTrial
                    priceOriginalAfterTrial
                    recurringInterval
                    recurringIntervalCount
                    recurringTrialPeriodDays
                    product
                    productName
                    productNameOrder
                    productNameMarketing
                    description
                    quantity
                    isSubscription
                    tags
                  }
                  frontendLineData
                  priceAmount
                  priceDefaultAmount
                  priceAmountOneTime
                  priceAmountRecurring
                  priceOriginalOneTime
                  priceOriginalRecurring
                  priceAmountAfterTrial
                  priceOriginalAfterTrial
                  recurringInterval
                  recurringIntervalCount
                  recurringTrialPeriodDays
                  product
                  productName
                  productNameOrder
                  productNameMarketing
                  description
                  quantity
                  tags
                }
                amountSubtotal
                amountTotal
                amountDiscount
                amountShipping
                amountTax
                language
                hasSubscription
                country
                taxRate
                checkoutMessage
              }
            }
        `,
        {}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}

export function useCheckoutUpdate(): UseMutationResult<
  GQL.CheckoutUpdate,
  ClientError,
  GQL.MutationCheckoutUpdateArgs
> {
  return useMutation(
    async (dataMutation) => {
      const { checkoutUpdate: data } = await graphQLClient.request(
        gql`
            mutation($isNewsletterChecked : Boolean!){
              checkoutUpdate(
                checkoutId: "${dataMutation.checkoutId}"
                customerId: "${dataMutation.customerId}"
                isNewsletterChecked: $isNewsletterChecked
              ) {
                checkoutId
                currency
                currencySymbol
                couponCode
                customerId
                lineItems {
                  currencySymbol
                  lineId
                  image
                  isSubscription
                  lineContent{
                    currencySymbol
                    lineId
                    image
                    priceAmount
                    priceDefaultAmount
                    priceAmountAfterTrial
                    priceOriginalAfterTrial
                    recurringInterval
                    recurringIntervalCount
                    recurringTrialPeriodDays
                    product
                    productName
                    productNameOrder
                    productNameMarketing
                    description
                    quantity
                    isSubscription
                    tags
                  }
                  frontendLineData
                  priceAmount
                  priceDefaultAmount
                  priceAmountOneTime
                  priceAmountRecurring
                  priceOriginalOneTime
                  priceOriginalRecurring
                  priceAmountAfterTrial
                  priceOriginalAfterTrial
                  recurringInterval
                  recurringIntervalCount
                  recurringTrialPeriodDays
                  product
                  productName
                  productNameOrder
                  productNameMarketing
                  description
                  quantity
                  tags
                }
                amountSubtotal
                amountTotal
                amountDiscount
                amountShipping
                amountTax
                language
                hasSubscription
                country
                taxRate
              }
            }
        `,
        {isNewsletterChecked: dataMutation.isNewsletterChecked}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}
export function useCheckoutStatus(): UseMutationResult<
  GQL.CheckoutStatus,
  ClientError,
  GQL.MutationCheckoutStatusArgs
> {
  return useMutation(
    async (dataMutation) => {
      const { checkoutStatus: data } = await graphQLClient.request(
        gql`
            mutation{
              checkoutStatus(
                checkoutId: "${dataMutation.checkoutId}"
              ) {
                status
                orderId
                thankYouPage
              }
            }
        `,
        {}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}

export function useCheckoutSubmit(): UseMutationResult<
  GQL.CheckoutSubmit,
  ClientError,
  GQL.MutationCheckoutSubmitArgs
> {
  return useMutation(
    async (dataMutation) => {
      const { checkoutSubmit: data } = await graphQLClient.request(
        gql`
            mutation{
              checkoutSubmit(
                checkoutId: "${dataMutation.checkoutId}"
              ) {
                stripeClientSecret
                paypalClientToken
                hasSubscription
              }
            }
        `,
        {}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}


export function useCheckoutExpressSubmit(): UseMutationResult<
  GQL.CheckoutExpressSubmit,
  ClientError,
  GQL.MutationCheckoutExpressSubmitArgs
> {
  return useMutation(
    async (dataMutation) => {
      const { checkoutExpressSubmit: data} = await graphQLClient.request(
        gql`
            mutation($address:AddressInput, $shipping:ShippingAddressInput, $metadata: JSONString){
              checkoutExpressSubmit(
                checkoutId: "${dataMutation.checkoutId}"
                email:"${dataMutation.email}"
                name:"${dataMutation.name}"
                description:"${dataMutation.description}"
                address: $address
                shipping: $shipping
                phone:"${dataMutation.phone}"
                currency:"${dataMutation.currency}"
                language:"${dataMutation.language}"
                metadata: $metadata
                firstName:"${dataMutation.firstName}"
                lastName:"${dataMutation.lastName}"
                companyName:"${dataMutation.companyName}"
                expressPaymentType:"${dataMutation.expressPaymentType}"
              ) {
                stripeClientSecret
                hasSubscription
                paymentIntentType
                expressPaymentType
              }
            }
        `,
        { address: dataMutation.address,shipping: dataMutation.shipping,metadata: dataMutation.metadata }
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}


export function useCheckoutExpressShippingAddressChange(): UseMutationResult<
  GQL.CheckoutExpressShippingAddressChange,
  ClientError,
  GQL.MutationCheckoutExpressShippingAddressChangeArgs
> {
  return useMutation(
    async (dataMutation) => {
      const { checkoutExpressShippingAddressChange: data } = await graphQLClient.request(
        gql`
            mutation{
              checkoutExpressShippingAddressChange(
                checkoutId: "${dataMutation.checkoutId}"
                country: "${dataMutation.country}"
              ) {
                  amountShipping
                  amountTotal
                  shippingRates {
                    id
                    amount
                    displayName
                    deliveryEstimate {
                      minimum {
                        value
                        unit
                      }
                      maximum {
                        value
                        unit
                      }
                    }
                  }
              }
            }
        `,
        {}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}

export function usePaypalSessionPay(): UseMutationResult<
  GQL.PaypalSessionPay,
  ClientError,
  GQL.MutationPaypalSessionPayArgs
> {
  return useMutation(
    async (dataMutation) => {
      const { paypalSessionPay: data } = await graphQLClient.request(
        gql`
            mutation{
              paypalSessionPay(
                checkoutId: "${dataMutation.checkoutId}"
                paymentMethodNonce: "${dataMutation.paymentMethodNonce}"
              ) {
                errorMessage
                status
                transactionId
              }
            }
        `,
        {}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}
