import {
  AutocompleteArrayInput,
  ChipField,
  Datagrid,
  Edit,
  EditButton,
  EmailField,
  FunctionField,
  ImageField,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  ShowBase,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRedirect,
} from "react-admin";
import Currency from "../components/currency";
import React from "react";

const MyToolbar = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props}>
      <SaveButton
      // label="Save"
      // mutationOptions={{
      //   onSuccess: (data) => {
      //     notify("ra.notification.created", {
      //       type: "info",
      //       messageArgs: { smart_count: 1 },
      //     });
      //     redirect(false);
      //   },
      // }}
      // type="button"
      // variant="text"
      />
    </Toolbar>
  );
};

const priceFilters = [
  <TextInput label="ID" source="pk" alwaysOn />,
  <TextInput label="Product Id" source="product" alwaysOn />,
  <TextInput label="Trial Days" source="recurringTrialPeriodDays" alwaysOn />,
  <SelectInput
    label="Currency"
    source="currency__icontains"
    choices={[
      { id: "EUR", name: "EUR" },
      { id: "GBP", name: "GBP" },
      { id: "USD", name: "USD" },
    ]}
    alwaysOn
  />,
];

export const PriceList = () => (
  <List filters={priceFilters}>
    <Datagrid>
      <TextField label="Price Id" source="priceId" />
      <TextField label="Product Id" source="product.productId" />
      <ImageField
        label="Product Image"
        sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }}
        source="product.image"
      />
      <TextField label="Product Name" source="product.name" />
      <TextField label="Trial period days" source="recurringTrialPeriodDays" />
      <FunctionField
        label="Price"
        render={(record: { unitAmount: number; currency: string }) => {
          return <Currency price={record.unitAmount} currency={record.currency} />;
        }}
      />
      <EditButton />
    </Datagrid>
  </List>
);

export const PriceEdit = () => (
  <>
    <Edit>
      <SimpleForm toolbar={<MyToolbar />}>
        <SimpleShowLayout>
          <TextField source="priceId" />
          <TextField source="type" />
          <TextField source="unitAmount" />
          {/* <ReferenceField source="product" reference="products">
            <TextField source="productId" />
          </ReferenceField> */}
        </SimpleShowLayout>

        <TextInput label="Trial period days" source="recurringTrialPeriodDays" />
        {/* <ReferenceArrayInput source="tags" reference="productTags">
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput> */}
      </SimpleForm>
    </Edit>
  </>
);
