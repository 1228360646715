import {
  DatagridConfigurable,
  FilterButton,
  FunctionField,
  List,
  SearchInput,
  SelectColumnsButton,
  ShowButton,
  TextField,
  TopToolbar,
  WrapperField,
} from "react-admin";

import LocalisedDateField from "../components/localisedDateField";
import StartDateEndDateInput from "../components/startDateEndDateInput";
import grapheneDataProvider from "../providers/grapheneDataProvider2";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const userFilters = [
  <SearchInput source="email__icontains" alwaysOn={true} placeholder="Email" />,
  <SearchInput source="firstName__icontains" placeholder="First Name" alwaysOn={true} />,
  <SearchInput source="lastName__icontains" placeholder="Last Name" alwaysOn={true} />,
  <SearchInput source="oldEmail" placeholder="Old Email" />,
  <StartDateEndDateInput source="dateRegistrationStarted" />,
  <StartDateEndDateInput source="subscriptionStart" />,
  <StartDateEndDateInput source="subscriptionEnd" />,
];

const UsersList = (props: any) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      dataProvider={grapheneDataProvider}
      filters={userFilters}
      title={"Customers"}
      sort={{ field: "dateRegistrationStarted", order: "DESC" }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="countryEmoji" sx={{ fontSize: "24px" }} label="Country" />
        <TextField source="language" />
        <FunctionField
          label="Subscription Start"
          render={(record: any) => {
            return <LocalisedDateField record={record} field="subscriptionStart" compact />;
          }}
        />
        <FunctionField
          label="Subscription End"
          render={(record: any) => {
            return <LocalisedDateField record={record} field="subscriptionEnd" compact />;
          }}
        />
        <FunctionField
          label="Created"
          render={(record: any) => {
            return <LocalisedDateField record={record} field="dateRegistrationStarted" compact />;
          }}
        />
        <FunctionField
          label="Last Login"
          render={(record: any) => {
            return <LocalisedDateField record={record} field="lastLoginAt" compact />;
          }}
        />
        <WrapperField label="Actions">
          <ShowButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export default UsersList;
