import React, { useContext, useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import "../css/checkout.css";
import { useStateMachine } from "little-state-machine";
import { addPaymentInfoTrackingEvent } from "./trackingEvents";
import { __DEV__, processENV } from "../api";
import { CheckoutContext } from "../context/checkoutContext";
import { StripeElementLocale, StripeElementsUpdateOptions, StripeError, StripePaymentElement } from "@stripe/stripe-js";
import { stripePaymentElementOptions } from "../themes";
import { LoaderWrapper } from "../screens/checkout";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { isValidStripeElementLocale } from "./utils";

const StripeForm = ({
  payNowButtonRef,
  isLoadingPayNowConfirm,
  setIsLoadingPayNowConfirm,
}: {
  payNowButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  isLoadingPayNowConfirm: boolean;
  setIsLoadingPayNowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { state } = useStateMachine();
  let cartData = useContext(CheckoutContext);
  const theme = useTheme();

  // const [message, setMessage] = (useState < null) | (String > null);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      elements &&
      state.data.cartData &&
      state.data.cartData.language &&
      state.data.cartData.language !== undefined &&
      isValidStripeElementLocale(state.data.cartData.language.toLowerCase())
    ) {
      const elementOptions: StripeElementsUpdateOptions = {
        locale: state.data.cartData.language.toLowerCase() as StripeElementLocale,
      };
      elements.update(elementOptions);
    }

    if (elements) {
      const elementOptions: StripeElementsUpdateOptions = {
        appearance: {
          variables: {
            colorPrimary: theme.palette.secondary.main,
            colorBackground: theme.palette.background.paper,
            colorText: theme.palette.text.primary,
          },
        },
      };
      elements.update(elementOptions);
    }
  }, [elements, theme]);

  useEffect(() => {
    if (__DEV__) {
      console.log("STRIPE", stripe);
    }
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      switch (setupIntent?.status) {
        case "succeeded":
          setMessage("Success! Your payment method has been saved.");
          break;

        case "processing":
          setMessage("Processing payment details. We'll update you when processing is complete.");
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage("Failed to process payment details. Please try another payment method.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoadingPayNowConfirm(true);

    addPaymentInfoTrackingEvent(cartData, "Card");
    // if (cart_has_subscription) {
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.origin + "/checkout/" + state.data.checkoutId + "/processing",
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      setMessage(error.message || "");
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoadingPayNowConfirm(false);
  };

  const onReady = (element: StripePaymentElement) => {
    setIsLoading(false);
  };
  const onLoadError = (event: { elementType: "payment"; error: StripeError }) => {
    setMessage(event.error.message || "");
    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ position: "relative" }}>
      {isLoading && (
        <LoaderWrapper sx={{ position: "absolute" }}>
          <CircularProgress />
        </LoaderWrapper>
      )}
      <Box sx={{ minHeight: "300px" }}>
        <PaymentElement
          id="payment-element"
          options={stripePaymentElementOptions}
          onReady={onReady}
          onLoadError={onLoadError}
        />
      </Box>
      <button
        disabled={isLoadingPayNowConfirm || !stripe || !elements}
        id="submit"
        ref={payNowButtonRef}
        style={{ display: "none" }}
      >
        <span id="button-text">
          {isLoadingPayNowConfirm ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default StripeForm;
