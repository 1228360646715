import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { gql, ClientError } from "graphql-request";
import { graphQLClient, __DEV__ } from "../api";

export function useOrderInfo(): UseMutationResult<GQL.OrderInfo, ClientError, GQL.MutationOrderInfoArgs> {
  return useMutation(
    async (dataMutation) => {
      const { orderInfo: data } = await graphQLClient.request(
        gql`
            mutation {
              orderInfo(
                orderId: "${dataMutation.orderId}",
                checkoutId: "${dataMutation.checkoutId}"
              ) {
                    cart {
                        checkoutId
                        orderId
                        currency
                        currencySymbol
                        couponCode
                        customerId
                        lineItems {
                          currencySymbol
                          description
                          frontendLineData
                          image
                          infoDelivery
                          infoExplainer
                          infoPayment
                          isSubscription
                          lineContent {
                            currencySymbol
                            description
                            frontendLineData
                            image
                            infoDelivery
                            infoExplainer
                            infoPayment
                            isSubscription
                            lineId
                            priceAmount
                            priceAmountAfterTrial
                            priceAmountOneTime
                            priceAmountRecurring
                            priceDefaultAmount
                            priceOriginalAfterTrial
                            priceOriginalOneTime
                            priceOriginalRecurring
                            product
                            productName
                            productNameMarketing
                            productNameOrder
                            quantity
                            recurringInterval
                            recurringIntervalCount
                            recurringTrialPeriodDays
                            tags
                          }
                          lineId
                          priceAmount
                          priceAmountAfterTrial
                          priceAmountOneTime
                          priceAmountRecurring
                          priceDefaultAmount
                          priceOriginalAfterTrial
                          priceOriginalOneTime
                          priceOriginalRecurring
                          product
                          productName
                          productNameMarketing
                          productNameOrder
                          quantity
                          recurringInterval
                          recurringIntervalCount
                          recurringTrialPeriodDays
                          tags
                        }
                        amountSubtotal
                        amountTotal
                        amountDiscount
                        amountShipping
                        amountTax
                        language
                    }
                }
            }
        `,
        {}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}

type dataOrderChartArgs = {
  dateFrom: string;
  dateTo: string;
};

export function useOrdersCharts(
  dataOrderChartArgs: dataOrderChartArgs,
  onSuccessCustom?: (data: [GQL.Orders]) => void
): UseQueryResult<[GQL.Orders], ClientError> {
  return useQuery(
    "orderChart",
    async () => {
      const { orders: data } = await graphQLClient.request(
        gql`
            query {
              orders(
                limit:10000
                where: [
                        {
                          field: "created_at__gte",
                          value: "\\"${dataOrderChartArgs.dateFrom}\\""
                        },
                        {
                          field: "created_at__lte",
                          value: "\\"${dataOrderChartArgs.dateTo}\\""
                        }
                      ], orderBy:
                        [
                        {
                          FieldName: "createdAt",
                          SortOrder: "asc"
                        }
                      ]
              ) {
                  createdAt
                  amountTotal
                  currency
                  orderItems {
                    productId
                    quantity
                  }
                  shippingItemsBrushCount
                  shippingItemsBrushHeadCount
                  shippingItemsGamesSubCount
                  shippingItemsToothPasteCount
                }
            }
        `,
        {}
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
    // ,{
    //   onSuccess: (data, variables, context) => {},
    //   onError: (error: ClientError, variables, context) => {},
    // }
  );
}
