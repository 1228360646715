import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    UseQueryResult,
  } from "react-query";
  import { request, gql, ClientError, GraphQLClient } from "graphql-request";
  import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
  import moment from "moment";
  
  export function useLocales(
    onSuccessCustom?: (data: [GQL.LocaleModel]) => void
  ): UseQueryResult<[GQL.LocaleModel], ClientError> {
    return useQuery(
      "locales",
      async () => {
        const { locales: data } = await graphQLClient.request(
          gql`
            query {
                locales {
                    id
                    active
                    alComment
                    # alUser
                    country
                    # createdAt
                    defaultCurrency
                    defaultLanguage
                    # name
                    supportedCurrencies
                    supportedLanguages
                    # updatedAt
                    countryEmoji
                    countryFull
                    defaultLanguageFull
                    defaultCurrencyFull
                }
            }
          `
        );
        if (__DEV__) {
          console.log(data);
        }
        return data;
      },
      {
        onSuccess: (data: any) => {
          if (onSuccessCustom !== undefined) onSuccessCustom!(data);
        },
      }
    );
  }
  
  // export function useArticlesCreate(
  //   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
  // ): UseMutationResult<GQL.IArticlesCreate, ClientError, GQL.IArticlesCreateOnMutationArguments> {
  //   return useMutation(
  //     async (dataMutation) => {
  //       const data = await graphQLClient.request(
  //         gql`
  
  //             mutation{
  //               articlesCreate(articleDate: "${moment(dataMutation.articleDate).format("YYYYMMDD")}",
  //               companyId: "${dataMutation.companyId}",
  //               description: """${dataMutation.description}""",
  //               documentUrl: "${dataMutation.documentUrl}",
  //               isPrivate: ${dataMutation.isPrivate}){
  //                 success
  //               }
  //             }
  //         `
  //       );
  //       if (__DEV__) {
  //         console.log(data);
  //       }
  //       return data;
  //     },
  //     {
  //       onSuccess: (data, variables, context) => {
  //         articlesList.refetch();
  //       },
  //       onError: (error: ClientError, variables, context) => {},
  //     }
  //   );
  // }
  
  // export function useArticlesDelete(
  //   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
  // ): UseMutationResult<GQL.IArticlesDelete, ClientError, GQL.IArticlesDeleteOnMutationArguments> {
  //   return useMutation(
  //     async (dataMutation) => {
  //       const data = await graphQLClient.request(
  //         gql`
  //           mutation{
  //             articlesDelete(articlesUuid: "${dataMutation.articlesUuid}"){
  //               success
  //             }
  //           }
  //         `
  //       );
  //       if (__DEV__) {
  //         console.log(data);
  //       }
  //       return data;
  //     },
  //     {
  //       onSuccess: (data, variables, context) => {
  //         articlesList.refetch();
  //       },
  //       onError: (error: ClientError, variables, context) => {},
  //     }
  //   );
  // }
  