export const GetEmailRegex = () => {
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
};

export const GetZipRegex = (countryCode: string) => {
  const ZIPCODES_REGEX = {
    GB: "/AGIR ?0AA|((([A-Z]d{1,2})|(([A-Z][A-HJ-Y]d{1,2})|(([A-Z]d[A-Z])|([A-Z][A-HJ-Y]d?[A-Z])))) ?d[A-Z]{2})z/i",
    UK: "/AGIR ?0AA|((([A-Z]d{1,2})|(([A-Z][A-HJ-Y]d{1,2})|(([A-Z]d[A-Z])|([A-Z][A-HJ-Y]d?[A-Z])))) ?d[A-Z]{2})z/i",
    // GB: /\AGIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}\z/i,
    // UK: /\A([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)\z/,
    JE: "/AJEd[dA-Z]?[ ]?d[ABD-HJLN-UW-Z]{2}z/i",
    GG: "/AGYd[dA-Z]?[ ]?d[ABD-HJLN-UW-Z]{2}z/i",
    IM: "/AIMd[dA-Z]?[ ]?d[ABD-HJLN-UW-Z]{2}z/i",
    US: "/Ad{5}([ -](?:d{4}|d{6}))?z/",
    IE: "/A([AC-FHKNPRTV-Y]d{2}|D6W)s?[0-9AC-FHKNPRTV-Y]{4}z/",
    CA: "/A[ABCEGHJKLMNPRSTVXY]d[ABCEGHJ-NPRSTV-Z][ ]?d[ABCEGHJ-NPRSTV-Z]dz/",
    DE: "/Ad{5}z/",
    AU: "/Ad{4}z/",
    IT: "/Ad{5}z/",
    CH: "/Ad{4}z/",
    AT: "/Ad{4}z/",
    ES: "/Ad{5}z/",
    NL: "/A[1-9]d{3}[ ]?(S[BCE-RT-Z]|[A-RT-Z][A-Z])z/",
    BE: "/Ad{4}z/",
    DK: "/Ad{4}z/",
    NO: "/Ad{4}z/",
    FI: "/Ad{5}z/",
    AX: "/A22d{3}z/",
    KR: "/A(d{5}|d{3}[-]d{3})z/",
    CN: "/Ad{6}z/",
    SG: "/Ad{6}z/",
    DZ: "/Ad{5}z/",
    AD: "/AADd{3}z/",
    AR: "/A[A-HJ-NP-Z]{1}d{4}([A-Z]{3})?z/",
    AM: "/A(37)?d{4}z/",
    BH: "/A(1[0-2]|[1-9])d{2}z/",
    BD: "/Ad{4}z/",
    BB: "/ABB([1-2]d{4}|15156667)z/",
    BY: "/Ad{6}z/",
    BM: "/A[A-Z]{2}[ ]?[A-Z0-9]{2}z/",
    BA: "/Ad{5}z/",
    BN: "/A[BKTP][A-Z][ ]?d{4}z/i",
    BG: "/Ad{4}z/",
    KH: "/Ad{5}z/",
    CV: "/Ad{4}z/",
    CL: "/Ad{3}[-]?d{4}z/",
    HR: "/Ad{5}z/",
    CY: "/Ad{4}z/",
    CZ: "/A[1-7][0-9]{2}[ ]?d{2}z/",
    DO: "/Ad{5}z/",
    EC: "/Ad{6}z/",
    EE: "/Ad{5}z/",
    FO: "/Ad{3}z/",
    GE: "/Ad{4}z/",
    GL: "/A39d{2}z/",
    GT: "/Ad{5}z/",
    HT: "/Ad{4}z/",
    HU: "/Ad{4}z/",
    IS: "/Ad{3}z/",
    IN: "/Ad{6}z/",
    ID: "/Ad{5}z/",
    IL: "/A(d{5}|d{7})z/",
    JO: "/Ad{5}z/",
    KZ: "/Ad{6}z/",
    KE: "/Ad{5}z/",
    KW: "/Ad{5}z/",
    LA: "/Ad{5}z/",
    LB: "/A(d{4}([ ]?d{4})?)?z/",
    LU: "/A(L[- ]?)?d{4}z/",
    MK: "/Ad{4}z/",
    MY: "/Ad{5}z/",
    MV: "/Ad{5}z/",
    MT: "/A[A-Z]{3}[ ]?d{2,4}z/i",
    MU: "/A[AR1-9]d{4}z/i",
    MX: "/Ad{5}z/",
    MA: "/Ad{5}z/",
    NZ: "/Ad{4}z/",
    NI: "/Ad{5}z/",
    NG: "/Ad{6}z/",
    OM: "/A(PC )?d{3}z/i",
    PK: "/Ad{5}z/",
    PY: "/Ad{4}z/",
    PH: "/Ad{4}z/",
    PL: "/Ad{2}[ -]?d{3}z/",
    PR: "/A00[679]d{2}([ -]d{4})?z/",
    RO: "/Ad{6}z/",
    RU: "/Ad{6}z/",
    SM: "/A4789dz/",
    SA: "/Ad{5}z/",
    SN: "/Ad{5}z/",
    SI: "/Ad{4}z/",
    ZA: "/Ad{4}z/",
    LK: "/Ad{5}z/",
    TJ: "/Ad{6}z/",
    TH: "/Ad{5}z/",
    TN: "/Ad{4}z/",
    TR: "/Ad{5}z/",
    TM: "/Ad{6}z/",
    UA: "/Ad{5}z/",
    UZ: "/Ad{6}z/",
    VA: "/A00120z/",
    VE: "/Ad{4}z/",
    ZM: "/Ad{5}z/",
    AS: "/A96799z/",
    CC: "/A6799z/",
    CK: "/Ad{4}z/",
    RS: "/Ad{5}z/",
    CS: "/Ad{5}z/",
    YU: "/Ad{5}z/",
    CX: "/A6798z/",
    ET: "/Ad{4}z/",
    FK: "/AFIQQ[ ]?1ZZz/i",
    NF: "/A2899z/",
    FM: "/A(9694[1-4])([ -]d{4})?z/",
    GF: "/A9[78]3d{2}z/",
    GP: "/A9[78][01]d{2}z/",
    GS: "/ASIQQ[ ]?1ZZz/i",
    GU: "/A969[1-3]d([ -]d{4})?z/",
    GW: "/Ad{4}z/",
    HM: "/Ad{4}z/",
    IQ: "/Ad{5}z/",
    KG: "/Ad{6}z/",
    LR: "/Ad{4}z/",
    LS: "/Ad{3}z/",
    MG: "/Ad{3}z/",
    MN: "/Ad{5}(-?d{4})?z/",
    MP: "/A9695[012]([ -]d{4})?z/",
    MQ: "/A9[78]2d{2}z/",
    NC: "/A988d{2}z/",
    NE: "/Ad{4}z/",
    PF: "/A987d{2}z/",
    PG: "/Ad{3}z/",
    PM: "/A9[78]5d{2}z/",
    PN: "/APCRN[ ]?1ZZz/i",
    PW: "/A96940z/",
    RE: "/A974d{2}z/",
    SH: "/A(ASCN|STHL|TDCU)[ ]?1ZZz/i",
    SJ: "/Ad{4}z/",
    SZ: "/A[HLMS]d{3}z/i",
    TC: "/ATKCA[ ]?1ZZz/i",
    WF: "/A986d{2}z/",
    XK: "/Ad{5}z/",
    YT: "/A976d{2}z/",
    // # N"OTE: UAE has no postal codes
    // # h"ttps://github.com/dgilperez/validates_zipcode/issues/28
    // # A"E: /\A([A-Z\d\s]){3,}\z/i,
    AF: "/Ad{4}z/",
    AL: "/Ad{4}z/",
    AG: "/A([A-Zds]){3,}z/i",
    AO: "/A([A-Zds]){3,}z/i",
    AZ: "/A(AZ[ ]?)?d{4}z/i",
    BF: "/A([A-Zds]){3,}z/i",
    BI: "/A([A-Zds]){3,}z/i",
    BS: "/A([A-Zds]){3,}z/i",
    BZ: "/A([A-Zds]){3,}z/i",
    BR: "/Ad{5}(-?d{3})?z/",
    BJ: "/A([A-Zds]){3,}z/i",
    BT: "/Ad{5}z/",
    BQ: "/A([A-Zds]){3,}z/i",
    BO: "/Ad{4}z/",
    BW: "/A([A-Zds]){3,}z/i",
    CF: "/A([A-Zds]){3,}z/i",
    CG: "/A([A-Zds]){3,}z/i",
    CI: "/A([A-Zds]){3,}z/i",
    // # NOTE: Cameroon has no postal codes
    // # CM: /\A([A-Z\d]){3,7}\z/i,
    CO: "/A([A-Zds]){3,}z/i",
    CR: "/Ad{5}z/",
    CU: "/Ad{5}z/",
    KM: "/A([A-Zds]){3,}z/i",
    DJ: "/A([A-Zds]){3,}z/i",
    DM: "/A([A-Zds]){3,}z/i",
    EG: "/A([A-Zds]){3,}z/i",
    GQ: "/A([A-Zds]){3,}z/i",
    EL: "/Ad{5}z/",
    ER: "/A([A-Zds]){3,}z/i",
    FJ: "/A([A-Zds]){3,}z/i",
    FR: "/Ad{5}z/",
    GA: "/Ad{4}z/",
    GD: "/A([A-Zds]){3,}z/i",
    GH: "/A([A-Zds]){3,}z/i",
    GM: "/A([A-Zds]){3,}z/i",
    GN: "/A([A-Zds]){3,}z/i",
    GI: "/A(GX11[ ]?1AA)z/i",
    GR: "/Ad{3}[ ]?d{2}z/",
    GY: "/A([A-Zds]){3,}z/i",
    HN: "/A(([A-Z]){2}|d{2})d{4}z/i",
    IO: "/Ad{5}z/",
    IR: "/Ad{5}z/",
    JP: "/Ad{3}-?d{4}z/",
    // # J"amaica has no postcode system.However, for Kingston, there are one- and two-digit sector codes.
    // # O"n February 12, 2007 it was announced: "POST CODE PROJECT SUSPENDED INDEFINITELY"
    // # h"ttps://en.youbianku.com/Jamaica#:~:text=Jamaica%20has%20no%20postcode%20system,the%20name%20of%20the%20town.
    JM: "/A((JM(DCN|CHR|AKN|DMR|BPD|AAW|CAN|ACE|DEH|CJS|BMY|BTS|CTY|DWD)(0[1-9]|[1-2]d))|d{1,2})?z/i",
    KI: "/AKI0[1-3]d{2}z/i",
    KN: "/AKN[ ]?d{4}z/i",
    KP: "/A([A-Zds]){3,8}z/i",
    LI: "/Ad{4}z/",
    LV: "/A(LV[- ]?)?d{4}z/i",
    LY: "/Ad{5}z/",
    LT: "/A(LT[- ]?)?d{5}z/i",
    LC: "/A([A-Zds]){3,}z/i",
    MC: "/Ad{5}z/",
    MD: "/A(([A-Z]){2})(|s)d{4}z/i",
    ME: "/A([A-Zds]){3,}z/i",
    MH: "/Ad{5}z/",
    MR: "/A([A-Zds]){3,}z/i",
    MM: "/A([A-Zds]){3,}z/i",
    MW: "/A([A-Zds]){3,}z/i",
    MZ: "/Ad{4}z/",
    NA: "/Ad{5}z/",
    NP: "/Ad{6}z/",
    NR: "/A([A-Zds]){3,}z/i",
    PT: "/Ad{4}([-]d{3})?z/",
    PS: "/Ad{3}z/",
    PA: "/Ad{4}z/",
    PE: "/Ad{5}z/",
    QA: "/A([A-Zds]){3,}z/i",
    RW: "/A([A-Zds]){3,}z/i",
    SC: "/A([A-Zds]){3,}z/i",
    SE: "/Ad{3}[ ]?d{2}z/",
    SK: "/A[089]d{2}[ ]?d{2}z/",
    SL: "/A([A-Zds]){3,}z/i",
    SB: "/A([A-Zds]){3,}z/i",
    SR: "/A([A-Zds]){3,}z/i",
    SO: "/A([A-Zds]){3,}z/i",
    SV: "/A([A-Zds]){3,}z/i",
    SD: "/Ad{5}z/",
    ST: "/A([A-Zds]){3,}z/i",
    SY: "/A([A-Zds]){3,}z/i",
    SS: "/Ad{5}z/",
    TD: "/Ad{5}z/",
    TG: "/A([A-Zds]){3,}z/i",
    TO: "/A([A-Zds]){3,}z/i",
    TZ: "/A([A-Zds]){3,}z/i",
    TT: "/Ad{6}z/",
    TW: "/Ad{3}(d{2})?z/",
    UG: "/A([A-Zds]){3,}z/i",
    UM: "/A([A-Zds]){3,}z/i",
    UY: "/A([A-Zds]){3,}z/i",
    VC: "/A(VC)d{4}z/i",
    VI: "/Ad{5}z/",
    VG: "/A([A-Zds]){3,}z/i",
    VN: "/Ad{6}z/",
    VU: "/A([A-Zds]){3,}z/i",
    WS: "/A([A-Zds]){3,}z/i",
    YE: "/A([A-Zds]){3,}z/i",
    ZW: "/A([A-Zds]){3,}z/i",
  };
  type ObjectKey = keyof typeof ZIPCODES_REGEX;
  const code = countryCode.toUpperCase() as ObjectKey;
  return ZIPCODES_REGEX.hasOwnProperty(countryCode.toUpperCase()) ? ZIPCODES_REGEX[code] : null;
};

export const round2digits = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const isValidStripeElementLocale = (locale: string) => {
  const validLocales = [
    "auto",
    "ar",
    "bg",
    "cs",
    "da",
    "de",
    "el",
    "en",
    "en-AU",
    "en-CA",
    "en-NZ",
    "en-GB",
    "es",
    "es-ES",
    "es-419",
    "et",
    "fi",
    "fil",
    "fr",
    "fr-CA",
    "fr-FR",
    "he",
    "hu",
    "hr",
    "id",
    "it",
    "it-IT",
    "ja",
    "ko",
    "lt",
    "lv",
    "ms",
    "mt",
    "nb",
    "nl",
    "no",
    "pl",
    "pt",
    "pt-BR",
    "ro",
    "ru",
    "sk",
    "sl",
    "sv",
    "th",
    "tr",
    "vi",
    "zh",
    "zh-HK",
    "zh-TW",
  ];
  return validLocales.includes(locale);
};
