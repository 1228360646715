import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import MuiPhoneNumber, { MuiPhoneNumberProps } from "material-ui-phone-number";
import { AutocompleteProps } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "red",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
    },
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

// export const RedditAutocompleteField = styled((props: AutocompleteProps) => <Autocomplete {...props} />)(
//   ({ theme }) => ({
//     "& .MuiFilledInput-root": {
//       fontFamily: "Gotham",
//       fontWeight: "400",
//       border: "1px solid #CDD4DC",
//       overflow: "hidden",
//       borderRadius: "16px",
//       color: theme.palette.primary.main,
//       backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#2b2b2b",
//       transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
//       "&:hover": {
//         backgroundColor: "transparent",
//       },
//       "&.Mui-focused": {
//         backgroundColor: "transparent",
//         // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
//         borderColor: theme.palette.primary.main,
//       },
//     },
//     "& .MuiInputLabel-root": {
//       fontFamily: "Gotham",
//       fontWeight: "600",
//       color: "#CDD4DC",
//       "&.Mui-focused": {
//         color: "#8193A8",
//         // color: theme.palette.primary.main,
//       },
//     },
//   })
// );

const RedditTextField = styled((props: TextFieldProps) => (
  <TextField InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    fontFamily: "Gotham",
    fontWeight: "400",
    border: "1px solid #CDD4DC",
    overflow: "hidden",
    borderRadius: "16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#2b2b2b",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "none",
    },
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Gotham",
    fontWeight: "600",
    color: "#CDD4DC",
    "&.Mui-focused": {
      color: "#8193A8",
      // color: theme.palette.primary.main,
    },
  },
}));

export const RedditPhoneField = styled((props: MuiPhoneNumberProps) => (
  <MuiPhoneNumber InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    fontFamily: "Gotham",
    fontWeight: "400",
    border: "1px solid #CDD4DC",
    overflow: "hidden",
    borderRadius: "16px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#2b2b2b",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Gotham",
    fontWeight: "600",
    color: "#CDD4DC",
    "&.Mui-focused": {
      color: "#8193A8",
      // color: theme.palette.primary.main,
    },
  },
  "& svg": {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

export default RedditTextField;
