import { ThemeProvider, createTheme, Theme, ThemeOptions, SimplePaletteColorOptions } from "@mui/material/styles";
import { ReactElement, useMemo, useState } from "react";
import { ColorModeContext } from "../context/checkoutContext";
import { GlobalStyles, ScopedCssBaseline } from "@mui/material";
import Checkout from "../screens/checkout";
import { merge } from "ts-deepmerge";
import { Appearance, StripePaymentElementOptions } from "@stripe/stripe-js";

const themeBase: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#231f20",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: "#FFFFFF",
    },
    error: {
      // main: "#FF3D13", //loop error
      // main: "#d32f2f", //patette default main
      main: "#ef5350", //patette default light
    },
    secondary: {
      light: "#00A944",
      main: "#2bb573",
      contrastText: "#FFFFFF",
      //   // dark: will be calculated from palette.secondary.main,
      //   contrastText: '#ffcc00',
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    // custom: {
    //   light: '#ffa726'
    //   main: '#f57c00',
    //   dark: '#ef6c00',
    //   contrastText: 'rgba(0, 0, 0, 0.87)',
    // }
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  components: {
    // Name of the component
    MuiTypography: {
      styleOverrides: {
        root: { fontFamily: "Gotham" },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "16px",
          fontFamily: "Gotham",
        },
      },
    },
  },
};

export const themeLight: Theme = createTheme(
  merge(themeBase, {
    palette: {
      mode: "light",
    },
  }) as ThemeOptions
);

export const themeDark = createTheme(
  merge(themeBase, {
    palette: {
      mode: "dark",
      background: {
        default: "#111111",
        paper: "#1A2027",
      },
      primary: {
        main: "#fefefe",
        contrastText: "#231f20",
      },
      secondary: {
        contrastText: "#fefefe",
      },
    },
  }) as ThemeOptions
);

export const stripeElementAppearance: Appearance = {
  theme: "stripe",
  // theme: "flat",
  labels: "floating",
  variables: {
    colorPrimary: (themeBase.palette!.secondary as SimplePaletteColorOptions).main,
    colorBackground: themeBase.palette?.background?.paper,
    colorText: (themeBase.palette!.primary as SimplePaletteColorOptions).main,
    // colorText: "#CDD4DC",
    colorDanger: (themeBase.palette!.secondary as SimplePaletteColorOptions).main,
    accessibleColorOnColorPrimary: (themeBase.palette!.primary as SimplePaletteColorOptions).main,
    // fontFamily: "Gotham, Ideal Sans, system-ui, sans-serif",
    // spacingUnit: "2px",
    borderRadius: "12px",
    // borderColor: "#e6e6e6",
    // See all possible variables below
  },
  rules: {
    ".Label": {
      color: (themeBase.palette!.primary as SimplePaletteColorOptions).main,
    },
    ".AccordionItem--selected": { color: (themeBase.palette!.secondary as SimplePaletteColorOptions).main },
    // ".TabIcon": { color: (themeBase.palette!.primary as SimplePaletteColorOptions).main },
    ".TabIcon": { color: "#1b5188" },
    ".Input": { border: "1px solid #CDD4DC", borderRadius: "12px" },
    ".Input:focus": {
      border: "1px solid " + (themeBase.palette!.primary as SimplePaletteColorOptions).main,
      boxShadow: "none",
    },
  },
};

export const stripePaymentElementOptions: StripePaymentElementOptions = {
  layout: {
    type: "accordion",
    defaultCollapsed: false,
    radios: true,
    spacedAccordionItems: false,
    // radios: false,
    // spacedAccordionItems: true,
  },
};

export const ThemeProviderToggleColorMode: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const [mode, setMode] = useState<"light" | "dark">("light");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(() => (mode === "light" ? themeLight : themeDark), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline enableColorScheme>
          <GlobalStyles
            styles={{
              body: { backgroundColor: "#ffffff", marginBottom: "150px" },
            }}
          />
          {theme.palette.primary.main}
          {children}
          <Checkout />
        </ScopedCssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
