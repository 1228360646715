import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { request, gql, ClientError, GraphQLClient } from "graphql-request";
import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
import moment from "moment";

export function useBundles(
  onSuccessCustom?: (data: [GQL.Bundle]) => void
): UseQueryResult<[GQL.Bundle], ClientError> {
  return useQuery(
    "bundles",
    async () => {
      const { bundles: data } = await graphQLClient.request(
        gql`
          query {
            bundles(limit: 500) {
              active
              createdAt
              bundleId
              itemsExtended {
                active
                currency
                metadata
                priceId
                product {
                  active
                  priceAmount
                  priceAmountAfterTrial
                  priceOriginalAfterTrial
                  priceRecurringInterval
                  priceRecurringIntervalCount
                  priceRecurringTrialPeriodDays
                  description
                  image
                  metadata
                  name
                  productId
                  tags
                  id
                  prodSku
                }
                type
                unitAmount
                unitAmountDecimal
                recurringInterval
                recurringIntervalCount
                recurringTrialPeriodDays
              }
              modifiedAt
              name
              nameOrderEn
              nameMarketingEn
              nameOrderDe
              nameMarketingDe
              image
              recurringInterval
              images
              index
              imageSubscription
              descriptionEn
              descriptionDe
              priceAmountOneTimeCrossedEur
              priceAmountOneTimeCrossedGbp
              priceAmountOneTimeCrossedUsd
              priceAmountOneTimeCurrentEur
              priceAmountOneTimeCurrentGbp
              priceAmountOneTimeCurrentUsd
              priceAmountPayAfterTrialCrossedEur
              priceAmountPayAfterTrialCrossedGbp
              priceAmountPayAfterTrialCrossedUsd
              priceAmountPayAfterTrialCurrentEur
              priceAmountPayAfterTrialCurrentGbp
              priceAmountPayAfterTrialCurrentUsd
              priceAmountPayNowCrossedEur
              priceAmountPayNowCrossedGbp
              priceAmountPayNowCrossedUsd
              priceAmountPayNowCurrentEur
              priceAmountPayNowCurrentGbp
              priceAmountPayNowCurrentUsd
              priceAmountRecurringCrossedEur
              priceAmountRecurringCrossedGbp
              priceAmountRecurringCrossedUsd
              priceAmountRecurringCurrentEur
              priceAmountRecurringCurrentGbp
              priceAmountRecurringCurrentUsd
              savingsEur
              savingsGbp
              savingsUsd
              bundleTags
              tags
              labels {
                text
                colour
                lang
              }
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
  );
}

// export function useArticlesCreate(
//   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
// ): UseMutationResult<GQL.IArticlesCreate, ClientError, GQL.IArticlesCreateOnMutationArguments> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`

//             mutation{
//               articlesCreate(articleDate: "${moment(dataMutation.articleDate).format("YYYYMMDD")}",
//               companyId: "${dataMutation.companyId}",
//               description: """${dataMutation.description}""",
//               documentUrl: "${dataMutation.documentUrl}",
//               isPrivate: ${dataMutation.isPrivate}){
//                 success
//               }
//             }
//         `
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         articlesList.refetch();
//       },
//       onError: (error: ClientError, variables, context) => {},
//     }
//   );
// }

// export function useArticlesDelete(
//   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
// ): UseMutationResult<GQL.IArticlesDelete, ClientError, GQL.IArticlesDeleteOnMutationArguments> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`
//           mutation{
//             articlesDelete(articlesUuid: "${dataMutation.articlesUuid}"){
//               success
//             }
//           }
//         `
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         articlesList.refetch();
//       },
//       onError: (error: ClientError, variables, context) => {},
//     }
//   );
// }
