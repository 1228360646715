import React from "react";
import ReactDOM from "react-dom/client";
import "./fonts/Montserrat-Bold.woff2";
import "./fonts/Montserrat-BoldItalic.woff2";
import "./fonts/Montserrat-Bold.woff2";
import "./fonts/Montserrat-BoldItalic.woff2";
import "./fonts/Montserrat-Italic.woff2";
import "./fonts/Montserrat-Light.woff2";
import "./fonts/Montserrat-LightItalic.woff2";
import "./fonts/Montserrat-Medium.woff2";
import "./fonts/Montserrat-MediumItalic.woff2";
import "./fonts/Montserrat-Regular.woff2";
import "./fonts/Montserrat-SemiBold.woff2";
import "./fonts/Montserrat-SemiBoldItalic.woff2";
import "./fonts/Roboto-Regular.woff2";
import "./fonts/Roboto-Bold.woff2";

import "./fonts/Montserrat-Black.ttf";
import "./fonts/Montserrat-BlackItalic.ttf";
import "./fonts/Montserrat-Bold.ttf";
import "./fonts/Montserrat-BoldItalic.ttf";
import "./fonts/Montserrat-ExtraBold.ttf";
import "./fonts/Montserrat-ExtraBoldItalic.ttf";
import "./fonts/Montserrat-ExtraLight.ttf";
import "./fonts/Montserrat-ExtraLightItalic.ttf";
import "./fonts/Montserrat-Italic.ttf";
import "./fonts/Montserrat-Light.ttf";
import "./fonts/Montserrat-LightItalic.ttf";
import "./fonts/Montserrat-Medium.ttf";
import "./fonts/Montserrat-MediumItalic.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import "./fonts/Montserrat-SemiBoldItalic.ttf";
import "./fonts/Montserrat-Thin.ttf";
import "./fonts/Montserrat-ThinItalic.ttf";

import "./fonts/Gotham-Black.ttf";
import "./fonts/Gotham-BlackItalic.ttf";
import "./fonts/Gotham-Bold.ttf";
import "./fonts/Gotham-BoldItalic.ttf";
import "./fonts/Gotham-Book.ttf";
import "./fonts/Gotham-BookItalic.ttf";
import "./fonts/Gotham-Light.ttf";
import "./fonts/Gotham-LightItalic.ttf";
import "./fonts/Gotham-Medium.ttf";
import "./fonts/Gotham-MediumItalic.ttf";
import "./fonts/Gotham-Thin.ttf";
import "./fonts/Gotham-ThinItalic.ttf";
import "./fonts/Gotham-Ultra.ttf";
import "./fonts/Gotham-UltraItalic.ttf";
import "./fonts/Gotham-XLight.ttf";
import "./fonts/Gotham-XLightItalic.ttf";

import "./index.css";
import "./css/fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const el = document.getElementById("root");
if (el === null) throw new Error("Root container missing in index.html");

const root = ReactDOM.createRoot(el);
root.render(
  <>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
