import { Tooltip } from "@mui/material";
import moment from "moment";

import { DateField } from "react-admin";

const LocalisedDateField = ({ record, field, compact = false, text = "", showTime = false }: any) => {
  let value = record[field];
  if (!value) return "";

  return (
    <>
      {`${text} `}
      {!compact ? `${moment(value).fromNow()}: ` : ""}
      <Tooltip
        followCursor={true}
        arrow={true}
        title={
          <>
            <DateField
              source={field}
              transform={(value: any) => new Date(moment.utc(value).format())}
              showTime={true}
            />
            <span style={{ fontSize: "14px" }}>{" in " + Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
          </>
        }
      >
        <span className="local-time-tooltip-trigger">
          <DateField source={field} showTime={showTime} />
          {!compact ? " UTC " : ""}
        </span>
      </Tooltip>
    </>
  );
};

export default LocalisedDateField;
