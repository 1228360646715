import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";

import RequestQuoteTwoToneIcon from "@mui/icons-material/RequestQuoteTwoTone";
import { Grid } from "@mui/material";
import {
  ArrayField,
  Datagrid,
  EmailField,
  FunctionField,
  Labeled,
  Show,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";

import { stripe_link } from "../api";
import LocalisedDateField from "../components/localisedDateField";

const ShopifyOrderDetailTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      Order for: {record.customer.shippingAddress.firstName}{" "}
      {record.customer.shippingAddress.lastName}
    </span>
  );
};

const SummaryTabHeader = () => {
  return (
    <span style={{ display: "" }}>
      <InfoTwoToneIcon />{" "}
      <span style={{ verticalAlign: "super" }}>Summary</span>
    </span>
  );
};

const ShippingTabHeader = () => {
  return (
    <span style={{ display: "" }}>
      <LocalShippingTwoToneIcon />{" "}
      <span style={{ verticalAlign: "super" }}>Shipping</span>
    </span>
  );
};

const BillingTabHeader = () => {
  return (
    <span style={{ display: "" }}>
      <RequestQuoteTwoToneIcon />{" "}
      <span style={{ verticalAlign: "super" }}>Billing</span>
    </span>
  );
};

const ShopifyOrderDetail = () => {
  return (
    <Show title={<ShopifyOrderDetailTitle />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label={<SummaryTabHeader />}>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="Order ID">
                <TextField source="id" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Created">
                <FunctionField
                  render={(record: any) => (
                    <LocalisedDateField
                      record={record}
                      field={"createdAt"}
                      showTime={true}
                    />
                  )}
                />
              </Labeled>
            </Grid>
          </Grid>

          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="Order Email">
                <EmailField source="email" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Shop Name">
                <TextField source="shopName" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="Stripe Payment ID">
                <FunctionField
                  render={(record: any) => {
                    let _id = record.stripeId;
                    if (!_id) {
                      return <></>;
                    }
                    if (!_id.startsWith("in_")) {
                      return <>{_id}</>;
                    }
                    return (
                      <>
                        {_id}
                        <a
                          href={`${stripe_link}invoices/${_id}`}
                          className="ex-link"
                          target="_blank"
                          title="View Invoice on Stripe"
                        >
                          ↗
                        </a>
                      </>
                    );
                  }}
                />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Stripe Customer ID">
                <FunctionField
                  render={(record: any) => {
                    let _id = record.customer.stripeId;
                    if (!_id) {
                      return <></>;
                    }
                    return (
                      <>
                        {_id}
                        <a
                          href={`${stripe_link}customers/${_id}`}
                          className="ex-link"
                          target="_blank"
                          title="View Customer on Stripe"
                        >
                          ↗
                        </a>
                      </>
                    );
                  }}
                />
              </Labeled>
            </Grid>
          </Grid>
          {"Items"}
          <ArrayField source="items">
            <Datagrid bulkActionButtons={false}>
              <TextField source="title" />
              <TextField source="productType" />
              <FunctionField
                label="Stripe Product ID"
                render={(item: { stripeProductId: any }) => {
                  return item.stripeProductId.startsWith("prod_") ? (
                    <>
                      {item.stripeProductId}
                      <a
                        href={`${stripe_link}products/${item.stripeProductId}`}
                        className="ex-link"
                        target="_blank"
                        title="View Product on Stripe"
                      >
                        ↗
                      </a>
                    </>
                  ) : (
                    <>{item.stripeProductId}</>
                  );
                }}
              />
              <TextField source="quantity" />
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={<ShippingTabHeader />}>
          <>{"Shipping Address"}</>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="First Name">
                <TextField source="customer.shippingAddress.firstName" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Last Name">
                <TextField source="customer.shippingAddress.lastName" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="House Number">
                <TextField source="customer.shippingAddress.houseNumber" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Street Name">
                <TextField source="customer.shippingAddress.streetName" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="City">
                <TextField source="customer.shippingAddress.city" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="State">
                <TextField source="customer.shippingAddress.state" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Country">
                <>
                  <TextField source="customer.shippingAddress.country" />
                  <FunctionField
                    render={(record: any) => (
                      <>{record.customer.shippingAddress.countryEmoji}</>
                    )}
                  />
                </>
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Zipcode">
                <TextField source="customer.shippingAddress.zipcode" />
              </Labeled>
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={<BillingTabHeader />} value={"billing"}>
          <>{"Billing Address"}</>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="First Name">
                <TextField source="customer.billingAddress.firstName" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Last Name">
                <TextField source="customer.billingAddress.lastName" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="House Number">
                <TextField source="customer.billingAddress.houseNumber" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Street Name">
                <TextField source="customer.billingAddress.streetName" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="City">
                <TextField source="customer.billingAddress.city" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="State">
                <TextField source="customer.billingAddress.state" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Country">
                <>
                  <TextField source="customer.shippingAddress.country" />
                  <FunctionField
                    render={(record: any) => (
                      <>{record.customer.shippingAddress.countryEmoji}</>
                    )}
                  />
                </>
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Zipcode">
                <TextField source="customer.billingAddress.zipcode" />
              </Labeled>
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ShopifyOrderDetail;
