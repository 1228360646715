import * as XLSX from "xlsx";

export const exporterXlsx = (orders: [GQL.Orders]) => {
  let order_list: any[] = [];
  orders.map((order: GQL.Orders) => {
    const order_obj_pre = {
      orderId: order.id,
      firstName: order.firstName || "",
      lastName: order.lastName,
      companyName: order.companyName,
      shippingAddressLine1: order.shippingAddressLine1 || "",
      shippingAddressLine2: order.shippingAddressLine2 || "",
      shippingAddressPostalCode: order.shippingAddressPostalCode || "",
      shippingAddressCity: order.shippingAddressCity || "",
      shippingState: order.shippingState || "",
      shippingAddressCountry: order.shippingAddressCountry || "",
      country: order.country || "",
      phone: (order.phone || "").toString(),
      amountTotal: (order.amountTotal || 0) / 100,
      email: order.email || "",
    };
    const order_obj_suf = {
      couponcode: order.couponcode || "",
      createdAt: new Date(order.createdAt),
      stripeCustomerId: order.stripeCustomerId,
      stripeInvoiceId: order.stripeInvoiceId,
      braintreeCustomerId: order.braintreeCustomerId || "",
      braintreePaypalPayerEmail: order.braintreePaypalPayerEmail,
      amountTax: (order.amountTax || 0) / 100,
      amountDiscount: (order.amountDiscount || 0) / 100,
      amountDiscountRate: order.amountDiscountRate,
      amountTotalValue: (order.amountTotalValue || 0) / 100,
      currency: order.currency,
      orderType: order.orderType,
      paymentType: order.paymentType,
      // amountShipping,
      // amountSubtotal,
      // amountTotalAfter,
      // billingAddressCity,
      // billingAddressCountry,
      // billingAddressLine1,
      // billingAddressLine2,
      // billingAddressPostalCode,
      // billingAddressState,
      // billingName,

      // braintreePaypalTransactionId,
      // country,
      // couponCodes,
      // currencySymbol,
      // discounts,
      // failedReferralEmailSent: order.failedReferralEmailSent,
      // hasSubscription,
      // hasTrial,
      // invoicePaymentReferenceId,
      // invoicePaymentRefundId: order.invoicePaymentRefundId,
      // isRefunded: order.isRefunded,
      // isTest: order.isTest,
      // language,
      // modifiedAt,
      // orderEmailSentAt: order.orderEmailSentAt,
      // orderId,
      // orderItems: order.orderItems,
      // orderType,
      // paymentType,
      // promoCodes: order.promoCodes,
      // referralVoucherSent: order.referralVoucherSent,
      // refundAt: order.refundAt,
      // refundId: order.refundId,
      // sevdeskInvoiceCreatedAt: order.sevdeskInvoiceCreatedAt,
      // sevdeskInvoiceId: order.sevdeskInvoiceId,
      // sevdeskInvoiceNumber: order.sevdeskInvoiceNumber,
      // shippingAddressState: order.shippingAddressState || "",
      // shippingCancelledAt: order.shippingCancelledAt || "",
      // shippingCompletedAt: order.shippingCompletedAt || "",
      // shippingCourierService: order.shippingCourierService || "...",
      // shippingItemsBrushCount: order.shippingItemsBrushCount || 0,
      // shippingItemsBrushHeadCount: order.shippingItemsBrushHeadCount || 0,
      // shippingItemsGamesSubCount: order.shippingItemsGamesSubCount || 0,
      // shippingItemsToothPasteCount: order.shippingItemsToothPasteCount || 0,
      // shippingName,
      // shippingSentToWarehouseAt: order.shippingSentToWarehouseAt,
      // shippingTrackingEmailSentAt: order.shippingTrackingEmailSentAt,
      // shippingTrackingId: order.shippingTrackingId,
      // stripeInvoiceIds: order.stripeInvoiceIds?.join(","),
      // stripePaymentIntent,
      // stripeSetupIntent,
      // stripeShippingPriceId: order.stripeShippingPriceId,
      // stripeSubscriptionId,
      // stripeSubscriptionIds: order.stripeSubscriptionIds?.join(","),
      // stripeTaxRateId,
      // tags,
      // taxRate,
      // trialDays,
      // userId,
    };

    if (order !== null && order.orderItems !== null && order.orderItems !== undefined) {
      let checkfirst = false;
      let is_first = true;
      order.orderItems.map((orderItem: GQL.OrderItem | null) => {
        let orderItem_obj = null;
        if (orderItem !== null && orderItem !== undefined) {
          orderItem_obj = {
            ...order_obj_pre,
            price: (orderItem.priceAmount || 0) / 100,
            quantity: orderItem.quantity,
            productSku: orderItem.productSku,
            tags: (orderItem.tags || []).join(","),
            productName: orderItem.productName,
            productNameMarketing: orderItem.productNameMarketing,
            productNameOrder: orderItem.productNameOrder,
            productBarcode: orderItem.productBarcode,
            productId: orderItem.productId,
            interval:
              orderItem.recurringIntervalCount + " " + orderItem.recurringInterval === "12 month"
                ? "1 year"
                : orderItem.recurringIntervalCount + " " + orderItem.recurringInterval,
            ...order_obj_suf,
            // bundleId,
            // bundleName,
            // currencySymbol,
            // id: orderItem.id,
            // image,
            // infoDelivery,
            // infoExplainer,
            // infoPayment,
            // isSubscription,
            // priceAmount,
            // priceAmountAfterTrial,
            // priceDefaultAmount,
            // priceId,
            // priceOriginalAfterTrial,
            // recurringInterval,
            // recurringIntervalCount,
            // recurringTrialPeriodDays,
          };
        }
        order_list.push(orderItem_obj);
        if (!is_first && checkfirst) {
          order_obj_pre.amountTotal = 0;

          order_obj_suf.amountTax = 0;
          order_obj_suf.amountDiscount = 0;
          order_obj_suf.amountDiscountRate = 0;
          order_obj_suf.amountTotalValue = 0;
        }
        is_first = false;
        return orderItem_obj;
      });
    }

    return order;
    // return order_list;
  });
  const header_renamed = [
    "Order Id",
    "First Name",
    "Last Name",
    "Company Name",
    "Address Line1",
    "Address Line2",
    "PostalCode",
    "City",
    "State",
    "Shipping Country",
    "Store Country",
    "Phone",
    "Total",
    "Email",
    "Price",
    "Quantity", //
    "Sku",
    "Tags", //
    "Product Name", //
    "Marketing Name", //
    "Order Name", //
    "Barcode",
    "Product stripe Id", //
    "Recurring Interval",
    "Couponcode",
    "Created at",
    "Stripe Customer Id",
    "Stripe Invoice Id",
    "Braintree Customer Id",
    "Braintree PaypalPayerEmail",
    "Tax",
    "Discount",
    "Discount Rate",
    "Total Value",
    "Currency",
    "Order Type",
    "Payment Type",
  ];
  const worksheet = XLSX.utils.json_to_sheet(order_list, { dateNF: "YYYY-MM-DD hh:mm:sss" });
  const worksheet_with_header = XLSX.utils.sheet_add_aoa(worksheet, [header_renamed], { origin: "A1" });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet_with_header, "Orders");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, "Order_export.xlsx");
};

export const exporterDiscountXlsx = (orders: [GQL.Orders]) => {
  let order_list: any[] = [];
  orders.map((order: GQL.Orders) => {
    const order_obj = {
      orderId: order.id,
      createdAt: new Date(order.createdAt),
      firstName: order.firstName || "",
      lastName: order.lastName,
      companyName: order.companyName,
      email: order.email || "",
      couponcode: order.couponcode || "",
      amountDiscount: (order.amountDiscount || 0) / 100,
      amountDiscountRate: order.amountDiscountRate || 0, // amountDiscount / (amountSubTotal + amountShipping)
      amountTotalValue: (order.amountTotalValue || 0) / 100, // amountSubTotal + amountShipping
      amountShipping: (order.amountShipping || 0) / 100,
      amountTax: (order.amountTax || 0) / 100,
      amountTotal: (order.amountTotal || 0) / 100,
      currency: order.currency,
      stripeInvoiceId: order.stripeInvoiceId,
      stripeSubscriptionId: order.stripeSubscriptionId,
      stripeSubscriptionIds: order.stripeSubscriptionIds?.join(","),
    };
    order_list.push(order_obj);
    return order_obj;
  });
  const header_renamed = [
    "Order Id",
    "Created at",
    "First Name",
    "Last Name",
    "Company Name",
    "Email",
    "Couponcode",
    "Discount Amount",
    "Discount Rate",
    "Original Value",
    "Shipping cost",
    "Tax",
    "Invoiced Total",
    "Currency",
    "Stripe Invoice Id",
    "Stripe stripeSubscriptionId",
    "Stripe stripeSubscriptionIds",
  ];
  const worksheet = XLSX.utils.json_to_sheet(order_list, { dateNF: "YYYY-MM-DD hh:mm:sss" });
  const worksheet_with_header = XLSX.utils.sheet_add_aoa(worksheet, [header_renamed], { origin: "A1" });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet_with_header, "Orders Discount");
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, "Order_export.xlsx");
};
