import * as React from "react";
import { DashboardMenuItem, Menu, MenuItemLink, useResourceDefinitions, useSidebarState } from "react-admin";
import BookIcon from "@mui/icons-material/Book";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import DefaultIcon from "@mui/icons-material/ViewList";
import SettingsIcon from "@mui/icons-material/Settings";
import StoreIcon from "@mui/icons-material/Store";

export const SidebarMenu = (props) => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
  const [open] = useSidebarState();
  return (
    <Menu {...props}>
      <DashboardMenuItem primaryText="Dashboard" />

      {resources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={(resource.options && resource.options.label) || resource.name}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}

      <MenuItemLink to="/order_charts" primaryText="Order Charts" leftIcon={<BarChartIcon />} />
      <MenuItemLink to="/settings" primaryText="Settings" leftIcon={<SettingsIcon />} />
      <MenuItemLink to="/basket" primaryText="Store" leftIcon={<StoreIcon />} />
    </Menu>
  );
};
