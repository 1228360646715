import React from "react";
import { useCallback } from "react";

import {
  ArrayField,
  ArrayInput,
  AutocompleteArrayInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  EmailField,
  Labeled,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  ShowBase,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useCreate,
  useUpdate,
  useNotify,
  useRedirect,
  FunctionField,
} from "react-admin";
import Currency from "../components/currency";
import DatagridUnSelect from "../components/datagridUnSelect";
import Grid from "@mui/material/Grid";
import { language_codes } from "../api";

const EmailTemplatesChoices = [
  { id: "order_confirmation", name: "Oreder Confirmation" },
  // { id: "order_confirmation_trial", name: "Oreder Confirmation Trial" },
  { id: "order_reminder", name: "Order Reminder" },
  // { id: "order_reminder_trial", name: "Order Reminder Trial" },
  { id: "order_renew", name: "Order Renew" },
  { id: "order_trial_ends", name: "Order Trial Ends" },
  // { id: "order_cancel", name: "order_cancel" },
];

const MyToolbar = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props}>
      <SaveButton
      // label="Save"
      // mutationOptions={{
      //   onSuccess: (data) => {
      //     notify("ra.notification.created", {
      //       type: "info",
      //       messageArgs: { smart_count: 1 },
      //     });
      //     redirect(false);
      //   },
      // }}
      // type="button"
      // variant="text"
      />
    </Toolbar>
  );
};
export const EmailProductDetailTextList = () => (
  <List>
    <Datagrid>
      <TextField source="emailType" />
      <ReferenceArrayField label="Tags" reference="productTags" source="tags" perPage={100}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="fieldName" />
      <TextField source="language" />
      <TextField source="text" />
      <EditButton />
    </Datagrid>
  </List>
);

export const EmailProductDetailTextEdit = () => {
  const [update, { isLoading, error }] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      try {
        await update(
          "emailProductDetailText",
          { id: values.id, data: values, previousData: values },
          { returnPromise: true }
        );
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );
  return (
    <Edit>
      <SimpleForm toolbar={<MyToolbar />}>
        <SimpleShowLayout>
          <TextField source="id" />
        </SimpleShowLayout>
        <SelectInput choices={EmailTemplatesChoices} source="emailType" />
        {/* </Labeled> */}
        <ReferenceArrayInput source="tags" reference="productTags" perPage={100}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
          choices={[
            { id: "info_explainer", name: "Explainer" },
            { id: "info_delivery", name: "Delivery" },
            { id: "info_payment", name: "Payment" },
          ]}
          source="fieldName"
        />
        <SelectInput
          choices={language_codes.map((language_code) => ({ id: language_code, name: language_code }))}
          source="language"
        />
        {/* <TextInput fullWidth source="language" InputLabelProps={{ shrink: true }} /> */}
        <TextInput fullWidth multiline rows={4} source="text" InputLabelProps={{ shrink: true }} />
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput fullWidth multiline rows={4} source="en" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput fullWidth multiline rows={4} source="de" InputLabelProps={{ shrink: true }} />
          </Grid>
        </Grid> */}
        {/* <SimpleShowLayout> */}
        {/* <ReferenceArrayField label="Tags" reference="productTags" source="tags">
            <SingleFieldList>
              <ChipField source="id" />
            </SingleFieldList>
          </ReferenceArrayField> */}
        {/* <ReferenceArrayInput source="tags" reference="productTags">
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput> */}
        {/* </SimpleShowLayout> */}
        {/* <ReferenceManyField label="Items" reference="prices" target="items">
          <Datagrid>
            <TextField source="priceId" />
          </Datagrid>
        </ReferenceManyField> */}
        {/* <SimpleShowLayout> */}
        {/* <TextField source="savings" /> */}
        {/* <ReferenceField source="itemsExtended" reference="price"> */}
        {/* <TextField source="itemsExtended.priceid" /> */}
        {/* <ArrayField source="itemsExtended">
            <DatagridUnSelect>
              <TextField label="Price Id" source="priceId" />
              <TextField label="Product Name" source="product.name" />
              <FunctionField
                label="Price"
                render={(record: { unitAmount: number; currency: string }) => {
                  return <Currency price={record.unitAmount} currency={record.currency} />;
                }}
              />
              <FunctionField
                label="Price Default"
                render={(record: { product: { defaultPriceAmount: number }; currency: string }) => {
                  return <Currency price={record.product.defaultPriceAmount} currency={record.currency} />;
                }}
              />
            </DatagridUnSelect>
          </ArrayField> */}
        {/* </ReferenceField> */}
        {/* <ReferenceArrayField label="Product Info" reference="prices" source="itemsExtended.priceid">
            <SingleFieldList>
              <ChipField source="priceid" />
            </SingleFieldList>
          </ReferenceArrayField> */}
        {/* <ReferenceArrayInput source="tags" reference="productTags">
                <SelectArrayInput optionText="name" />
              </ReferenceArrayInput> */}
        {/* </SimpleShowLayout> */}
        {/* <ArrayInput source="items">
          <SimpleFormIterator getItemLabel={(index) => `${index + 1}. link`}>
            <TextInput source="" sx={{ width: "100%" }} />
          </SimpleFormIterator>
        </ArrayInput> */}
        {/* <ReferenceArrayField label="Items" reference="prices" source="items">
          <Datagrid>
            <TextField source="priceId" />
            <TextField source="product.productId" />
            <TextField source="product.name" />
            <TextField source="unitAmount" />
            <TextField source="unitAmount" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField> */}

        {/* <SimpleShowLayout> */}
        {/* <TextInput label="Image Title EN" source="prodImageTitleEn" />
        <TextInput label="Image Title DE" source="prodImageTitleDe" />
        <TextInput label="Image Corner Note EN" source="prodImageCornerNoteEn" />
        <TextInput label="Image Corner Note DE" source="prodImageCornerNoteDe" />
        <TextInput label="Image Tooltip EN" source="prodImageTooltipTextEn" />
        <TextInput label="Image Tooltip DE" source="prodImageTooltipTextDe" /> */}
        {/* </SimpleShowLayout> */}
        {/* <ReferenceArrayField source="bundles" reference="prices">
          <Datagrid>
            <TextField source="priceId" />
            <TextField source="unitAmount" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField> */}
        {/* <ReferenceManyField reference="prices" target="id" label="Boundle Prices">
          <Datagrid>
            <DateField source="id" />
            <TextField source="unitAmount" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField> */}
      </SimpleForm>
    </Edit>
  );
};

export const EmailProductDetailTextCreate = () => {
  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("emailProductDetailText", { data: values }, { returnPromise: true });
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );
  return (
    <Create>
      <SimpleForm>
        {/* <SimpleForm onSubmit={save}> */}
        {/* <SimpleShowLayout> toolbar={<MyToolbar />} */}
        {/* <TextField source="bundleId" /> */}
        {/* <TextField source="name" /> */}
        {/* <TextField source="items" /> */}
        {/* <Labeled label="Email Product Detail Text"> */}
        <SelectInput choices={EmailTemplatesChoices} source="emailType" />
        {/* </Labeled> */}
        <ReferenceArrayInput source="tags" reference="productTags" perPage={100}>
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput
          choices={[
            { id: "info_explainer", name: "Explainer" },
            { id: "info_delivery", name: "Delivery" },
            { id: "info_payment", name: "Payment" },
          ]}
          source="fieldName"
        />
        <SelectInput
          choices={language_codes.map((language_code) => ({ id: language_code, name: language_code }))}
          source="language"
        />
        <TextInput fullWidth multiline rows={4} source="text" />
        {/* <TextInput fullWidth multiline rows={4} source="en" />
        <TextInput fullWidth multiline rows={4} source="de" /> */}
        {/* <ReferenceArrayField label="Tags" reference="productTags" source="tags">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField> */}
        {/* </SimpleShowLayout> */}
        {/* <ReferenceManyField label="Items" reference="prices" target="items">
          <Datagrid>
            <TextField source="priceId" />
          </Datagrid>
        </ReferenceManyField> */}
        {/* <ArrayInput source="items">
          <SimpleFormIterator getItemLabel={(index) => `${index + 1}. link`}>
            <TextInput source="" sx={{ width: "100%" }} />
          </SimpleFormIterator>
        </ArrayInput> */}
        {/* <ReferenceArrayField label="Items" reference="prices" source="items">
          <Datagrid>
            <TextField source="priceId" />
            <TextField source="product.productId" />
            <TextField source="product.name" />
            <TextField source="unitAmount" />
            <TextField source="unitAmount" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField> */}

        {/* <SimpleShowLayout> */}
        {/* <TextInput label="Image Title EN" source="prodImageTitleEn" />
        <TextInput label="Image Title DE" source="prodImageTitleDe" />
        <TextInput label="Image Corner Note EN" source="prodImageCornerNoteEn" />
        <TextInput label="Image Corner Note DE" source="prodImageCornerNoteDe" />
        <TextInput label="Image Tooltip EN" source="prodImageTooltipTextEn" />
        <TextInput label="Image Tooltip DE" source="prodImageTooltipTextDe" /> */}
        {/* </SimpleShowLayout> */}
        {/* <ReferenceArrayField source="bundles" reference="prices">
          <Datagrid>
            <TextField source="priceId" />
            <TextField source="unitAmount" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField> */}
        {/* <ReferenceManyField reference="prices" target="id" label="Boundle Prices">
          <Datagrid>
            <DateField source="id" />
            <TextField source="unitAmount" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField> */}
      </SimpleForm>
    </Create>
  );
};
