import { createStore, GlobalState } from "little-state-machine";
import { __DEV__ } from "../api";

const defaultState = {
  data: {
    stripeCustomerId: "",
    customer: {
      name: null,
      email: "",
      description: null,
      address: {
        city: null,
        country: null,
        line1: null,
        line2: null,
        postalCode: null,
        state: null,
      },
      shipping: {
        name: null,
        phone: null,
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postalCode: null,
          state: null,
        },
      },
      phone: null,
      metadata: "{}",
    },
    stripeOrderId: "",
    cartId: "",
    checkoutId: "",
    cartData: null,
    isNewsletterChecked: false,
  },
};

export const createGlobalStore = () => {
  createStore(defaultState);
};

export const updateAction = (state: GlobalState, payload: any) => {
  if (__DEV__) {
    console.log("state:", state);
    console.log("payload:", payload);
  }
  return {
    ...state,
    ...payload,
  };
};

export const updateDataAction = (state: GlobalState, payload: any) => {
  if (__DEV__) {
    console.log("state:", state);
    console.log("payload:", payload);
  }
  return {
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  };
};

export const updateCustomerAction = (state: GlobalState, payload: any) => {
  if (__DEV__) {
    console.log("state:", state);
    console.log("payload:", payload);
  }
  return {
    ...state,
    data: {
      ...state.data,
      customer: {
        ...state.data.customer,
        ...payload,
      },
    },
  };
};

export const clearStateAction = (state: GlobalState, payload: any) => {
  if (__DEV__) {
    console.log("state:", state);
    console.log("payload:", payload);
  }
  return defaultState;
};
