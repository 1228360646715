import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  ChipField,
  DatagridConfigurable,
  Edit,
  EditButton,
  ExportButton,
  FilterButton,
  ImageField,
  List,
  Pagination,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectColumnsButton,
  SelectInput,
  Show,
  ShowButton,
  SingleFieldList,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  TranslatableFields,
  TranslatableInputs,
  WrapperField,
  required,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { Control, useForm, useWatch } from "react-hook-form";
import ImageArrayInputWithPreview, { ImageArrayFieldWithPreview } from "../components/ImageArrayInputWithPreview";
import { PricesTabHeader } from "../components/PricesTabHeader";
import { SummaryTabHeader } from "../components/SummaryTabHeader";
import { TranslationsTabHeader } from "../components/TranslationsTabHeader";
import dataProvider from "../providers/grapheneDataProvider2";
import { Locale } from "./locales";

const filterToQueryTags = (searchText: string) => ({ name__icontains: `${searchText}` });
const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;
const productFilters = [
  <TextInput label="ID" source="pk" alwaysOn />,
  <TextInput label="Name" source="name__icontains" alwaysOn />,
  <TextInput label="SKU" source="prodSku__icontains" alwaysOn />,
  <ReferenceArrayInput source="tags__all" reference="productTags" alwaysOn perPage={100}>
    <AutocompleteArrayInput label="Tags in" filterToQuery={filterToQueryTags} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="tags__nin" reference="productTags" alwaysOn perPage={100}>
    <AutocompleteArrayInput label="Tags not in" filterToQuery={filterToQueryTags} />
  </ReferenceArrayInput>,
];

const MyActions = () => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton />
    <ExportButton />
  </TopToolbar>
);

const PriceField = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.unitAmount} {record.currency.toUpperCase()} ({record.priceId}) {record.type}{" "}
      {record.type === "recurring" && record.recurringIntervalCount > 1 && record.recurringIntervalCount}{" "}
      {record.type === "recurring" && record.recurringInterval && record.recurringInterval}{" "}
      {record.type === "recurring" && "trial days "} {record.type === "recurring" && record.recurringTrialPeriodDays}{" "}
    </span>
  );
};

export const NewProductList = (props: any) => {
  return (
    <List filters={productFilters} actions={<MyActions />} pagination={<MyPagination />}>
      <DatagridConfigurable
        omit={[
          "descriptionEn",
          "descriptionDe",
          "prodSku",
          "prodBarcode",
          "prodOrderTitleEn",
          "prodOrderTitleDe",
          "prodMarketingTitleEn",
          "prodMarketingTitleDe",
          "prodImageTooltipTextEn",
          "prodImageTooltipTextDe",
        ]}
      >
        <TextField source="productId" />
        <ImageField sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }} source="image" />
        <TextField source="name" />
        <ReferenceArrayField label="Tags" reference="productTags" source="tags" perPage={100}>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <WrapperField label="Actions">{props.hasEdit ? <EditButton /> : <ShowButton />}</WrapperField>
        <TextField source="prodSku" />
        <TextField source="prodBarcode" />
      </DatagridConfigurable>
    </List>
  );
};

interface PricesFormProps {
  control: Control<any>;
}

const PricesShow: React.FC = () => {
  const record = useRecordContext();
  const localeIds = record.locales;
  const [priceLocaleKeys, setPriceLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let p_keys: string[] = [];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedCurrencies.forEach((curr) => p_keys.push(`${curr}-${locale.country}`));
        });
        setPriceLocaleKeys([...new Set(p_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!priceLocaleKeys || priceLocaleKeys.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <TranslatableFields
        locales={priceLocaleKeys}
        defaultLocale={priceLocaleKeys[0]}
        // sx={{ minWidth: "400px" }}
        groupKey="prices"
      >
        <ReferenceField source="prices.crossedPrice" reference="prices">
          <PriceField />
          {/* <TextField label="Crossed Price" optionText={} /> */}
        </ReferenceField>
        <ReferenceField source="prices.currentPrice" reference="prices">
          <PriceField />
          {/* <TextField label="Current Price" optionText={<PriceField />} /> */}
        </ReferenceField>
        <TextField
          source="prices.productSku"
          // helperText={"(Optional) Product Overwrite sku default:  " + record.prodSku}
        />
      </TranslatableFields>
    </>
  );
};

const TranslationsShow: React.FC = () => {
  const record = useRecordContext();
  const localeIds = record.locales;
  const [translationLocaleKeys, setTranslationLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let t_keys: string[] = ["en-GB"];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedLanguages.forEach((lang) => t_keys.push(`${lang}-${locale.country}`));
        });
        setTranslationLocaleKeys([...new Set(t_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!translationLocaleKeys) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Alert severity="info" sx={{ width: "100%" }}>
        "en-GB" fields are required
      </Alert>
      <TranslatableFields
        locales={translationLocaleKeys}
        defaultLocale={"en-GB"}
        // sx={{ width: "100%" }}
        groupKey="translations"
      >
        <TextField
          source="translations.prodMarketingTitle"
          label="Marketing title"
          // helperText="Title used in marketing (emails etc.)"
        />
        <TextField
          source="translations.prodOrderTitle"
          label="Order title"
          // helperText="Title used in order details"
        />
        <TextField
          source="translations.description"
          // helperText="Description of the product"
          label="Description"
          // multiline
          // minRows={2}
        />

        <TextField
          source="translations.prodImageTitle"
          label="Image title"
          // helperText="Alt text for the product's image"
        />
        <TextField
          source="translations.prodImageCornerNote"
          label="Image corner note"
          // helperText="Text to be shown in the corner of the product image"
        />
        <TextField
          source="translations.prodImageTooltipText"
          label="Image tooltip text"
          // helperText="Text to show on the image as a tooltip"
          // multiline
          // minRows={2}
        />
        <ImageArrayFieldWithPreview source="translations.images" />
      </TranslatableFields>
    </>
  );
};

export const NewProductsShow = () => {
  // const { register, control, handleSubmit } = useForm();

  return (
    <Show>
      <TabbedShowLayout syncWithLocation={false}>
        <TabbedShowLayout.Tab label={<SummaryTabHeader />}>
          <TextField source="name" sx={{ minWidth: "400px" }} />
          <ReferenceArrayField label="Tags" reference="productTags" source="tags" perPage={100}>
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="prodSku" sx={{ minWidth: "400px" }} />
          <TextField source="prodBarcode" sx={{ minWidth: "400px" }} />
          <TextField source="image" sx={{ minWidth: "400px" }} />
          <ImageField sx={{ "& img": { maxWidth: 400, maxHeight: 100, objectFit: "contain" } }} source="image" />
          <ReferenceArrayField source="locales" reference="locales">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={<TranslationsTabHeader />}>
          <TranslationsShow />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={<PricesTabHeader />}>
          <PricesShow />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const PricesInputForm: React.FC<PricesFormProps> = () => {
  const record = useRecordContext();
  const localeIds = useWatch({ name: "locales" });
  const [priceLocaleKeys, setPriceLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let p_keys: string[] = [];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedCurrencies.forEach((curr) => p_keys.push(`${curr}-${locale.country}`));
        });
        setPriceLocaleKeys([...new Set(p_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!priceLocaleKeys || priceLocaleKeys.length === 0) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <TranslatableInputs
        locales={priceLocaleKeys}
        defaultLocale={priceLocaleKeys[0]}
        sx={{ minWidth: "400px" }}
        groupKey="prices"
      >
        <ReferenceInput source="prices.crossedPrice" reference="prices" filter={{ product: record.id }}>
          <SelectInput fullWidth label="Crossed Price" optionText={<PriceField />} />
        </ReferenceInput>
        <ReferenceInput source="prices.currentPrice" reference="prices" filter={{ product: record.id }}>
          <SelectInput fullWidth label="Current Price" optionText={<PriceField />} />
        </ReferenceInput>
        <TextInput
          source="prices.productSku"
          helperText={"(Optional) Product Overwrite sku default:  " + record.prodSku}
        />
      </TranslatableInputs>
    </>
  );
};

const TranslationsInputForm: React.FC<PricesFormProps> = () => {
  const record = useRecordContext();
  const localeIds = useWatch({ name: "locales" });
  const [translationLocaleKeys, setTranslationLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let t_keys: string[] = ["en-GB"];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedLanguages.forEach((lang) => t_keys.push(`${lang}-${locale.country}`));
        });
        setTranslationLocaleKeys([...new Set(t_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!translationLocaleKeys) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Alert severity="info" sx={{ width: "100%" }}>
        "en-GB" fields are required
      </Alert>
      <TranslatableInputs
        locales={translationLocaleKeys}
        defaultLocale={"en-GB"}
        sx={{ width: "100%" }}
        groupKey="translations"
      >
        <TextInput
          source="translations.prodMarketingTitle"
          label="Marketing title"
          helperText="Title used in marketing (emails etc.)"
        />
        <TextInput source="translations.prodOrderTitle" label="Order title" helperText="Title used in order details" />
        <TextInput
          source="translations.description"
          helperText="Description of the product"
          label="Description"
          // multiline
          // minRows={2}
        />

        <TextInput
          source="translations.prodImageTitle"
          label="Image title"
          helperText="Alt text for the product's image"
        />
        <TextInput
          source="translations.prodImageCornerNote"
          label="Image corner note"
          helperText="Text to be shown in the corner of the product image"
        />
        <TextInput
          source="translations.prodImageTooltipText"
          label="Image tooltip text"
          helperText="Text to show on the image as a tooltip"
          // multiline
          // minRows={2}
        />
        <ImageArrayInputWithPreview source="translations.images" />
      </TranslatableInputs>
    </>
  );
};

export const NewProductsEdit = () => {
  const { register, control, handleSubmit } = useForm();

  return (
    <Edit>
      <TabbedForm syncWithLocation={false}>
        <TabbedForm.Tab label={<SummaryTabHeader />}>
          <TextInput source="name" validate={required()} sx={{ minWidth: "400px" }} />
          <ReferenceArrayInput label="Tags" reference="productTags" source="tags" perPage={100}>
            <AutocompleteArrayInput
              validate={required()}
              label="Tags"
              optionText={(record) => `${record.name}`}
              filterToQuery={filterToQueryTags}
            />
          </ReferenceArrayInput>
          <TextInput source="prodSku" sx={{ minWidth: "400px" }} />
          <TextInput source="prodBarcode" sx={{ minWidth: "400px" }} />
          <TextInput source="image" sx={{ minWidth: "400px" }} />
          <ImageField sx={{ "& img": { maxWidth: 400, maxHeight: 100, objectFit: "contain" } }} source="image" />
          <ReferenceArrayInput source="locales" reference="locales">
            <AutocompleteArrayInput validate={required()} label="Locales" optionText={(record) => `${record.name}`} />
          </ReferenceArrayInput>
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<TranslationsTabHeader />}>
          <TranslationsInputForm control={control} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<PricesTabHeader />}>
          <PricesInputForm control={control} />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};
