import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  CreateButton,
  DatagridConfigurable,
  Edit,
  EditButton,
  FilterButton,
  FunctionField,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SearchInput,
  SelectColumnsButton,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  WithListContext,
  WrapperField,
} from "react-admin";

import { Chip, Typography } from "@mui/material";
import LocalisedDateField from "../components/localisedDateField";

export interface Locale {
  id: string;
  active: boolean;
  name: string;
  country: string;
  countryEmoji: string;
  countryFull: string;
  defaultCurrency: string;
  defaultCurrencyFull: string;
  defaultLanguage: string;
  defaultLanguageFull: string;
  supportedCurrencies: string[];
  supportedLanguages: string[];
}

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const userFilters = [<SearchInput source="name__icontains" alwaysOn={true} placeholder="Name" />];

export const LocalesList = (props: any) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={userFilters}
      title={"Locales"}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="name" />
        <FunctionField
          render={(record: any) => (
            <span title={record.countryFull} style={{ fontSize: "24px" }}>
              {record.countryEmoji}
            </span>
          )}
          label="Country"
        />
        <TextField source="defaultLanguageFull" label="Default Language" />
        <TextField source="defaultCurrencyFull" label="Default Currency" />
        <FunctionField
          render={(record: any) => (
            <LocalisedDateField record={record} field="createdAt" compact={true} showTime={true} />
          )}
          label="Created At"
        />
        <FunctionField
          render={(record: any) => (
            <LocalisedDateField record={record} field="updatedAt" compact={true} showTime={true} />
          )}
          label="Updated At"
        />
        <WrapperField label="Actions">
          <ShowButton />
          <EditButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

const LanguageAndCountryFilterToQuery = (query: string) => {
  if (query.length == 2) {
    return { id__exact: query };
  }
  return { name__icontains: query };
};

export const LocaleCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ReferenceInput source="country" reference="allCountries">
          <AutocompleteInput
            optionText={(option) => {
              return `${option.flag} ${option.id} - ${option.name}`;
            }}
            filterToQuery={LanguageAndCountryFilterToQuery}
            sx={{ width: "300px" }}
            validate={required()}
          />
        </ReferenceInput>
        <ReferenceInput source="defaultLanguage" reference="allLanguages">
          <AutocompleteInput
            optionText={(option) => {
              return `${option.id} - ${option.name}`;
            }}
            filterToQuery={LanguageAndCountryFilterToQuery}
            sx={{ width: "300px" }}
            validate={required()}
          />
        </ReferenceInput>

        <ReferenceInput source="defaultCurrency" reference="allCurrencies">
          <AutocompleteInput
            optionText={(option) => {
              return `${option.id} - ${option.name}`;
            }}
            sx={{ width: "300px" }}
            validate={required()}
          />
        </ReferenceInput>
        <ReferenceArrayInput source="supportedCurrencies" reference="allCurrencies">
          <AutocompleteArrayInput sx={{ minWidth: "300px" }} optionText={(record) => `${record.id} - ${record.name}`} />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="supportedLanguages" reference="allLanguages">
          <AutocompleteArrayInput
            sx={{ minWidth: "300px" }}
            filterToQuery={LanguageAndCountryFilterToQuery}
            optionText={(record) => `${record.id} - ${record.name}`}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

const LocaleShowAside = () => {
  const record = useRecordContext();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Metadata</Typography>
      <p>
        Status:{" "}
        <Chip label={record.active ? "Active" : "Archived"} color={record.active ? "success" : "error"} size="small" />
      </p>
      <p>
        Created <LocalisedDateField record={record} field="createdAt" showTime={true} />
      </p>
      <p>
        Updated <LocalisedDateField record={record} field="updatedAt" showTime={true} />{" "}
      </p>
    </div>
  );
};

const LocaleShowTitle = () => {
  const locale: Locale = useRecordContext();
  if (!locale) return null;
  return (
    <span>
      Locale - {locale.name} {locale.countryEmoji}
    </span>
  );
};

export const LocaleShow = () => {
  return (
    <Show aside={<LocaleShowAside />} title={<LocaleShowTitle />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <FunctionField
          label="Country"
          render={(locale: Locale) => {
            return (
              <>
                {locale.country} - {locale.countryFull}{" "}
                <span style={{ fontSize: "24px", verticalAlign: "middle" }}>{locale.countryEmoji}</span>
              </>
            );
          }}
        />
        <FunctionField
          label="Default Language"
          render={(locale: Locale) => {
            return (
              <>
                {locale.defaultLanguage} - {locale.defaultLanguageFull}
              </>
            );
          }}
        />
        <FunctionField
          label="Default Currency"
          render={(locale: Locale) => {
            return (
              <>
                {locale.defaultCurrency} - {locale.defaultCurrencyFull}
              </>
            );
          }}
        />
        <ReferenceArrayField source="supportedCurrencies" reference="allCurrencies">
          <WithListContext
            render={({ data }) => (
              <ul>
                {data.map((tag) => (
                  <li key={tag.id}>
                    {tag.id} - {tag.name}
                  </li>
                ))}
              </ul>
            )}
          />
        </ReferenceArrayField>
        <ReferenceArrayField source="supportedLanguages" reference="allLanguages">
          <WithListContext
            render={({ data }) => (
              <ul>
                {data.map((tag) => (
                  <li key={tag.id}>
                    {tag.id} - {tag.name}
                  </li>
                ))}
              </ul>
            )}
          />
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export const LocaleEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ReferenceInput source="country" reference="allCountries">
          <AutocompleteInput
            optionText={(option) => {
              return `${option.flag} ${option.id} - ${option.name}`;
            }}
            filterToQuery={LanguageAndCountryFilterToQuery}
            sx={{ width: "300px" }}
            validate={required()}
          />
        </ReferenceInput>
        <ReferenceInput source="defaultLanguage" reference="allLanguages">
          <AutocompleteInput
            optionText={(option) => {
              return `${option.id} - ${option.name}`;
            }}
            filterToQuery={LanguageAndCountryFilterToQuery}
            sx={{ width: "300px" }}
            validate={required()}
          />
        </ReferenceInput>

        <ReferenceInput source="defaultCurrency" reference="allCurrencies">
          <AutocompleteInput
            optionText={(option) => {
              return `${option.id} - ${option.name}`;
            }}
            sx={{ width: "300px" }}
            validate={required()}
          />
        </ReferenceInput>
        <ReferenceArrayInput source="supportedCurrencies" reference="allCurrencies">
          <AutocompleteArrayInput sx={{ minWidth: "300px" }} optionText={(record) => `${record.id} - ${record.name}`} />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="supportedLanguages" reference="allLanguages">
          <AutocompleteArrayInput
            sx={{ minWidth: "300px" }}
            filterToQuery={LanguageAndCountryFilterToQuery}
            optionText={(record) => `${record.id} - ${record.name}`}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
