import {
  DatagridConfigurable,
  EmailField,
  FilterButton,
  FunctionField,
  List,
  SearchInput,
  SelectColumnsButton,
  ShowButton,
  TextField,
  TopToolbar,
  WrapperField,
} from "react-admin";

import LocalisedDateField from "../components/localisedDateField";
import StartDateEndDateInput from "../components/startDateEndDateInput";
import grapheneDataProvider from "../providers/grapheneDataProvider2";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const userFilters = [
  <SearchInput
    source="email__icontains"
    alwaysOn={true}
    placeholder="Order Email"
  />,
  <SearchInput
    source="customer__email__icontains"
    placeholder="Customer Email"
  />,
  <SearchInput
    source="customer__shippingAddress__firstName__icontains"
    placeholder="Shipping First Name"
    alwaysOn={true}
  />,
  <SearchInput
    source="customer__shippingAddress__lastName__icontains"
    placeholder="Shipping Last Name"
    alwaysOn={true}
  />,
  <SearchInput
    source="customer__billingAddress__firstName__icontains"
    placeholder="Billing First Name"
  />,
  <SearchInput
    source="customer__billingAddress__lastName__icontains"
    placeholder="Billing Last Name"
  />,
  <SearchInput source="id" placeholder="Order ID" alwaysOn={true} />,
  <StartDateEndDateInput source="createdAt" />,
];

const ShopifyOrdersList = (props: any) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      dataProvider={grapheneDataProvider}
      filters={userFilters}
      title={"Shopify Orders"}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <EmailField source="email" label="Order Email" />
        <TextField
          source="customer.shippingAddress.firstName"
          label="Shipping First Name"
        />
        <TextField
          source="customer.shippingAddress.lastName"
          label="Shipping Last Name"
        />
        <TextField
          source="customer.shippingAddress.countryEmoji"
          sx={{ fontSize: "24px" }}
          label="Shipping Country"
        />
        <FunctionField
          render={(record: any) => (
            <LocalisedDateField
              record={record}
              field="createdAt"
              compact={true}
              showTime={true}
            />
          )}
          label="Order DateTime"
        />
        <FunctionField
          render={(record: any) => <>{record.items.length}</>}
          label="Item Count"
        />
        <WrapperField label="Actions">
          <ShowButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export default ShopifyOrdersList;
