import { __DEV__ } from "../api";

const timeZoneCityToCountryJson = require("../data/timeZoneToCountry.json");

const userTimeZoneCountryCode = () => {
  let userRegion;
  let userCity;
  let userCountry = "AT";
  let userTimeZone;

  if (Intl) {
    userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tzArr = userTimeZone.split("/");
    userRegion = tzArr[0];
    userCity = tzArr[tzArr.length - 1];
    userCountry = timeZoneCityToCountryJson[userCity];
  }
  if (__DEV__) {
    console.log("Time Zone:", userTimeZone);
    console.log("Region:", userRegion);
    console.log("City:", userCity);
    console.log("Country:", userCountry);
  }

  return userCountry;
};

export default userTimeZoneCountryCode();
