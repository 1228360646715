import { Avatar, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { AutocompleteInput, ReferenceInput, TextInput, useInput } from "react-admin";
import dataProvider from "../providers/grapheneDataProvider2";

const ProductSelectionFilter = (searchText: string) => {
  if (!searchText) return {};
  if (searchText.startsWith("prod_")) {
    return { productId__icontains: `${searchText}` };
  } else if (searchText.startsWith("price_")) {
    return { price_id__istartswith: `${searchText}` };
  }
  return {
    name__icontains: `${searchText}`,
  };
};

interface Product {
  image: string;
  name: string;
}

const ProductSelectionInputWithPreview = ({ source = "products.productId", disabled = false, value = "" }) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const { field } = useInput({ source });

  useEffect(() => {
    if (field.value) {
      dataProvider
        .getOne("v2Products", { id: field.value, fields: "image" })
        .then(({ data }: any) => setSelectedProduct(data))
        .catch((err) => console.error(err));
    }
  }, [field.value]);

  return value ? (
    <TextInput source="" defaultValue={value} disabled />
  ) : (
    <ReferenceInput
      label="Product"
      source={source}
      reference="v2Products"
      enableGetChoices={({ q }) => q && q.length >= 2}
      disabled={disabled}
    >
      <>
        {selectedProduct && selectedProduct.image ? (
          <Box display="flex" alignItems="center" mt={2}>
            <Avatar
              src={selectedProduct.image}
              alt={selectedProduct.name}
              sx={{ width: 50, height: 50, marginRight: 2 }}
            />
          </Box>
        ) : (
          <Box display="flex" alignItems="center" mb={2} sx={{ width: 50, height: 50, marginRight: 2 }} />
        )}
        <AutocompleteInput
          label="Product"
          helperText="Lookup products by their name &amp; ID, or by price ID"
          inputText={(option) => `${option.name} \n [${option.id}]`}
          optionText={(option) => `${option.name} \n [${option.id}]`}
          filterToQuery={ProductSelectionFilter}
          sx={{ width: "80%" }}
        />
      </>
    </ReferenceInput>
  );
};

export default ProductSelectionInputWithPreview;
