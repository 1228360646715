import React from "react";
import ResizableBox from "../components/ResizableBox";
import { Box, CardContent, CircularProgress, Container, Typography, styled } from "@mui/material";
import moment from "moment";
import { useOrdersCharts } from "../hooks/useOrder";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Label } from "@mui/icons-material";
import { round2digits } from "../components/utils";
import { __DEV__ } from "../api";

const ChartTitle = styled("div")(({ theme }) => ({
  ...theme.typography.h5,
  // color: theme.palette.primary.main,
  // fontFamily: "Gotham",
  padding: "10px",
  fontWeight: 500,
  fontSize: "22px",
  lineHeight: "30px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

type OrderTotal = {
  createdAt: Date;
  amountTotal: number;
  amountTotalEUR?: number;
  amountTotalGBP?: number;
  createdAtLabel?: String;
  productCount?: number;
  brushCount?: number;
  gamesCount?: number;
  brushheadCount?: number;
  toothpasteCount?: number;
};

type Series = {
  label: string;
  data: OrderTotal[];
};

const dataDefalut: Series[] = [
  {
    label: "React Charts",
    data: [
      {
        createdAt: new Date(),
        amountTotal: 202123,
      },
      {
        createdAt: new Date(new Date().setDate(new Date().getDate() - 1)),
        amountTotal: 20212,
      },
    ],
  },
  {
    label: "React Query",
    data: [
      {
        createdAt: new Date(),
        amountTotal: 10234230,
      },
      {
        createdAt: new Date(new Date().setDate(new Date().getDate() - 1)),
        amountTotal: 202129,
      },
    ],
  },
];
//  primaryAxis: AxisOptions<TDatum>
//  Primary Value Accessor
//  secondaryAxes: AxisOptions<TDatum>[]
//  Primary Value Accessor
// type ReactText = string | number;
// type ReactChild = React.ReactElement | ReactText;

// interface ReactNodeArray extends Array<ReactNode> {}
// type ReactFragment = {} | ReactNodeArray;
// type ReactNode = ReactChild | ReactFragment | React.ReactPortal | boolean | null | undefined;

// type Props = {
//   children: ReactNode
// }

function FlexBoxCenter({ children }: React.PropsWithChildren<{}>) {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", height: "100%" }}
    >
      {children}
    </Box>
  );
}

export function OrderCharts() {
  const [chartProcessLoading, setChartProcessLoading] = React.useState(true);
  const [chartDataDailyCurrency, setChartDataDailyCurrency] = React.useState(dataDefalut[0].data);
  const [chartDataHourlyCurrency, setChartDataHourlyCurrency] = React.useState(dataDefalut[0].data);
  const yesterday = moment().subtract(6, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
  // const yesterday = moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
  const today = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

  function dataToChartDataHourly(data: GQL.Orders[]): OrderTotal[] {
    let daysBetween = moment().subtract(2, "days").startOf("day");
    const dayEnd = moment().endOf("day");
    let initial: OrderTotal[] = [];
    while (dayEnd.unix() >= daysBetween.unix()) {
      initial.push({ amountTotal: 0, createdAt: daysBetween.toDate() });
      daysBetween = daysBetween.add(1, "hours");
    }

    return data.reduce((acc: OrderTotal[], curr) => {
      const time = moment(curr.createdAt.substr(0, 13) + ":00:00").toDate();
      const accNode: any = acc.find((node: OrderTotal) => node.createdAt.valueOf() === time.valueOf());
      if (accNode && curr.amountTotal) {
        accNode.amountTotal += curr.amountTotal / 100;
      } else {
        if (curr.amountTotal) {
          acc.push({ amountTotal: curr.amountTotal / 100, createdAt: time });
        }
      }
      return acc;
    }, initial);
  }

  function dataToChartDataDaily(data: GQL.Orders[]): OrderTotal[] {
    let daysBetween = moment().subtract(2, "days").startOf("day");
    const dayEnd = moment().endOf("day");
    let initial: OrderTotal[] = [];
    while (dayEnd.unix() >= daysBetween.unix()) {
      initial.push({ amountTotal: 0, createdAt: daysBetween.toDate() });
      daysBetween = daysBetween.add(1, "days");
    }

    return data.reduce((acc: OrderTotal[], curr) => {
      const time = moment(curr.createdAt.substr(0, 10) + "T00:00:00").toDate();
      const accNode: any = acc.find((node: OrderTotal) => node.createdAt.valueOf() === time.valueOf());
      if (accNode && curr.amountTotal) {
        accNode.amountTotal += curr.amountTotal / 100;
      } else {
        if (curr.amountTotal) {
          acc.push({ amountTotal: curr.amountTotal / 100, createdAt: time });
        }
      }
      return acc;
    }, initial);
  }

  function dataToChartDataHourlyCurrency(data: GQL.Orders[]): OrderTotal[] {
    let daysBetween = moment().subtract(2, "days").startOf("day");
    const dayEnd = moment().endOf("day");
    let initial: OrderTotal[] = [];
    while (dayEnd.unix() >= daysBetween.unix()) {
      initial.push({
        amountTotal: 0,
        amountTotalEUR: 0,
        amountTotalGBP: 0,
        createdAt: daysBetween.toDate(),
        createdAtLabel: daysBetween.format("DD MMM HH:00"),
        productCount: 0,
        brushCount: 0,
        gamesCount: 0,
        brushheadCount: 0,
        toothpasteCount: 0,
      });
      daysBetween = daysBetween.add(1, "hours");
    }

    return data
      .reduce((acc: OrderTotal[], curr) => {
        const time = moment(curr.createdAt.substr(0, 13) + ":00:00").toDate();
        const timeLabel = moment(curr.createdAt.substr(0, 13) + ":00:00").format("DD MMM HH:00");
        const accNode: any = acc.find((node: OrderTotal) => node.createdAt.valueOf() === time.valueOf());
        if (accNode && curr.amountTotal) {
          if (curr.currency === "EUR") {
            accNode.amountTotalEUR += curr.amountTotal / 100;
          } else if (curr.currency === "GBP") {
            accNode.amountTotalGBP += curr.amountTotal / 100;
          }
          accNode.amountTotal += curr.amountTotal / 100;
          accNode.productCount += curr.orderItems ? curr.orderItems.length : 0;
          accNode.brushCount += curr.shippingItemsBrushCount ? curr.shippingItemsBrushCount : 0;
          accNode.gamesCount += curr.shippingItemsGamesSubCount ? curr.shippingItemsGamesSubCount : 0;
          accNode.brushheadCount += curr.shippingItemsBrushHeadCount ? curr.shippingItemsBrushHeadCount : 0;
          accNode.toothpasteCount += curr.shippingItemsToothPasteCount ? curr.shippingItemsToothPasteCount : 0;
        } else {
          if (curr.amountTotal) {
            if (curr.currency === "EUR") {
              acc.push({
                amountTotal: curr.amountTotal / 100,
                amountTotalEUR: curr.amountTotal / 100,
                createdAt: time,
                createdAtLabel: timeLabel,
                productCount: curr.orderItems ? curr.orderItems.length : 0,
                brushCount: curr.shippingItemsBrushCount ? curr.shippingItemsBrushCount : 0,
                gamesCount: curr.shippingItemsGamesSubCount ? curr.shippingItemsGamesSubCount : 0,
                brushheadCount: curr.shippingItemsBrushHeadCount ? curr.shippingItemsBrushHeadCount : 0,
                toothpasteCount: curr.shippingItemsToothPasteCount ? curr.shippingItemsToothPasteCount : 0,
              });
            } else if (curr.currency === "GBP") {
              acc.push({
                amountTotal: curr.amountTotal / 100,
                amountTotalGBP: curr.amountTotal / 100,
                createdAt: time,
                createdAtLabel: timeLabel,
                productCount: curr.orderItems ? curr.orderItems.length : 0,
                brushCount: curr.shippingItemsBrushCount ? curr.shippingItemsBrushCount : 0,
                gamesCount: curr.shippingItemsGamesSubCount ? curr.shippingItemsGamesSubCount : 0,
                brushheadCount: curr.shippingItemsBrushHeadCount ? curr.shippingItemsBrushHeadCount : 0,
                toothpasteCount: curr.shippingItemsToothPasteCount ? curr.shippingItemsToothPasteCount : 0,
              });
            }
          }
        }
        return acc;
      }, initial)
      .map((obj) => {
        obj.amountTotalEUR = obj.amountTotalEUR ? round2digits(obj.amountTotalEUR) : 0;
        obj.amountTotalGBP = obj.amountTotalGBP ? round2digits(obj.amountTotalGBP) : 0;
        return obj;
      })
      .sort(function (a: OrderTotal, b: OrderTotal) {
        return a.createdAt.getTime() - b.createdAt.getTime();
      });
  }

  function dataToChartDataDailyCurrency(data: GQL.Orders[]): OrderTotal[] {
    let daysBetween = moment().subtract(2, "days").startOf("day");
    const dayEnd = moment().endOf("day");
    let initial: OrderTotal[] = [];
    while (dayEnd.unix() >= daysBetween.unix()) {
      initial.push({
        amountTotal: 0,
        amountTotalEUR: 0,
        amountTotalGBP: 0,
        createdAt: daysBetween.toDate(),
        createdAtLabel: daysBetween.format("DD MMM"),
        productCount: 0,
        brushCount: 0,
        gamesCount: 0,
        brushheadCount: 0,
        toothpasteCount: 0,
      });
      daysBetween = daysBetween.add(1, "days");
    }

    return data
      .reduce((acc: OrderTotal[], curr) => {
        const time = moment(curr.createdAt.substr(0, 10) + "T00:00:00").toDate();
        const timeLabel = moment(curr.createdAt.substr(0, 10) + "T00:00:00").format("DD MMM");
        const accNode: any = acc.find((node: OrderTotal) => node.createdAt.valueOf() === time.valueOf());
        if (accNode && curr.amountTotal) {
          if (curr.currency === "EUR") {
            accNode.amountTotalEUR += curr.amountTotal / 100;
          } else if (curr.currency === "GBP") {
            accNode.amountTotalGBP += curr.amountTotal / 100;
          }
          accNode.amountTotal += curr.amountTotal / 100;
          accNode.productCount += curr.orderItems ? curr.orderItems.length : 0;
          accNode.brushCount += curr.shippingItemsBrushCount ? curr.shippingItemsBrushCount : 0;
          accNode.gamesCount += curr.shippingItemsGamesSubCount ? curr.shippingItemsGamesSubCount : 0;
          accNode.brushheadCount += curr.shippingItemsBrushHeadCount ? curr.shippingItemsBrushHeadCount : 0;
          accNode.toothpasteCount += curr.shippingItemsToothPasteCount ? curr.shippingItemsToothPasteCount : 0;
        } else {
          if (curr.amountTotal) {
            if (curr.currency === "EUR") {
              acc.push({
                amountTotal: curr.amountTotal / 100,
                amountTotalEUR: curr.amountTotal / 100,
                amountTotalGBP: 0,
                createdAt: time,
                createdAtLabel: timeLabel,
                productCount: curr.orderItems ? curr.orderItems.length : 0,
                brushCount: curr.shippingItemsBrushCount ? curr.shippingItemsBrushCount : 0,
                gamesCount: curr.shippingItemsGamesSubCount ? curr.shippingItemsGamesSubCount : 0,
                brushheadCount: curr.shippingItemsBrushHeadCount ? curr.shippingItemsBrushHeadCount : 0,
                toothpasteCount: curr.shippingItemsToothPasteCount ? curr.shippingItemsToothPasteCount : 0,
              });
            } else if (curr.currency === "GBP") {
              acc.push({
                amountTotal: curr.amountTotal / 100,
                amountTotalEUR: 0,
                amountTotalGBP: curr.amountTotal / 100,
                createdAt: time,
                createdAtLabel: timeLabel,
                productCount: curr.orderItems ? curr.orderItems.length : 0,
                brushCount: curr.shippingItemsBrushCount ? curr.shippingItemsBrushCount : 0,
                gamesCount: curr.shippingItemsGamesSubCount ? curr.shippingItemsGamesSubCount : 0,
                brushheadCount: curr.shippingItemsBrushHeadCount ? curr.shippingItemsBrushHeadCount : 0,
                toothpasteCount: curr.shippingItemsToothPasteCount ? curr.shippingItemsToothPasteCount : 0,
              });
            }
          }
        }
        return acc;
      }, initial)
      .map((obj) => {
        obj.amountTotalEUR = obj.amountTotalEUR ? round2digits(obj.amountTotalEUR) : 0;
        obj.amountTotalGBP = obj.amountTotalGBP ? round2digits(obj.amountTotalGBP) : 0;
        return obj;
      })
      .sort(function (a, b) {
        return a.createdAt.getTime() - b.createdAt.getTime();
      });
  }

  function DataGet(data: GQL.Orders[]) {
    const chartDataHourlyCurrency = dataToChartDataHourlyCurrency(data);
    const chartDataDailyCurrency = dataToChartDataDailyCurrency(data);

    if (__DEV__) {
      console.log("chartDataDailyCurrency", chartDataDailyCurrency);
    }
    setChartDataDailyCurrency(chartDataDailyCurrency);
    setChartDataHourlyCurrency(chartDataHourlyCurrency);
    setChartProcessLoading(false);
  }

  const queryOrdersCharts = useOrdersCharts({ dateFrom: yesterday, dateTo: today }, DataGet);
  React.useEffect(() => {}, []);

  return (
    <Container component="main" maxWidth="lg" sx={{ mt: 2, mb: 12 }}>
      <Box sx={{ mt: 2, mb: 12 }}>
        <ChartTitle>Orders sum per hour</ChartTitle>
        <ResizableBox>
          {queryOrdersCharts.isLoading && chartProcessLoading ? (
            <FlexBoxCenter>
              <CircularProgress />
            </FlexBoxCenter>
          ) : queryOrdersCharts.isError ? (
            <FlexBoxCenter>"Error, please refresh"</FlexBoxCenter>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={chartDataHourlyCurrency}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="createdAtLabel" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="amountTotalEUR"
                  fill="#0f83ab"
                  activeBar={<Rectangle fill="#13a0cf" stroke="#13a0cf" />}
                />
                <Bar dataKey="amountTotalGBP" fill="#bd3afa" activeBar={<Rectangle fill="gold" stroke="purple" />} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </ResizableBox>
        <ChartTitle>Order brush items per hour</ChartTitle>
        <ResizableBox>
          {queryOrdersCharts.isLoading && chartProcessLoading ? (
            <FlexBoxCenter>
              <CircularProgress />
            </FlexBoxCenter>
          ) : queryOrdersCharts.isError ? (
            <FlexBoxCenter>"Error, please refresh"</FlexBoxCenter>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={chartDataHourlyCurrency}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="createdAtLabel" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="brushCount" fill="#0f83ab" activeBar={<Rectangle fill="#13a0cf" stroke="#13a0cf" />} />
                {/* <Bar dataKey="amountTotalGBP" fill="#bd3afa" activeBar={<Rectangle fill="gold" stroke="purple" />} /> */}
              </BarChart>
            </ResponsiveContainer>
          )}
        </ResizableBox>
        <ChartTitle>Orders sum per day</ChartTitle>
        <ResizableBox>
          {queryOrdersCharts.isLoading && chartProcessLoading ? (
            <FlexBoxCenter>
              <CircularProgress />
            </FlexBoxCenter>
          ) : queryOrdersCharts.isError ? (
            <FlexBoxCenter>"Error, please refresh"</FlexBoxCenter>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={chartDataDailyCurrency}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="createdAtLabel" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  label="Total"
                  dataKey="amountTotalEUR"
                  fill="#0f83ab"
                  activeBar={<Rectangle fill="#13a0cf" stroke="#13a0cf" />}
                />
                <Bar
                  dataKey="amountTotalGBP"
                  fill="#bd3afa"
                  activeBar={<Rectangle fill="#d172fd" stroke="#d172fd" />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </ResizableBox>
        <ChartTitle>Orders items count</ChartTitle>
        <ResizableBox>
          {queryOrdersCharts.isLoading && chartProcessLoading ? (
            <FlexBoxCenter>
              <CircularProgress />
            </FlexBoxCenter>
          ) : queryOrdersCharts.isError ? (
            <FlexBoxCenter>"Error, please refresh"</FlexBoxCenter>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={chartDataDailyCurrency}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="createdAtLabel" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="productCount" fill="#0f83ab" activeBar={<Rectangle fill="#13a0cf" stroke="#13a0cf" />} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </ResizableBox>
        <ChartTitle>Orders sum count per category</ChartTitle>
        <ResizableBox>
          {queryOrdersCharts.isLoading && chartProcessLoading ? (
            <FlexBoxCenter>
              <CircularProgress />
            </FlexBoxCenter>
          ) : queryOrdersCharts.isError ? (
            <FlexBoxCenter>"Error, please refresh"</FlexBoxCenter>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={chartDataDailyCurrency}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="createdAtLabel" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="brushCount" fill="#0f83ab" activeBar={<Rectangle fill="#13a0cf" stroke="#13a0cf" />} />
                <Bar
                  dataKey="brushheadCount"
                  fill="#faa43a"
                  activeBar={<Rectangle fill="#fcaf50" stroke="#fcaf50" />}
                />
                <Bar dataKey="gamesCount" fill="#bd3afa" activeBar={<Rectangle fill="#d172fd" stroke="#d172fd" />} />
                <Bar
                  dataKey="toothpasteCount"
                  fill="#49e384"
                  activeBar={<Rectangle fill="#82ca9d" stroke="#82ca9d" />}
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </ResizableBox>
      </Box>
    </Container>
  );
}
