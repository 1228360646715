import { DateInput, Labeled } from "react-admin";

const StartDateEndDateInput = ({ source }: any) => {
  return (
    <Labeled label={source} sx={{ position: "relative" }}>
      <>
        <DateInput
          source={source + "__gte"}
          placeholder="DD/MM/YYYY"
          label="Start"
        />
        <DateInput
          source={source + "__lte"}
          placeholder="DD/MM/YYYY"
          label="End"
        />
      </>
    </Labeled>
  );
};

export default StartDateEndDateInput;
