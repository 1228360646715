import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./en";

export const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./fr").then((messages) => messages.default);
  }
  if (locale === "de") {
    return import("./de").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");
