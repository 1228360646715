import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";

export const Dashboard = () => (
  <div style={{ margin: "20px 0px 0 0" }}>
    <Card>
      <Title title="Welcome to the administration" />
      <CardContent>Please choose from the side menu</CardContent>
    </Card>
  </div>
);
export default Dashboard;
