import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { request, gql, ClientError, GraphQLClient } from "graphql-request";
import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
import moment from "moment";

export function useProducts(
  onSuccessCustom?: (data: [GQL.Product]) => void
): UseQueryResult<[GQL.Product], ClientError> {
  return useQuery(
    "products",
    async () => {
      const { products: data } = await graphQLClient.request(
        gql`
          query {
            products(limit: 500) {
              description
              productId
              name
              image
              priceAmount
              priceAmountAfterTrial
              priceOriginal
              priceOriginalAfterTrial
              priceRecurringInterval
              priceRecurringIntervalCount
              priceRecurringTrialPeriodDays
              priceCurrency
              defaultPrice
              defaultPriceAmount
              crossedPriceEur
              crossedPriceGbp
              crossedPriceUsd
              currentPriceEur
              currentPriceGbp
              currentPriceUsd
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data;
    },
    {
      onSuccess: (data: any) => {
        if (onSuccessCustom !== undefined) onSuccessCustom!(data);
      },
    }
  );
}

// export function useArticlesCreate(
//   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
// ): UseMutationResult<GQL.IArticlesCreate, ClientError, GQL.IArticlesCreateOnMutationArguments> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`

//             mutation{
//               articlesCreate(articleDate: "${moment(dataMutation.articleDate).format("YYYYMMDD")}",
//               companyId: "${dataMutation.companyId}",
//               description: """${dataMutation.description}""",
//               documentUrl: "${dataMutation.documentUrl}",
//               isPrivate: ${dataMutation.isPrivate}){
//                 success
//               }
//             }
//         `
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         articlesList.refetch();
//       },
//       onError: (error: ClientError, variables, context) => {},
//     }
//   );
// }

// export function useArticlesDelete(
//   articlesList: UseQueryResult<[GQL.IArticlesType], ClientError>
// ): UseMutationResult<GQL.IArticlesDelete, ClientError, GQL.IArticlesDeleteOnMutationArguments> {
//   return useMutation(
//     async (dataMutation) => {
//       const data = await graphQLClient.request(
//         gql`
//           mutation{
//             articlesDelete(articlesUuid: "${dataMutation.articlesUuid}"){
//               success
//             }
//           }
//         `
//       );
//       if (__DEV__) {
//         console.log(data);
//       }
//       return data;
//     },
//     {
//       onSuccess: (data, variables, context) => {
//         articlesList.refetch();
//       },
//       onError: (error: ClientError, variables, context) => {},
//     }
//   );
// }
