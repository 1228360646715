import React from "react";
import { NumericFormat } from "react-number-format";

type Props = {
  price: number;
  currency: string;
};

const Currency: React.FC<Props> = (props: Props) => {
  // props.currency = props.currency.toUpperCase();
  if (props.currency.toUpperCase() === "GBP") {
    return (
      <NumericFormat
        value={props.price / 100}
        displayType={"text"}
        thousandSeparator={","}
        decimalSeparator={"."}
        prefix={"£ "}
      />
    );
  } else if (props.currency.toUpperCase() === "EUR") {
    return (
      <NumericFormat
        value={props.price / 100}
        displayType={"text"}
        thousandSeparator={""}
        decimalSeparator={","}
        prefix={"€ "}
      />
    );
  } else if (props.currency.toUpperCase() === "USD") {
    return (
      <NumericFormat
        value={props.price / 100}
        displayType={"text"}
        thousandSeparator={","}
        decimalSeparator={"."}
        prefix={"$ "}
        renderText={(value) => <b>{value}</b>}
      />
    );
  } else {
    return (
      <NumericFormat
        value={props.price / 100}
        displayType={"text"}
        thousandSeparator={","}
        decimalSeparator={"."}
        suffix={""}
      />
    );
  }
};

export default Currency;
