import Box from "@mui/material/Box";
import React from "react";

const GumLogo = () => {
  return (
    <Box
      style={{
        // background: "#009257", // "#039257", //"#2bb573",
        width: "220px",
        height: "108px",
        // borderRadius: "44px",
        // borderBottomRightRadius: "0px",
        // borderTopRightRadius: "0px",
        // display: "flex",
        // padding: "11px",
      }}
    >
      <svg viewBox="0 0 335.41 155.84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" className="st0" fill="none" width="335.41" height="155.84" />
        <path
          className="st1"
          fill="#009257"
          d="M82.49,129.87c-35.05,0-56.51-23.04-56.51-51.95c0-28.91,21.47-51.95,56.51-51.95h226.95v103.89H82.49z"
        />
        <g>
          <path
            className="st2"
            fill="#FFFFFF"
            d="M297.69,57.39c-0.97,0-1.88-0.23-2.72-0.69c-0.83-0.45-1.51-1.07-2.02-1.9c-0.51-0.82-0.76-1.7-0.76-2.65
          c0-0.93,0.24-1.81,0.74-2.62c0.49-0.81,1.16-1.45,2.01-1.92c0.84-0.46,1.76-0.7,2.75-0.7c0.99,0,1.91,0.23,2.76,0.7
          c0.85,0.46,1.52,1.1,2.01,1.92c0.48,0.81,0.73,1.69,0.73,2.62c0,0.96-0.25,1.84-0.76,2.65c-0.51,0.82-1.18,1.45-2.02,1.9
          C299.57,57.16,298.66,57.39,297.69,57.39z M297.69,56.22c0.7,0,1.38-0.17,2.06-0.52c0.67-0.34,1.21-0.83,1.61-1.48
          c0.41-0.65,0.61-1.34,0.61-2.07c0-0.71-0.19-1.38-0.58-2.03c-0.38-0.63-0.91-1.13-1.59-1.49c-0.69-0.37-1.39-0.55-2.11-0.55
          c-0.73,0-1.43,0.18-2.11,0.55c-0.68,0.36-1.21,0.86-1.59,1.49c-0.38,0.64-0.57,1.32-0.57,2.03c0,0.73,0.21,1.42,0.6,2.07
          c0.4,0.65,0.94,1.14,1.61,1.48C296.3,56.05,296.98,56.22,297.69,56.22z M295.47,55.35V49.1h1.91c0.73,0,1.25,0.06,1.55,0.17
          c0.3,0.11,0.54,0.29,0.71,0.52c0.18,0.23,0.27,0.54,0.27,0.93c0,0.38-0.11,0.72-0.33,1.02c-0.22,0.31-0.56,0.58-1.02,0.8l1.54,2.8
          h-1.38l-1.41-2.55h-0.62v2.55H295.47z M296.7,51.93h0.53c0.43,0,0.76-0.11,0.98-0.3c0.21-0.2,0.33-0.46,0.33-0.78
          c0-0.27-0.09-0.48-0.27-0.64c-0.16-0.16-0.5-0.24-1-0.24h-0.58V51.93z"
          />
          <path
            className="st2"
            fill="#FFFFFF"
            d="M124.95,71.14c3.7,0,6.69,3.22,6.69,7.15c0,3.92-2.99,7.15-6.69,7.15c-3.64,0-6.64-3.23-6.64-7.15
          C118.32,74.37,121.31,71.14,124.95,71.14z M200.81,71.14c3.7,0,6.69,3.22,6.69,7.15c0,3.92-2.99,7.15-6.69,7.15
          c-3.65,0-6.64-3.23-6.64-7.15C194.16,74.37,197.16,71.14,200.81,71.14z M272.86,48.08H212.5v59.02h15.33V61.5h16.27v45.59h15.32
          V61.5h9.06c6.51,0,7.21,5.44,7.21,7.79v37.81h15.32V66.25C291.02,57.32,284.23,48.08,272.86,48.08z M48.94,77.59
          c0-19.04,10.02-29.52,28.21-29.52h36.12V60.2H81.43c-9.7,0-14.63,5.85-14.63,17.38c0,11.53,4.93,17.38,14.63,17.38h15.48V83.42
          h-9.9V71.75h26.27v35.35H77.16C58.96,107.1,48.94,96.61,48.94,77.59z M174.2,48.06v38.97c0,3.88-4.58,8.07-11.41,8.07
          c-6.87,0-11.14-4.05-11.14-8.07V48.06h-14.96v39.8c0,12.56,10.96,20.66,26.11,20.66c14.9,0,26.37-8.31,26.37-20.66v-39.8H174.2z"
          />
        </g>
      </svg>
    </Box>
  );
};

export default GumLogo;
