import OrdersIcon from '@mui/icons-material/RequestQuote';
import LocalShipping from '@mui/icons-material/LocalShipping';
import OrdersList from './OrdersListDesktop';
// import OrdersEdit from './OrdersEdit';

export default {
    icon: LocalShipping,
    list: OrdersList,
    // edit: OrdersEdit,
};
