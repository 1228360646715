import { language_codes } from "../api";

const getTranslationJson = (lang: string) => {
  if (!language_codes.includes(lang)) {
    lang = "en";
  }
  const translationsJson: GQL_Prismic.Checkout_Translations = require("../data/translation_" +
    lang.toLowerCase() +
    ".json");
  return translationsJson;
};

export default getTranslationJson;
