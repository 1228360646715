import { processENV } from "../api";

const BASE_URL = (processENV.REACT_APP_KIDS_API_URL || "http://localhost:5000") + "/api/v2/auth/external";

const SSOAuthProvider = {
  login: () => {
    return Promise.reject();
  },

  checkAuth: () => {
    let cookie = localStorage.getItem("PB_Auth");
    if (!cookie) {
      return Promise.reject({ redirectTo: "/no-access" });
    }
    const { state } = JSON.parse(cookie);

    var requestOptions: any = {
      method: "GET",
      mode: "cors",
    };
    return fetch(`${BASE_URL}/check_expiry/${state}`, requestOptions)
      .then((response) => response.status)
      .then((statusCode) => {
        if (statusCode === 200) {
          return Promise.resolve();
        } else {
          localStorage.removeItem("PB_Auth");
          return Promise.reject({ redirectTo: "/no-access" });
        }
      });
  },
  checkError: (error: { status: any }) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("PB_Auth");
      return Promise.reject({ redirectTo: "/credentials-required" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  logout: () => {
    return Promise.resolve("/login");
  },
  getIdentity: () => {
    try {
      const { id, fullName } = JSON.parse(localStorage.getItem("PB_Auth") || "");
      return Promise.resolve({ id, fullName });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default SSOAuthProvider;
