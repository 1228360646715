import Typography from "@mui/material/Typography";
import {
  AutocompleteArrayInput,
  ChipField,
  Create,
  CreateButton,
  DatagridConfigurable,
  Edit,
  EditButton,
  FilterButton,
  Labeled,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  required,
  SearchInput,
  SelectColumnsButton,
  Show,
  ShowButton,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  WrapperField,
} from "react-admin";
import LocalisedDateField from "../components/localisedDateField";

export const InternalUserGroupCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ReferenceArrayInput source="locales" reference="locales">
          <AutocompleteArrayInput
            optionText={(option) => {
              return `${option.name}`;
            }}
            sx={{ width: "300px" }}
            validate={required()}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const userFilters = [<SearchInput source="name__icontains" alwaysOn={true} placeholder="Name" />];

export const InternalUserGroupList = (props: any) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={userFilters}
      title={"Locales"}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="name" />
        <ReferenceArrayField label="Locales" reference="locales" source="localeIds">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField source="userCount" />
        <WrapperField label="Actions">
          <ShowButton />
          <EditButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export const InternalUserGroupEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <ReferenceArrayInput source="localeIds" reference="locales">
          <AutocompleteArrayInput
            sx={{ minWidth: "300px" }}
            optionText={(record) => `${record.name}`}
            filterToQuery={(query) => {
              return { name__icontains: query };
            }}
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const InternalUserGroupShowAside = () => {
  const record = useRecordContext();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Metadata</Typography>
      <p>
        Created <LocalisedDateField record={record} field="createdAt" showTime={true} />
      </p>
      <p>
        Updated <LocalisedDateField record={record} field="updatedAt" showTime={true} />{" "}
      </p>
    </div>
  );
};

export const InternalUserGroupShow = () => {
  return (
    <Show aside={<InternalUserGroupShowAside />}>
      <SimpleForm>
        <Labeled>
          <TextField source="name" validate={required()} />
        </Labeled>
        <Labeled>
          <ReferenceArrayField label="Locales" reference="locales" source="localeIds">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Labeled>
      </SimpleForm>
    </Show>
  );
};
