import {
  ArrayField,
  Datagrid,
  EmailField,
  FunctionField,
  Labeled,
  Show,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";

import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import MonetizationOnTwoToneIcon from "@mui/icons-material/MonetizationOnTwoTone";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
import SportsEsportsTwoToneIcon from "@mui/icons-material/SportsEsportsTwoTone";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Chip, Grid } from "@mui/material";

import { stripe_link } from "../api";
import Currency from "../components/currency";
import LocalisedDateField from "../components/localisedDateField";

const UserDetailTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (
    <span>
      User - {record.firstName} {record.lastName}
    </span>
  );
};

const SummaryTabHeader = () => {
  return (
    <span style={{ display: "" }}>
      <InfoTwoToneIcon />{" "}
      <span style={{ verticalAlign: "super" }}>Summary</span>
    </span>
  );
};

const SubscriptionsTabHeader = () => {
  return (
    <span style={{ display: "" }}>
      <MonetizationOnTwoToneIcon />{" "}
      <span style={{ verticalAlign: "super" }}>Subscriptions</span>
    </span>
  );
};

const AppDeviceTabHeader = () => {
  return (
    <span style={{ display: "" }}>
      <PhoneIphoneTwoToneIcon />{" "}
      <span style={{ verticalAlign: "super" }}>App & Device</span>
    </span>
  );
};

const GamesTabHeader = () => {
  return (
    <span style={{ display: "" }}>
      <SportsEsportsTwoToneIcon />{" "}
      <span style={{ verticalAlign: "super" }}>Games</span>
    </span>
  );
};

const EmptySubscriptionList = () => {
  return (
    <small>
      <i>This user has no subscriptions</i>
    </small>
  );
};

const SubscriptionStoreIcon = ({ store }: any) => {
  switch (store) {
    case "GooglePlay":
      return <GoogleIcon titleAccess="Google Play" />;
    case "Apple App Store":
      return <AppleIcon titleAccess="Apple App Store" />;
    case "Stripe":
      return <StorefrontIcon titleAccess="Playbrush Webshop" />;
    default:
      return store;
  }
};

const UserDetail = () => {
  return (
    <Show title={<UserDetailTitle />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label={<SummaryTabHeader />}>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="First Name">
                <TextField source="firstName" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Last Name">
                <TextField source="lastName" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="Email">
                <EmailField source="email" />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Old Email">
                <EmailField source="oldEmail" emptyText="never changed" />
              </Labeled>
            </Grid>
          </Grid>
          <Grid container columnGap={4}>
            <Grid item>
              <Labeled label="Country">
                <TextField source="countryEmoji" sx={{ fontSize: "20px" }} />
              </Labeled>
            </Grid>
            <Grid item>
              <Labeled label="Language">
                <TextField source="language" />
              </Labeled>
            </Grid>
          </Grid>

          <FunctionField
            label={"Customer Since"}
            render={(user: { dateRegistrationStarted: string }) => (
              <LocalisedDateField
                record={user}
                field={"dateRegistrationStarted"}
                showTime={true}
              />
            )}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label={<SubscriptionsTabHeader />}
          path="subscriptions"
        >
          <ArrayField source="subscriptions">
            <Datagrid
              bulkActionButtons={false}
              empty={<EmptySubscriptionList />}
            >
              <TextField source="title" />
              <FunctionField
                label="Subscription ID"
                render={(subscription: { subscriptionId: any }) => {
                  return subscription.subscriptionId.startsWith("sub_") ? (
                    <>
                      {subscription.subscriptionId}
                      <a
                        href={`${stripe_link}subscriptions/${subscription.subscriptionId}`}
                        className="ex-link"
                        target="_blank"
                        title="View subscription on Stripe"
                      >
                        ↗
                      </a>
                    </>
                  ) : (
                    <>{subscription.subscriptionId}</>
                  );
                }}
              />
              <FunctionField
                label="Store"
                render={(subscription: { store: string }) => {
                  return <SubscriptionStoreIcon store={subscription.store} />;
                }}
              />
              <FunctionField
                label="Status"
                render={(sub: any) => {
                  return (
                    <Chip
                      label={sub.status}
                      color={sub.status === "ACTIVE" ? "success" : "warning"}
                      size="small"
                      variant="outlined"
                    />
                  );
                }}
              />
              <FunctionField
                label="Start"
                render={(sub: any) => {
                  return (
                    <LocalisedDateField
                      record={sub}
                      field="createdAt"
                      compact={true}
                    />
                  );
                }}
              />
              <FunctionField
                label="End"
                render={(sub: any) => {
                  return (
                    <LocalisedDateField
                      record={sub}
                      field="endDate"
                      compact={true}
                    />
                  );
                }}
              />
              <FunctionField
                label="Price"
                render={(sub: any) => {
                  return (
                    <Currency
                      price={sub.price}
                      currency={sub.currency || "eur"}
                    />
                  );
                }}
              />
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label={<AppDeviceTabHeader />} path="device">
          <FunctionField
            label="App Last Opened"
            render={(user: { lastOpenedApp: string }) => {
              return (
                <LocalisedDateField
                  record={user}
                  field={"lastOpenedApp"}
                  showTime={true}
                />
              );
            }}
          />
          <FunctionField
            label="Last Login"
            render={(user: { lastLoginAt: string }) => {
              return (
                <LocalisedDateField
                  record={user}
                  field={"lastLoginAt"}
                  showTime={true}
                />
              );
            }}
          />

          <TextField source="os" label="Operating System" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab path="games" label={<GamesTabHeader />}>
          <small>🚧This tab is under construction.🚧</small>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserDetail;
