import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { request, gql, ClientError, GraphQLClient } from "graphql-request";
import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
import moment from "moment";

export function useCoupon(): UseMutationResult<GQL.CouponCheck, ClientError, GQL.MutationCouponCheckArgs> {
  return useMutation(
    "couponCheck",
    async (dataMutation) => {
      const data = await graphQLClient.request(
        gql`
          mutation{
            couponCheck(couponCode: "${dataMutation.couponCode}", country: "${dataMutation.country}") {
              valid
              percentage
              value
            }
          }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data.couponCheck;
    },
    {
      onSuccess: (data: any) => {},
    }
  );
}
