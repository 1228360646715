import { Alert, Toolbar } from "@mui/material";
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import { useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  ChipField,
  Create,
  CreateButton,
  DatagridConfigurable,
  Edit,
  EditButton,
  ExportButton,
  FilterButton,
  ImageField,
  List,
  NumberInput,
  Pagination,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SelectColumnsButton,
  SelectInput,
  ShowButton,
  SimpleFormIterator,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  WrapperField,
  isEmpty,
  required,
  useRecordContext,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import ImageArrayInputWithPreview from "../components/ImageArrayInputWithPreview";
import { PricesTabHeader } from "../components/PricesTabHeader";
import ProductSelectionInputWithPreview from "../components/ProductSelectionInputWithPreview";
import { SummaryTabHeader } from "../components/SummaryTabHeader";
import { TranslationsTabHeader } from "../components/TranslationsTabHeader";
import LocalisedDateField from "../components/localisedDateField";
import dataProvider from "../providers/grapheneDataProvider2";
import { Locale } from "./locales";

const isFalsy = (value: any) => {
  if (value === undefined || value === null || value === "") {
    return true;
  } else if (typeof value === "object") {
    return isEmpty(value);
  }
  return false;
};

const isTruthy = (value: any) => {
  return !isFalsy(value);
};

const validateBundleEdit = (bundlePre: GQL.V2BundlepresSetInput) => {
  let errors: any = {};
  if (isTruthy(bundlePre)) {
    if (!bundlePre?.products?.length) {
      errors.products = "Required";
    }
    if (!bundlePre?.locales?.length) {
      errors.locales = "Required";
    }
    if (isTruthy(bundlePre.prices) && isTruthy(bundlePre?.prices?.stripePriceIdsExtended)) {
      Object.entries(bundlePre?.prices?.stripePriceIdsExtended).forEach(([currency, prices]: [any, any]) => {
        prices.forEach((price: any) => {
          if (price["type"] == "recurring") {
            if (
              isFalsy(price["recurringInterval"]) ||
              isFalsy(price["recurringIntervalCount"]) ||
              isFalsy(price["recurringTrialPeriodDays"])
            ) {
              let error = {
                [currency]: `Recurring values are missing`,
              };
              errors.prices = {
                stripePriceIdsExtended: error,
              };
            }
          }
        });
      });
    }
  }
  return errors;
};

const BundleTranslationsForm = () => {
  const record = useRecordContext();
  const localeIds = useWatch({ name: "locales" });
  const [translationLocaleKeys, setTranslationLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let t_keys: string[] = ["en-GB"];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedLanguages.forEach((lang) => t_keys.push(`${lang}-${locale.country}`));
        });
        setTranslationLocaleKeys([...new Set(t_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!translationLocaleKeys) {
    return <p>Loading...</p>;
  }
  return (
    <>
      <Alert severity="info">"en-GB" fields are required</Alert>
      <TranslatableInputs
        locales={translationLocaleKeys}
        defaultLocale={"en-GB"}
        sx={{ minWidth: "600px" }}
        groupKey="translations"
        fullWidth
      >
        <RichTextInput
          fullWidth
          source="translations.description"
          label="Description"
          toolbar={<RichTextInputToolbar size="small" />}
        />
        <TextInput source="translations.nameOrder" label="Order name" helperText="Name used in order details" />
        <TextInput
          source="translations.nameMarketing"
          label="Marketing name"
          helperText="Name used in marketing (emails etc.)"
        />
        <ImageArrayInputWithPreview source="translations.images" />
        <ArrayInput source="translations.labels" label="Labels">
          <SimpleFormIterator inline>
            <TextInput source="text" />
            <TextInput source="colour" />
          </SimpleFormIterator>
        </ArrayInput>
      </TranslatableInputs>
    </>
  );
};

interface ObjectLiteral {
  [key: string]: any;
}

const BundlePricesForm = () => {
  const { setValue, getValues } = useFormContext();
  const products = useWatch({ name: "products" });
  const localeIds = useWatch({ name: "locales" });
  const [priceLocaleKeys, setPriceLocaleKeys] = useState<string[] | null>(null);

  useEffect(() => {
    if (localeIds) {
      let p_keys: string[] = [];
      dataProvider.getMany("locales", { id__in: localeIds }).then(({ data }) => {
        data.forEach((locale: Locale) => {
          locale?.supportedCurrencies.forEach((curr) => p_keys.push(`${curr}-${locale.country}`));
        });
        setPriceLocaleKeys([...new Set(p_keys)]);
      });
    }
  }, [localeIds]);

  if (!localeIds || localeIds.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select locales
      </Alert>
    );
  }

  if (!priceLocaleKeys) {
    return <p>Loading...</p>;
  }

  if (!products || products.length === 0) {
    return (
      <Alert severity="warning" sx={{ width: "100%" }}>
        Please select products
      </Alert>
    );
  }

  return (
    <>
      <TranslatableInputs locales={priceLocaleKeys} defaultLocale={priceLocaleKeys[0]} groupKey="prices" fullWidth>
        <ArrayInput label="Item Prices" source="prices.stripePriceIdsExtended">
          <SimpleFormIterator
            getItemLabel={(index) => `${index + 1}.`}
            inline
            fullWidth
            disableAdd
            disableRemove
            disableClear
            disableReordering
          >
            <ReferenceField source="productId" reference="v2Products">
              <ImageField source="image" />
            </ReferenceField>
            <ReferenceField source="productId" reference="v2Products" sx={{ width: "300px" }}>
              <>
                <TextField source="name" />
                <br />
                <TextField source="productId" />
              </>
            </ReferenceField>
            <NumberInput label="Price" source="unitAmount" step={1} validate={required()} sx={{ width: "100px" }} />
            <SelectInput
              source="type"
              choices={[
                { id: "one_time", name: "One Time" },
                { id: "recurring", name: "Recurring" },
              ]}
              emptyText=""
              emptyValue=""
              validate={required()}
              defaultValue="one_time"
            />
            <SelectInput
              source="recurringInterval"
              choices={[
                { id: "month", name: "Month" },
                { id: "year", name: "Year" },
              ]}
              emptyText=""
              emptyValue=""
              defaultValue=""
              sx={{ width: "150px" }}
            />
            <NumberInput
              label="Recurring Interval Count"
              source="recurringIntervalCount"
              defaultValue={0}
              sx={{ width: "150px" }}
            />
            <NumberInput
              label="Recurring Trial Period Days"
              source="recurringTrialPeriodDays"
              defaultValue={0}
              sx={{ width: "200px" }}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </TranslatableInputs>
    </>
  );
};

export const NewBundleCreate = () => {
  return (
    <Create>
      <TabbedForm syncWithLocation={false} validate={validateBundleEdit}>
        <TabbedForm.Tab label={<SummaryTabHeader />}>
          <TextInput source="name" fullWidth />
          <TextInput source="image" label="Image" fullWidth />
          <ReferenceArrayInput source="locales" reference="locales" perPage={100}>
            <AutocompleteArrayInput
              validate={required()}
              label="Locales"
              optionText={(record) => `${record.name}`}
              sx={{ minWidth: "100%" }}
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="products" reference="v2Products" perPage={100}>
            <AutocompleteArrayInput
              validate={required()}
              label="Products"
              optionText={(record) => `${record.name}`}
              filterToQuery={filterToQueryTags}
              sx={{ minWidth: "100%" }}
            />
          </ReferenceArrayInput>
          <ReferenceArrayInput source="bundleTags" reference="productTags" perPage={100}>
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <NumberInput label="Index" source="index" />
          <BooleanInput label="Is crossed price visible?" source="isCrossedPriceVisible" />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<TranslationsTabHeader />}>
          <BundleTranslationsForm />
        </TabbedForm.Tab>
        {/*<TabbedForm.Tab label={<PricesTabHeader />}>
          <BundlePricesForm />
        </TabbedForm.Tab>*/}
      </TabbedForm>
    </Create>
  );
};

const ReadOnlyToolbar = () => {
  const record = useRecordContext();
  console.log("record is ", record);
  return (
    <Toolbar>
      <Alert severity="info" sx={{ width: "100%" }}>
        This Bundle was published <LocalisedDateField record={record} field="modifiedAt" /> <br />
        <a href={`/v2Bundlepres/${record.id}`}>Click here</a> to edit the corresponding BundlePre
      </Alert>
    </Toolbar>
  );
};

export const NewBundleEdit = (props: any) => {
  return (
    <Edit mutationMode="pessimistic">
      <TabbedForm
        syncWithLocation={false}
        validate={validateBundleEdit}
        toolbar={props.hasSave ? undefined : <ReadOnlyToolbar />}
      >
        <TabbedForm.Tab label={<SummaryTabHeader />}>
          <TextInput source="name" fullWidth />
          <TextInput source="image" label="Image" fullWidth />
          <ReferenceArrayInput source="locales" reference="locales">
            <AutocompleteArrayInput validate={required()} label="Locales" optionText={(record) => `${record.name}`} />
          </ReferenceArrayInput>
          <ArrayInput fullWidth label="Products" source="products" helperText="Products in this bundle">
            <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`} inline>
              <ProductSelectionInputWithPreview source="" />
            </SimpleFormIterator>
          </ArrayInput>

          <ReferenceArrayInput source="bundleTags" reference="productTags" perPage={100}>
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <NumberInput label="Index" source="index" />
          <BooleanInput label="Is crossed price visible" source="isCrossedPriceVisible" />
        </TabbedForm.Tab>
        <TabbedForm.Tab label={<TranslationsTabHeader />}>
          <BundleTranslationsForm />
        </TabbedForm.Tab>

        <TabbedForm.Tab label={<PricesTabHeader />}>
          <BundlePricesForm />
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};

const MyActions = ({ showCreate = false }) => {
  return (
    <TopToolbar>
      {showCreate ? <CreateButton /> : ""}
      <FilterButton />
      <SelectColumnsButton />
      <ExportButton />
    </TopToolbar>
  );
};

const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;
const filterToQueryTags = (searchText: string) => ({
  name__icontains: `${searchText}`,
});
const bundleFilters = [
  <TextInput label="ID" source="pk" alwaysOn />,
  <TextInput label="Name" source="name__icontains" alwaysOn />,
  <ReferenceArrayInput label="Tags in" source="tags__all" reference="productTags" alwaysOn perPage={100}>
    <AutocompleteArrayInput label="Tags in" filterToQuery={filterToQueryTags} />
  </ReferenceArrayInput>,
  <ReferenceArrayInput label="Tags not in" source="tags__nin" reference="productTags" alwaysOn perPage={100}>
    <AutocompleteArrayInput label="Tags not in" filterToQuery={filterToQueryTags} />
  </ReferenceArrayInput>,
];

export const NewBundleList = (props: any) => (
  <List filters={bundleFilters} actions={<MyActions {...props} />} pagination={<MyPagination />}>
    <DatagridConfigurable
      omit={["nameOrderEn", "nameMarketingEn", "nameOrderDe", "nameMarketingDe", "isCrossedPriceVisible"]}
    >
      <TextField source="bundleId" />

      <ImageField sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }} source="image" />
      <TextField source="name" />

      <ReferenceArrayField label="Bundle Tags" reference="productTags" source="bundleTags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>

      <TextField lable="Index" source="index" />
      <WrapperField label="Actions">{props.showCreate ? <EditButton /> : <ShowButton />}</WrapperField>
    </DatagridConfigurable>
  </List>
);
