import * as React from "react";
import { BasketData, BasketItem } from "./basketData";

const BasketContext = React.createContext<BasketData>({
  items: [],
  onAllItemsDeleted: () => {},
  onItemAdded: (item: BasketItem) => {},
  onItemDeleted: (id: string) => {},
  setInitialData: (basketItem: BasketItem[]) => [],
});

const withBasketData =
  <T extends {}>(Wrap: any) =>
  (props: T) => {
    return (
      <BasketContext.Consumer>
        {(basketData: BasketData) => <Wrap basketData={basketData} {...props} />}
      </BasketContext.Consumer>
    );
  };

function useBasket() {
  const basket = React.useContext(BasketContext);
  return basket;
}

export { BasketContext, withBasketData, useBasket };
