import green from "@mui/material/colors/green";
import type { SxProps } from "@mui/material";
import orange from "@mui/material/colors/orange";
import lightBlue from "@mui/material/colors/lightBlue";
import red from "@mui/material/colors/red";
import grey from "@mui/material/colors/grey";
import { Identifier } from "react-admin";

export type ShippingStatus =
  | "ordered"
  | "process_manually"
  // | "sent_to_warewhouse"
  // | "tracking_email_sent"
  | "complete"
  | "cancelled";

const rowSx =
  (selectedRow?: Identifier) =>
  (record: GQL.Orders): SxProps => {
    let style = {};
    if (!record) {
      return style;
    }
    if (selectedRow && selectedRow === record.id) {
      style = {
        ...style,
        backgroundColor: "action.selected",
      };
    }
    if (record.shippingState === "ordered")
      return {
        ...style,
        borderLeftColor: lightBlue[300],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.shippingState === "process_manually")
      return {
        ...style,
        borderLeftColor: orange[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.shippingState === "sent_to_warewhouse")
      return {
        ...style,
        borderLeftColor: green[300],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.shippingState === "tracking_email_sent")
      return {
        ...style,
        borderLeftColor: green[400],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.shippingState === "complete")
      return {
        ...style,
        borderLeftColor: green[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.shippingState === "cancelled")
      return {
        ...style,
        borderLeftColor: grey[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    return style;
  };

export default rowSx;
