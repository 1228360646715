import React from "react";
import {
  ChipField,
  Datagrid,
  EditButton,
  EmailField,
  List,
  ReferenceArrayField,
  SelectField,
  SingleFieldList,
  TextField,
} from "react-admin";

export const ProductTagsList = () => (
  <List>
    <Datagrid>
      <TextField source="tagId" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
