import React from "react";
// import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import RedditTextField from "./redditTextfield";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

const CheckBoxCheckedIcon = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "transparent",
          width: "18px",
          height: "18px",
          border: "2px solid #2bb573",
          borderRadius: "3px",
          position: "absolute",
          left: "12.5%",
          right: "12.5%",
          top: "12.5%",
          bottom: "12.5%",
        }}
      ></Box>
      <CheckBoxOutlineBlankIcon sx={{ color: "transparent" }} />
      <Box
        sx={{
          backgroundColor: "#2bb573",
          borderRadius: "2px",
          width: "10px",
          height: "10px",
          position: "absolute",
          left: "29.17%",
          right: "29.17%",
          top: "29.17%",
          bottom: "29.17%",
        }}
      ></Box>
    </Box>
  );
};

export const CheckBoxUnCheckedIcon = ({ colorBorder = "#8193A8" }: { colorBorder?: string }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "transparent",
          width: "18px",
          height: "18px",
          border: `2px solid ${colorBorder}`,
          borderRadius: "3px",
          position: "absolute",
          left: "12.5%",
          right: "12.5%",
          top: "12.5%",
          bottom: "12.5%",
        }}
      ></Box>
      <CheckBoxOutlineBlankIcon sx={{ color: "transparent" }} />
    </Box>
  );
};
export default CheckBoxCheckedIcon;
