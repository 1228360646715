import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { useStateMachine } from "little-state-machine";
import { any } from "prop-types";
import { processENV } from "../api";
import StripeExpressCheckout from "./stripeExpressCheckout";
import { CheckoutContext } from "../context/checkoutContext";

const stripePromise = loadStripe(processENV.REACT_APP_STRIPE_CLIENT_SECRET || "", {
  apiVersion: processENV.REACT_APP_STRIPE_API_VERSION,
});

const StripeExpressCheckoutElement = () => {
  let cartData = useContext(CheckoutContext);
  if (cartData == null) {
    return;
  }
  const optionsCard: StripeElementsOptions = {
    paymentMethodTypes: ["card", "link"],
    // paymentMethodConfiguration:{ "paypal"{"available":true, "display_preference":{"overridable": null,
    // "preference": "on",
    // "value": "on"}}},
    mode: (cartData!.amountTotal || 0) > 0 ? "payment" : "setup",
    amount: cartData!.amountTotal || 0,
    currency: cartData!.currency?.toLowerCase() || "eur",
    setupFutureUsage: cartData?.hasSubscription || false ? "off_session" : null,
    // Customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const optionsPaypal: StripeElementsOptions = {
    paymentMethodTypes: ["paypal"],
    // paymentMethodConfiguration:{ "paypal"{"available":true, "display_preference":{"overridable": null,
    // "preference": "on",
    // "value": "on"}}},
    mode: (cartData!.amountTotal || 0) > 0 ? "payment" : "setup",
    amount: cartData!.amountTotal || 0,
    currency: cartData!.currency?.toLowerCase() || "eur",
    // setupFutureUsage: cartData?.hasSubscription || false ? "off_session" : null,
    // setupFutureUsage: cartData?.hasSubscription || false ? "off_session" : null,
    // Customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <>
      <Elements stripe={stripePromise} options={optionsPaypal}>
        <StripeExpressCheckout />
      </Elements>
      <Elements stripe={stripePromise} options={optionsCard}>
        <StripeExpressCheckout />
      </Elements>
    </>
  );
};

export default StripeExpressCheckoutElement;
