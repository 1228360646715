import { List, Datagrid, TextField, UrlField } from "react-admin";

import grapheneDataProvider from "../providers/grapheneDataProvider2";

const InvoiceArchiveList = (props: any) => (
  <List {...props} dataProvider={grapheneDataProvider}>
    <Datagrid>
      <TextField source="year" />
      <TextField source="id" label="Name" />
      <UrlField source="downloadLink" />
    </Datagrid>
  </List>
);

export default InvoiceArchiveList;
