import { AutocompleteArrayInput, ReferenceArrayInput, required } from "react-admin";

export const LocaleReferenceArrayInput = () => {
  return (
    <ReferenceArrayInput source="locales" reference="locales" perPage={100}>
      <AutocompleteArrayInput
        validate={required()}
        label="Locales"
        optionText={(record) => `${record.name} - ${record.countryEmoji}`}
        sx={{ minWidth: "100%" }}
      />
    </ReferenceArrayInput>
  );
};
