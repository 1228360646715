import { Divider, Tab, Tabs } from "@mui/material";
import { useCallback } from "react";
import {
  Count,
  DatagridConfigurable,
  ExportButton,
  FilterButton,
  FunctionField,
  Identifier,
  List,
  SelectColumnsButton,
  ShowButton,
  TextField,
  TopToolbar,
  WrapperField,
  useListContext,
} from "react-admin";
import { Fragment } from "react/jsx-runtime";
import { braintree_link, stripe_link } from "../../api";
import Currency from "../../components/currency";
import LocalisedDateField from "../../components/localisedDateField";
import { exporterXlsx } from "../../exporter/orderXlsx";
import { MyBulkActionButtons, orderFilters } from "../order";
import rowSx from "./rowSx";

export interface OrderListDesktopProps {
  selectedRow?: Identifier;
}
const MyActions = () => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton />
    {/* <ExportButton maxResults={1000000} /> */}
    {/* <ExportButton label="CSV Shipping" exporter={exporterShipping} maxResults={1000000} /> */}
    <ExportButton label="XLSX Orders" exporter={exporterXlsx} maxResults={1000000} />
    {/* <ExportButton label="Export Discount" exporter={exporterDiscount} maxResults={1000000} /> */}
  </TopToolbar>
);

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();

export const OrderList = ({ selectedRow }: OrderListDesktopProps) => (
  <List
    sort={{ field: "createdAt", order: "DESC" }}
    filters={orderFilters}
    filterDefaultValues={{ created_at__gte: firstDay }}
    // exporter={exporter}
    actions={<MyActions />}
  >
    <TabbedDatagrid />
  </List>
);

const tabs = [
  { id: "ordered", name: "ordered" },
  { id: "process_manually", name: "process_manually" },
  { id: "complete", name: "complete" },
  { id: "cancelled", name: "cancelled" },
];

const TabbedDatagrid = () => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters } = listContext;
  // const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          { ...filterValues, shippingState: value },
          displayedFilters,
          false // no debounce, we want the filter to fire immediately
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.shippingState || "ordered"}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              <span>
                {choice.name}
                (
                <Count
                  filter={{
                    ...filterValues,
                    shippingState: choice.name,
                  }}
                  sx={{ lineHeight: "inherit" }}
                />
                )
              </span>
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />

      <>
        {filterValues.shippingState === "ordered" && (
          <DatagridConfigurable
            omit={["address", "shippingName", "couponcode"]}
            rowSx={rowSx()}
            bulkActionButtons={<MyBulkActionButtons />}
          >
            {/* rowClick="edit" */}
            <FunctionField
              label="Date"
              render={(record: any) => {
                return <LocalisedDateField record={record} field="createdAt" compact showTime />;
              }}
            />
            {/* <DateField label="Date" source="modifiedAt" showTime /> */}
            <TextField label="Order Id" source="id" />
            <TextField label="Type" source="orderType" />
            <TextField label="Payment" source="paymentType" />
            <TextField label="Coupon Code" source="couponcode" />
            <TextField label="Country" source="country" />
            {/* <UrlField source="invoiceLink" /> */}
            <FunctionField
              label="Invoice"
              render={(record: GQL.Orders) =>
                (record.stripeInvoiceIds !== null &&
                  record.stripeInvoiceIds !== undefined &&
                  record.stripeInvoiceIds.length > 1 &&
                  record.stripeInvoiceIds.map((stripeInvoiceId, i) => (
                    <a
                      key={stripeInvoiceId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "invoices/" + stripeInvoiceId}
                    >
                      Invoice_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeInvoiceId !== null && record.stripeInvoiceId && (
                  <a target="_blank" rel="noreferrer" href={stripe_link + "invoices/" + record.stripeInvoiceId}>
                    Invoice
                  </a>
                ))
              }
            />
            <FunctionField
              label="Subscription"
              render={(record: GQL.Orders) =>
                (record.stripeSubscriptionIds !== null &&
                  record.stripeSubscriptionIds !== undefined &&
                  record.stripeSubscriptionIds.length > 1 &&
                  record.stripeSubscriptionIds.map((stripeSubscriptionId, i) => (
                    <a
                      key={stripeSubscriptionId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "subscriptions/" + stripeSubscriptionId}
                    >
                      Subscription_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeSubscriptionId !== null && record.stripeSubscriptionId && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={stripe_link + "subscriptions/" + record.stripeSubscriptionId}
                  >
                    Subscription
                  </a>
                ))
              }
            />
            <FunctionField
              label="Payment Id"
              render={(record: GQL.Orders) =>
                record.paymentType !== null && record.paymentType === "card"
                  ? record.stripeSetupIntent
                    ? record.stripeSetupIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "setup_intents/" + record.stripeSetupIntent}
                        >
                          SetupIntent
                        </a>
                      )
                    : record.stripePaymentIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "payments/" + record.stripePaymentIntent}
                        >
                          PaymentIntent
                        </a>
                      )
                  : record.braintreePaypalTransactionId && (
                      <a target="_blank" rel="noreferrer" href={braintree_link + record.braintreePaypalTransactionId}>
                        Braintree Transaction
                      </a>
                    )
              }
            />
            <TextField label="Name" source="shippingName" />
            <TextField label="Email" source="email" />
            <FunctionField
              textAlign="left"
              label="Address"
              source="address"
              render={(record: GQL.Orders) => {
                return [
                  record.shippingAddressPostalCode,
                  record.shippingAddressCity,
                  record.shippingAddressLine1,
                  record.shippingAddressLine2,
                  record.shippingAddressCountry,
                ].join(" ");
              }}
            />
            <FunctionField
              textAlign="left"
              label="Nb Items"
              render={(record: GQL.Orders) => {
                return record.orderItems?.length;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Total"
              render={(record: { amountTotal: number; currency: string }) => {
                return <Currency price={record.amountTotal} currency={record.currency} />;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Shipping Items Count"
              render={(record: GQL.Orders) => {
                let countOutput = "";
                if (record.shippingItemsBrushCount !== undefined) {
                  countOutput += "B:" + record.shippingItemsBrushCount;
                }
                if (record.shippingItemsBrushHeadCount !== undefined) {
                  countOutput += " BH:" + record.shippingItemsBrushHeadCount;
                }
                if (record.shippingItemsGamesSubCount !== undefined) {
                  countOutput += " G:" + record.shippingItemsGamesSubCount;
                }
                if (record.shippingItemsToothPasteCount !== undefined) {
                  countOutput += " T:" + record.shippingItemsToothPasteCount;
                }
                return countOutput;
              }}
            />
            <TextField label="Shipping State" source="shippingState" />
            <TextField label="Shipping Courier" source="shippingCourierService" />
            {/* <TextField label="Product Id" source="product.productId" />
          <ImageField
            label="Product Image"
            sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }}
            source="product.image"
          />
          <TextField label="Product Name" source="product.name" />
          */}
            {/* <EditButton /> */}
            <WrapperField label="Actions">
              <ShowButton resource="orders" />
            </WrapperField>
          </DatagridConfigurable>
        )}
        {filterValues.shippingState === "process_manually" && (
          <DatagridConfigurable
            omit={["address", "shippingName", "couponcode"]}
            rowSx={rowSx()}
            bulkActionButtons={<MyBulkActionButtons />}
          >
            {/* rowClick="edit" */}
            <FunctionField
              label="Date"
              render={(record: any) => {
                return <LocalisedDateField record={record} field="createdAt" compact showTime />;
              }}
            />
            {/* <DateField label="Date" source="modifiedAt" showTime /> */}
            <TextField label="Order Id" source="id" />
            <TextField label="Type" source="orderType" />
            <TextField label="Payment" source="paymentType" />
            <TextField label="Coupon Code" source="couponcode" />
            <TextField label="Country" source="country" />
            {/* <UrlField source="invoiceLink" /> */}
            <FunctionField
              label="Invoice"
              render={(record: GQL.Orders) =>
                (record.stripeInvoiceIds !== null &&
                  record.stripeInvoiceIds !== undefined &&
                  record.stripeInvoiceIds.length > 1 &&
                  record.stripeInvoiceIds.map((stripeInvoiceId, i) => (
                    <a
                      key={stripeInvoiceId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "invoices/" + stripeInvoiceId}
                    >
                      Invoice_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeInvoiceId !== null && record.stripeInvoiceId && (
                  <a target="_blank" rel="noreferrer" href={stripe_link + "invoices/" + record.stripeInvoiceId}>
                    Invoice
                  </a>
                ))
              }
            />
            <FunctionField
              label="Subscription"
              render={(record: GQL.Orders) =>
                (record.stripeSubscriptionIds !== null &&
                  record.stripeSubscriptionIds !== undefined &&
                  record.stripeSubscriptionIds.length > 1 &&
                  record.stripeSubscriptionIds.map((stripeSubscriptionId, i) => (
                    <a
                      key={stripeSubscriptionId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "subscriptions/" + stripeSubscriptionId}
                    >
                      Subscription_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeSubscriptionId !== null && record.stripeSubscriptionId && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={stripe_link + "subscriptions/" + record.stripeSubscriptionId}
                  >
                    Subscription
                  </a>
                ))
              }
            />
            <FunctionField
              label="Payment Id"
              render={(record: GQL.Orders) =>
                record.paymentType !== null && record.paymentType === "card"
                  ? record.stripeSetupIntent
                    ? record.stripeSetupIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "setup_intents/" + record.stripeSetupIntent}
                        >
                          SetupIntent
                        </a>
                      )
                    : record.stripePaymentIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "payments/" + record.stripePaymentIntent}
                        >
                          PaymentIntent
                        </a>
                      )
                  : record.braintreePaypalTransactionId && (
                      <a target="_blank" rel="noreferrer" href={braintree_link + record.braintreePaypalTransactionId}>
                        Braintree Transaction
                      </a>
                    )
              }
            />
            <TextField label="Name" source="shippingName" />
            <TextField label="Email" source="email" />
            <FunctionField
              textAlign="left"
              label="Address"
              source="address"
              render={(record: GQL.Orders) => {
                return [
                  record.shippingAddressPostalCode,
                  record.shippingAddressCity,
                  record.shippingAddressLine1,
                  record.shippingAddressLine2,
                  record.shippingAddressCountry,
                ].join(" ");
              }}
            />
            <FunctionField
              textAlign="left"
              label="Nb Items"
              render={(record: GQL.Orders) => {
                return record.orderItems?.length;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Total"
              render={(record: { amountTotal: number; currency: string }) => {
                return <Currency price={record.amountTotal} currency={record.currency} />;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Shipping Items Count"
              render={(record: GQL.Orders) => {
                let countOutput = "";
                if (record.shippingItemsBrushCount !== undefined) {
                  countOutput += "B:" + record.shippingItemsBrushCount;
                }
                if (record.shippingItemsBrushHeadCount !== undefined) {
                  countOutput += " BH:" + record.shippingItemsBrushHeadCount;
                }
                if (record.shippingItemsGamesSubCount !== undefined) {
                  countOutput += " G:" + record.shippingItemsGamesSubCount;
                }
                if (record.shippingItemsToothPasteCount !== undefined) {
                  countOutput += " T:" + record.shippingItemsToothPasteCount;
                }
                return countOutput;
              }}
            />
            <TextField label="Shipping State" source="shippingState" />
            <TextField label="Shipping Courier" source="shippingCourierService" />
            {/* <TextField label="Product Id" source="product.productId" />
          <ImageField
            label="Product Image"
            sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }}
            source="product.image"
          />
          <TextField label="Product Name" source="product.name" />
          */}
            {/* <EditButton /> */}
            <WrapperField label="Actions">
              <ShowButton resource="orders" />
            </WrapperField>
          </DatagridConfigurable>
        )}
        {filterValues.shippingState === "complete" && (
          <DatagridConfigurable omit={["address", "shippingName", "couponcode"]} rowSx={rowSx()}>
            {/* rowClick="edit" */}
            <FunctionField
              label="Date"
              render={(record: any) => {
                return <LocalisedDateField record={record} field="createdAt" compact showTime />;
              }}
            />
            {/* <DateField label="Date" source="modifiedAt" showTime /> */}
            <TextField label="Order Id" source="id" />
            <TextField label="Type" source="orderType" />
            <TextField label="Payment" source="paymentType" />
            <TextField label="Coupon Code" source="couponcode" />
            <TextField label="Country" source="country" />
            {/* <UrlField source="invoiceLink" /> */}
            <FunctionField
              label="Invoice"
              render={(record: GQL.Orders) =>
                (record.stripeInvoiceIds !== null &&
                  record.stripeInvoiceIds !== undefined &&
                  record.stripeInvoiceIds.length > 1 &&
                  record.stripeInvoiceIds.map((stripeInvoiceId, i) => (
                    <a
                      key={stripeInvoiceId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "invoices/" + stripeInvoiceId}
                    >
                      Invoice_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeInvoiceId !== null && record.stripeInvoiceId && (
                  <a target="_blank" rel="noreferrer" href={stripe_link + "invoices/" + record.stripeInvoiceId}>
                    Invoice
                  </a>
                ))
              }
            />
            <FunctionField
              label="Subscription"
              render={(record: GQL.Orders) =>
                (record.stripeSubscriptionIds !== null &&
                  record.stripeSubscriptionIds !== undefined &&
                  record.stripeSubscriptionIds.length > 1 &&
                  record.stripeSubscriptionIds.map((stripeSubscriptionId, i) => (
                    <a
                      key={stripeSubscriptionId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "subscriptions/" + stripeSubscriptionId}
                    >
                      Subscription_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeSubscriptionId !== null && record.stripeSubscriptionId && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={stripe_link + "subscriptions/" + record.stripeSubscriptionId}
                  >
                    Subscription
                  </a>
                ))
              }
            />
            <FunctionField
              label="Payment Id"
              render={(record: GQL.Orders) =>
                record.paymentType !== null && record.paymentType === "card"
                  ? record.stripeSetupIntent
                    ? record.stripeSetupIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "setup_intents/" + record.stripeSetupIntent}
                        >
                          SetupIntent
                        </a>
                      )
                    : record.stripePaymentIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "payments/" + record.stripePaymentIntent}
                        >
                          PaymentIntent
                        </a>
                      )
                  : record.braintreePaypalTransactionId && (
                      <a target="_blank" rel="noreferrer" href={braintree_link + record.braintreePaypalTransactionId}>
                        Braintree Transaction
                      </a>
                    )
              }
            />
            <TextField label="Name" source="shippingName" />
            <TextField label="Email" source="email" />
            <FunctionField
              textAlign="left"
              label="Address"
              source="address"
              render={(record: GQL.Orders) => {
                return [
                  record.shippingAddressPostalCode,
                  record.shippingAddressCity,
                  record.shippingAddressLine1,
                  record.shippingAddressLine2,
                  record.shippingAddressCountry,
                ].join(" ");
              }}
            />
            <FunctionField
              textAlign="left"
              label="Nb Items"
              render={(record: GQL.Orders) => {
                return record.orderItems?.length;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Total"
              render={(record: { amountTotal: number; currency: string }) => {
                return <Currency price={record.amountTotal} currency={record.currency} />;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Shipping Items Count"
              render={(record: GQL.Orders) => {
                let countOutput = "";
                if (record.shippingItemsBrushCount !== undefined) {
                  countOutput += "B:" + record.shippingItemsBrushCount;
                }
                if (record.shippingItemsBrushHeadCount !== undefined) {
                  countOutput += " BH:" + record.shippingItemsBrushHeadCount;
                }
                if (record.shippingItemsGamesSubCount !== undefined) {
                  countOutput += " G:" + record.shippingItemsGamesSubCount;
                }
                if (record.shippingItemsToothPasteCount !== undefined) {
                  countOutput += " T:" + record.shippingItemsToothPasteCount;
                }
                return countOutput;
              }}
            />
            <TextField label="Shipping State" source="shippingState" />
            <TextField label="Shipping Courier" source="shippingCourierService" />
            {/* <TextField label="Product Id" source="product.productId" />
          <ImageField
            label="Product Image"
            sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }}
            source="product.image"
          />
          <TextField label="Product Name" source="product.name" />
          */}
            {/* <EditButton /> */}
            <WrapperField label="Actions">
              <ShowButton resource="orders" />
            </WrapperField>
          </DatagridConfigurable>
        )}
        {filterValues.shippingState === "cancelled" && (
          <DatagridConfigurable omit={["address", "shippingName", "couponcode"]} rowSx={rowSx()}>
            {/* rowClick="edit" */}
            <FunctionField
              label="Date"
              render={(record: any) => {
                return <LocalisedDateField record={record} field="createdAt" compact showTime />;
              }}
            />
            {/* <DateField label="Date" source="modifiedAt" showTime /> */}
            <TextField label="Order Id" source="id" />
            <TextField label="Type" source="orderType" />
            <TextField label="Payment" source="paymentType" />
            <TextField label="Coupon Code" source="couponcode" />
            <TextField label="Country" source="country" />
            {/* <UrlField source="invoiceLink" /> */}
            <FunctionField
              label="Invoice"
              render={(record: GQL.Orders) =>
                (record.stripeInvoiceIds !== null &&
                  record.stripeInvoiceIds !== undefined &&
                  record.stripeInvoiceIds.length > 1 &&
                  record.stripeInvoiceIds.map((stripeInvoiceId, i) => (
                    <a
                      key={stripeInvoiceId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "invoices/" + stripeInvoiceId}
                    >
                      Invoice_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeInvoiceId !== null && record.stripeInvoiceId && (
                  <a target="_blank" rel="noreferrer" href={stripe_link + "invoices/" + record.stripeInvoiceId}>
                    Invoice
                  </a>
                ))
              }
            />
            <FunctionField
              label="Subscription"
              render={(record: GQL.Orders) =>
                (record.stripeSubscriptionIds !== null &&
                  record.stripeSubscriptionIds !== undefined &&
                  record.stripeSubscriptionIds.length > 1 &&
                  record.stripeSubscriptionIds.map((stripeSubscriptionId, i) => (
                    <a
                      key={stripeSubscriptionId}
                      target="_blank"
                      rel="noreferrer"
                      href={stripe_link + "subscriptions/" + stripeSubscriptionId}
                    >
                      Subscription_{i + 1}
                      <br />
                    </a>
                  ))) ||
                (record.stripeSubscriptionId !== null && record.stripeSubscriptionId && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={stripe_link + "subscriptions/" + record.stripeSubscriptionId}
                  >
                    Subscription
                  </a>
                ))
              }
            />
            <FunctionField
              label="Payment Id"
              render={(record: GQL.Orders) =>
                record.paymentType !== null && record.paymentType === "card"
                  ? record.stripeSetupIntent
                    ? record.stripeSetupIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "setup_intents/" + record.stripeSetupIntent}
                        >
                          SetupIntent
                        </a>
                      )
                    : record.stripePaymentIntent && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={stripe_link + "payments/" + record.stripePaymentIntent}
                        >
                          PaymentIntent
                        </a>
                      )
                  : record.braintreePaypalTransactionId && (
                      <a target="_blank" rel="noreferrer" href={braintree_link + record.braintreePaypalTransactionId}>
                        Braintree Transaction
                      </a>
                    )
              }
            />
            <TextField label="Name" source="shippingName" />
            <TextField label="Email" source="email" />
            <FunctionField
              textAlign="left"
              label="Address"
              source="address"
              render={(record: GQL.Orders) => {
                return [
                  record.shippingAddressPostalCode,
                  record.shippingAddressCity,
                  record.shippingAddressLine1,
                  record.shippingAddressLine2,
                  record.shippingAddressCountry,
                ].join(" ");
              }}
            />
            <FunctionField
              textAlign="left"
              label="Nb Items"
              render={(record: GQL.Orders) => {
                return record.orderItems?.length;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Total"
              render={(record: { amountTotal: number; currency: string }) => {
                return <Currency price={record.amountTotal} currency={record.currency} />;
              }}
            />
            <FunctionField
              textAlign="right"
              label="Shipping Items Count"
              render={(record: GQL.Orders) => {
                let countOutput = "";
                if (record.shippingItemsBrushCount !== undefined) {
                  countOutput += "B:" + record.shippingItemsBrushCount;
                }
                if (record.shippingItemsBrushHeadCount !== undefined) {
                  countOutput += " BH:" + record.shippingItemsBrushHeadCount;
                }
                if (record.shippingItemsGamesSubCount !== undefined) {
                  countOutput += " G:" + record.shippingItemsGamesSubCount;
                }
                if (record.shippingItemsToothPasteCount !== undefined) {
                  countOutput += " T:" + record.shippingItemsToothPasteCount;
                }
                return countOutput;
              }}
            />
            <TextField label="Shipping State" source="shippingState" />
            <TextField label="Shipping Courier" source="shippingCourierService" />
            {/* <TextField label="Product Id" source="product.productId" />
          <ImageField
            label="Product Image"
            sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }}
            source="product.image"
          />
          <TextField label="Product Name" source="product.name" />
          */}
            {/* <EditButton /> */}
            <WrapperField label="Actions">
              <ShowButton resource="orders" />
            </WrapperField>
          </DatagridConfigurable>
        )}
      </>
    </Fragment>
  );
};
export default OrderList;
