import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CartList from "../components/cartList";
import { useOrderInfo } from "../hooks/useOrder";
import getTranslationJson from "../components/getTranslationJson";

// import { CustomerFormType } from "../globalState";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://playbrush.com/">
        Playbrush
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ThankYou() {
  let navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const queryCheckoutId = queryParams.get("checkoutId");
  const mutationOrderInfo = useOrderInfo();
  const [cart, setCart] = React.useState<GQL.CheckoutOpen | null>(null);

  useEffect(() => {
    if (queryCheckoutId !== null) {
      mutationOrderInfo.mutate(
        { checkoutId: queryCheckoutId },
        {
          onSuccess: (data) => {
            if (data.cart !== undefined) {
              setCart(data.cart);
            }
          },
        }
      );
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Thank you for your order from Playbrush
          </Typography>
          <React.Fragment>
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                {" "}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Your orderid is {cart?.orderId}
              </Typography>
              {/* <Typography variant="subtitle1">
                    Your order number is #2001539. We have emailed your order confirmation, and will send you an update
                    when your order has shipped.
                  </Typography> */}
              <Button
                onClick={() => navigate("/basket/")}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
              >
                New order back to Basket
              </Button>
              {cart && <CartList cart={cart} translation={getTranslationJson("en")} listType={"ORDER"} />}
            </React.Fragment>
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
