import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import { CountryDataType, RegionDataType } from "../globalState/global";

import { alpha, styled } from "@mui/material/styles";
import { useLocales } from "../hooks/useLocales";

type CountriesOptions = {
  code: string;
  name: string;
};
type StateOptions = {
  name: string;
  shortName?: string;
};
interface IOptions {
  options: CountriesOptions[];
}

// generage select dropdown option list dynamically
const CountryOptions: React.FC<IOptions> = ({ options }) => {
  return (
    <>
      {options.map((option) => (
        <option key={option.code} value={option.code}>
          {option.code} | {option.name}
        </option>
      ))}
    </>
  );
};

const CountryOptionsMaterial: React.FC<IOptions> = ({ options }) => {
  return (
    <>
      {options.map((option) => (
        <MenuItem key={option.code} value={option.code.toUpperCase()}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />{" "}
          {option.code} | {option.name}
        </MenuItem>
      ))}
    </>
  );
};

const countriesJson = require("../data/countries.json");

interface ICountrySelect {
  onSelect?: any;
  selectedCountry?: string;
  fullWidth?: any;
  required?: boolean;
  error?: boolean;
  helperText?: string | null;
  label?: string;
  inputRef?: any;
  onChange: any;
  value?: any;
  name?: any;
}

interface IStateSelect extends ICountrySelect {
  selectedState: string;
  regionsOptions?: any;
  inputRef?: any;
}

export const CountrySelect = ({ selectedCountry, onSelect }: ICountrySelect) => {
  const [selectedOption, setSelectedOption] = useState<String>();

  // This function is triggered when the select changes
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <>
      <select onChange={selectChange} name="countries" className="form-control">
        <CountryOptions options={countriesJson} />
      </select>
      {selectedOption && selectedOption}
    </>
  );
};

const RedditFormControl = styled((props: FormControlProps) => <FormControl {...props} />)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    fontFamily: "Gotham",
    fontWeight: "400",
    border: "1px solid #CDD4DC",
    overflow: "hidden",
    borderRadius: "16px",
    // color: "#8193A8",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#2b2b2b",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "none",
    },
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Gotham",
    fontWeight: "600",
    color: "#cdd4dc",
    // color: "#CDD4DC",
    // color: theme.palette.primary.main,
    "&.Mui-focused": {
      color: "#8193A8",
      // color: theme.palette.primary.main,
    },
  },
  "& .MuiSelect-select.MuiFilledInput-input": {
    paddingBottom: "8px",
  },
}));

export const CountrySelectMaterial = ({
  selectedCountry,
  onSelect,
  fullWidth,
  required,
  error,
  helperText,
  label,
  inputRef,
  onChange,
  value,
  name,
}: ICountrySelect) => {
  // const [selectedOption, setSelectedOption] = useState<string>(selectedCountry);
  // useEffect(() => {
  //   setSelectedOption(selectedCountry);
  // }, []);

  return (
    <>
      <Box>
        <RedditFormControl
          fullWidth
          variant="filled"
          sx={{ m: 0, minWidth: 200, backgroundColor: "transparent" }}
          size="small"
          error={error}
          required={required}
        >
          <InputLabel id="countryLabel">{label && (label || "Counrty")}</InputLabel>
          <Select
            labelId="countryLabel"
            id="country"
            name="country"
            value={value}
            fullWidth
            onChange={(event: SelectChangeEvent | any) => {
              // setSelectedOption(event.target.value);
              // setSelectedOption(event.target.value);
              if (onSelect) {
                onSelect(event.target.value);
              }
              onChange(event.target.value);
            }}
            inputRef={inputRef}
            autoComplete="country"
          >
            <MenuItem key="null" value="">
              <em>None</em>
            </MenuItem>
            <MenuItem key="DE_" value="DE">
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${"DE".toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${"DE".toLowerCase()}.png 2x`}
                alt=""
                style={{ marginRight: "10px" }}
              />
              Deutschland (DE)
            </MenuItem>
            <MenuItem key="AT_" value="AT">
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${"AT".toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${"AT".toLowerCase()}.png 2x`}
                alt=""
                style={{ marginRight: "10px" }}
              />
              Österreich (AT)
            </MenuItem>
            <MenuItem key="GB_" value="GB">
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${"GB".toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${"GB".toLowerCase()}.png 2x`}
                alt=""
                style={{ marginRight: "10px" }}
              />
              United Kingdom (GB)
            </MenuItem>
            <MenuItem key="FR_" value="FR">
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${"FR".toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${"FR".toLowerCase()}.png 2x`}
                alt=""
                style={{ marginRight: "10px" }}
              />
              France (FR)
            </MenuItem>
            <MenuItem key="IT_" value="IT">
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${"IT".toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${"IT".toLowerCase()}.png 2x`}
                alt=""
                style={{ marginRight: "10px" }}
              />
              Italy (IT)
            </MenuItem>
            {countriesJson
              .sort(function (a: CountriesOptions, b: CountriesOptions) {
                return a.name.localeCompare(b.name);
              })
              .map((option: CountriesOptions) => (
                <MenuItem key={option.code} value={option.code.toUpperCase()}>
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />{" "}
                  {option.name} ({option.code})
                </MenuItem>
              ))}
          </Select>
          {error && <FormHelperText>{helperText || "Required"}</FormHelperText>}
        </RedditFormControl>
      </Box>
      {/* <select onChange={selectChange} name="countries" className="form-control">
        <CountryOptions options={countriesJson} />
      </select>
      {selectedOption && selectedOption} */}
    </>
  );
};

export const LocaleSelectMaterial = ({
  selectedCountry,
  onSelect,
  fullWidth,
  required,
  error,
  helperText,
  label,
  inputRef,
  onChange,
  value,
  name,
}: ICountrySelect) => {
  // const [selectedOption, setSelectedOption] = useState<string>(selectedCountry);
  // useEffect(() => {
  //   setSelectedOption(selectedCountry);
  // }, []);

  const queryLocales = useLocales();

  return (
    <>
      <Box>
        <RedditFormControl
          fullWidth
          variant="filled"
          sx={{ m: 0, minWidth: 300, backgroundColor: "transparent" }}
          size="small"
          error={error}
          required={required}
        >
          <InputLabel id="countryLabel">{label && (label || "Counrty")}</InputLabel>
          {queryLocales.isLoading ? (
            <>Loading...</>
          ) : (
            <Select
              labelId="countryLabel"
              id="country"
              name="country"
              value={value}
              fullWidth
              onChange={(event: SelectChangeEvent | any) => {
                // setSelectedOption(event.target.value);
                // setSelectedOption(event.target.value);
                if (onSelect) {
                  onSelect(event.target.value);
                }
                onChange(event.target.value);
              }}
              inputRef={inputRef}
              autoComplete="country"
            >
              {queryLocales.data!.length > 0 ? (
                queryLocales.data!.map((locale) => {
                  return (
                    <MenuItem key={locale.id} value={JSON.stringify(locale)}>
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${locale.country.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${locale.country.toLowerCase()}.png 2x`}
                        alt=""
                        style={{ marginRight: "10px" }}
                      />
                      {locale.countryFull} {locale.defaultLanguage}-{locale.country} {locale.defaultCurrency}
                    </MenuItem>
                  );
                })
              ) : (
                <div style={{ fontSize: 20, fontWeight: "bold" }}>No Item found</div>
              )}
            </Select>
          )}
          {error && <FormHelperText>{helperText || "Required"}</FormHelperText>}
        </RedditFormControl>
      </Box>
      {/* <select onChange={selectChange} name="countries" className="form-control">
        <CountryOptions options={countriesJson} />
      </select>
      {selectedOption && selectedOption} */}
    </>
  );
};

export const StateSelectMaterial = ({
  selectedCountry,
  selectedState,
  onSelect,
  fullWidth,
  required,
  error,
  helperText,
  label,
  inputRef,
  onChange,
  regionsOptions,
}: IStateSelect) => {
  const [selectedOption, setSelectedOption] = useState<string>(selectedState);
  // useEffect(() => {
  //   setSelectedOption(selectedCountry);
  // }, []);

  return (
    <>
      <Box>
        <RedditFormControl
          fullWidth
          variant="filled"
          sx={{ m: 0, minWidth: 200, backgroundColor: "transparent" }}
          size="small"
          error={error}
          required={required}
        >
          <InputLabel id="countryLabel">{label && (label || "Counrty")}</InputLabel>
          <Select
            labelId="countryLabel"
            id="state"
            name="state"
            value={selectedOption}
            fullWidth
            onChange={(event: SelectChangeEvent | any) => {
              setSelectedOption(event.target.value);
              onChange(event.target.value);
            }}
            inputRef={inputRef}
          >
            <MenuItem key="null" value="">
              <em>None</em>
            </MenuItem>
            {regionsOptions
              // .sort(function (a: CountriesOptions, b: CountriesOptions) {
              //   return a.name.localeCompare(b.name);
              // })
              .map((option: StateOptions) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
          {error && <FormHelperText>{helperText || "Required"}</FormHelperText>}
        </RedditFormControl>
      </Box>
      {/* <select onChange={selectChange} name="countries" className="form-control">
        <CountryOptions options={countriesJson} />
      </select>
      {selectedOption && selectedOption} */}
    </>
  );
};
