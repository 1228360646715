import Typography from "@mui/material/Typography";
import {
  AutocompleteInput,
  CreateButton,
  DatagridConfigurable,
  Edit,
  EditButton,
  FilterButton,
  Labeled,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SearchInput,
  SelectColumnsButton,
  Show,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  WrapperField,
} from "react-admin";
import LocalisedDateField from "../components/localisedDateField";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const userFilters = [
  <SearchInput source="name__icontains" alwaysOn={true} placeholder="Name" />,
  <SearchInput source="email__icontains" alwaysOn={true} placeholder="Email" />,
];

export const InternalUserList = (props: any) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      filters={userFilters}
      title={"Internal Users"}
      sort={{ field: "updated_at", order: "DESC" }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="name" />
        <ReferenceField label="Group" reference="internalUserGroups" source="groupId" />
        <WrapperField label="Actions">
          <ShowButton />
          <EditButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export const InternalUserEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput disabled source="email" validate={required()} />
        <ReferenceInput source="groupId" reference="internalUserGroups">
          <AutocompleteInput
            source="id"
            sx={{ minWidth: "300px" }}
            optionText={(record) => `${record.name}`}
            filterToQuery={(query) => {
              return { name__icontains: query };
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const InternalUserShowAside = () => {
  const record = useRecordContext();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Metadata</Typography>
      <p>
        Created <LocalisedDateField record={record} field="createdAt" showTime={true} />
      </p>
      <p>
        Updated <LocalisedDateField record={record} field="updatedAt" showTime={true} />{" "}
      </p>
    </div>
  );
};

export const InternalUserShow = () => {
  return (
    <Show aside={<InternalUserShowAside />}>
      <SimpleForm>
        <Labeled>
          <TextField source="name" validate={required()} />
        </Labeled>
        <Labeled>
          <TextField source="email" validate={required()} />
        </Labeled>
        <Labeled>
          <ReferenceField label="Group" reference="internalUserGroups" source="groupId" />
        </Labeled>
      </SimpleForm>
    </Show>
  );
};
