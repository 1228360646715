import { Box, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ArrayField,
  ArrayInput,
  Datagrid,
  SimpleFormIterator,
  TextInput,
  TextField,
  required,
  useInput,
  ImageField,
  SingleFieldList,
  ChipField,
  FunctionField,
} from "react-admin";
import DatagridUnSelect from "./datagridUnSelect";

const isUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};

const ImagePreviewField = ({ source }: any) => {
  const { field } = useInput({ source });
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (field.value && isUrl(field.value)) {
      setImageUrl(field.value);
    }
  }, [field.value]);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
      {imageUrl ? (
        <Tooltip
          title={"View ↗ (opens in new tab)"}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
        >
          <a href={imageUrl} target="_blank">
            <img src={imageUrl} alt="Preview" style={{ width: 50, height: 50, marginRight: 2 }} />
          </a>
        </Tooltip>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mt={2}
          sx={{ width: 50, height: 50, marginRight: 2 }}
        ></Box>
      )}
    </Box>
  );
};

const ImageArrayInputWithPreview = (props: any) => (
  <ArrayInput fullWidth label="Images" source={props.source}>
    <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`} inline>
      <ImagePreviewField source="" />
      <TextInput label="Image URL" source="" validate={required()} sx={{ width: "80%" }} type="url" defaultValue={""} />
    </SimpleFormIterator>
  </ArrayInput>
);

export const ImageArrayFieldWithPreview = (props: any) => (
  <ArrayField label="Images" source={props.source}>
    <SingleFieldList>
      <FunctionField
        sx={{
          "& img": {
            maxWidth: 100,
            maxHeight: 50,
            objectFit: "contain",
          },
        }}
        label="Image Preview"
        render={(record: any) => {
          return <img src={record} />;
        }}
      />
      <FunctionField
        label="Image URL"
        render={(record: any) => {
          return record;
        }}
      />
    </SingleFieldList>
    {/* DatagridUnSelect not working becaues the data array not a object list */}
    {/* <DatagridUnSelect>
      <FunctionField
        sx={{
          "& img": {
            maxWidth: 100,
            maxHeight: 50,
            objectFit: "contain",
          },
        }}
        label="Image Preview"
        render={(record: any) => {
          return <img src={record} />;
        }}
      />
      <FunctionField
        label="Image URL"
        render={(record: any) => {
          return record;
        }}
      />
    </DatagridUnSelect> */}
  </ArrayField>
);

export default ImageArrayInputWithPreview;
