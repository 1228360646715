import * as React from "react";
import { useState } from "react";
import { BasketContext } from "./basketContext";
import { BasketData, BasketItem } from "./basketData";

export default function BasketProvider({ children }: { children?: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<BasketItem[]>([]);

  const setInitialData = (basketItem: BasketItem[]): BasketItem[] => {
    setItems(basketItem);
    return items;
  };

  const onAllItemsDeleted = (): BasketItem[] => {
    setItems([]);
    return items;
  };

  const onItemAdded = (item: BasketItem): Boolean => {
    let _items = [...items];
    let index = _items.findIndex((_item) => _item.id === item.id);
    if (index > -1) {
      _items[index].quantity++;
    } else {
      item.quantity = 1;
      _items.push(item);
    }
    setItems(_items);

    return true;
  };

  const onItemDeleted = (id: string): Boolean => {
    let _items = [...items];
    const index = _items.findIndex((item) => item.id === id);
    if (index > -1) {
      _items.splice(index, 1);
    }
    setItems(_items);
    return true;
  };

  const providerValue = {
    items,
    setInitialData,
    onAllItemsDeleted,
    onItemAdded,
    onItemDeleted,
  };

  return <BasketContext.Provider value={providerValue}>{children}</BasketContext.Provider>;
}
