import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { request, gql, ClientError, GraphQLClient } from "graphql-request";
import { apiBaseUrl, graphQLClient, __DEV__ } from "../api";
import moment from "moment";

export function useNewsletterSubscribe(): UseMutationResult<
  GQL.NewsletterSubscribe,
  ClientError,
  GQL.MutationNewsletterSubscribeArgs
> {
  return useMutation(
    async (dataMutation) => {
      const data = await graphQLClient.request(
        gql`
            mutation {
              newsletterSubscribe(
                email: "${dataMutation.email}",
                firstName: "${dataMutation.firstName}",
                lastName: "${dataMutation.lastName}",
              ) {
                success
              }
            }
        `
      );
      if (__DEV__) {
        console.log(data);
      }
      return data.newsletterSubscribe;
    },
    {
      onSuccess: (data, variables, context) => {},
      onError: (error: ClientError, variables, context) => {},
    }
  );
}
