import { IconButton, useTheme, Box } from "@mui/material";
import { ColorModeContext } from "../context/checkoutContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import React from "react";

const ThemeToggle = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Box
      sx={{
        // display: "flex",
        // width: "100%",
        // alignItems: "center",
        // justifyContent: "center",
        bgcolor: "background.default",
        // color: "text.primary",
        color: "#CDD4DC",
        borderRadius: 1,
        p: 3,
      }}
    >
      {/* {theme.palette.mode} mode */}
      <IconButton
        sx={{ ml: 1, "&:hover": { color: "text.primary" } }}
        onClick={colorMode.toggleColorMode}
        color="inherit"
      >
        {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
};
export default ThemeToggle;
