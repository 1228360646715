import * as React from "react";
import { MouseEvent, useState } from "react";
import { Box, Button, Menu, MenuItem, styled } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const DropDownMenu = (props: DropDownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const label = props.label || "Menu";
  const icon = props.icon || <MoreVertIcon />;

  const handleOpenClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Root component="span">
      <Button
        // color="inherit"
        // aria-controls="simple-menu"
        // aria-label=""
        // aria-haspopup="true"
        size="small"
        onClick={handleOpenClick}
        startIcon={icon}
        // endIcon={<ExpandMoreIcon fontSize="small" />}
      >
        {label}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.children && props.children.map((menuItem, index) => <MenuItem key={index}>{menuItem}</MenuItem>)}
      </Menu>
    </Root>
  );
};

export const DropDownMenuClasses = {};

const Root = styled(Box, {
  overridesResolver: (props, styles) => styles.root,
})({
  "&.MuiButton-sizeSmall": {
    lineHeight: 1.5,
  },
});

export interface DropDownMenuProps {
  children?: React.ReactNode[];
  icon?: React.ReactNode;
  label?: string;
}
