import axios from "axios";
import { GraphQLClient } from "graphql-request";

export const processENV = {
  REACT_APP_KIDS_API_URL: process.env.REACT_APP_KIDS_API_URL,
  REACT_APP_SITE_URL: process.env.REACT_APP_SITE_URL,
  REACT_APP_DEV: process.env.REACT_APP_DEV,
  REACT_APP_ENV_NAME: process.env.REACT_APP_ENV_NAME,
  REACT_APP_STRIPE_CLIENT_SECRET: process.env.REACT_APP_STRIPE_CLIENT_SECRET,
  REACT_APP_STRIPE_API_VERSION: "2023-10-16",
  REACT_APP_AEM_BASIC_AUTH_USER: process.env.REACT_APP_AEM_BASIC_AUTH_USER,
  REACT_APP_AEM_BASIC_AUTH_PASS: process.env.REACT_APP_AEM_BASIC_AUTH_PASS,
};

export const apiBaseUrl = processENV.REACT_APP_KIDS_API_URL + "/gql/v1/graphql";
export const apiRestUrl = processENV.REACT_APP_KIDS_API_URL + "/rest";
export const refreshTokenName = "PB_RefreshToken";
export const refreshTokenExpiresInName = "PB_RefreshTokenExpiresIn";
export const __DEV__ = processENV.REACT_APP_DEV === "true" ? true : false;
export const __ENV__ = processENV.REACT_APP_ENV_NAME;
export const language_codes = ["EN", "DE", "FR", "IT"];
export const allowed_shipping_countries = ["DE", "AT", "GB", "IT", "FR"];

export enum ENV {
  DEV = "dev",
  STAGING = "staging",
  PRODUCTION = "production",
}

export const braintree_link =
  __ENV__ === ENV.PRODUCTION
    ? "https://www.braintreegateway.com/merchants/bk2psk76qfjk8z3c/transactions/"
    : "https://sandbox.braintreegateway.com/merchants/22m4vgccxmfsvzn4/transactions/";

export const stripe_link =
  __ENV__ === ENV.PRODUCTION ? "https://dashboard.stripe.com/" : "https://dashboard.stripe.com/test/";

export enum PAGEVIEW {
  BASKET = "/checkout/basket",
  CHECKOUT_BEGIN = "/checkout/begin-checkout",
  CHECKOUT_EXPRESS = "/checkout/express-checkout",
  CHECKOUT_REGULAR = "/checkout/regular-checkout",
  CHECKOUT_PAYMENT_VALIDATION = "/checkout/payment-validation-checkout",
}

export const adobe_datalayer_launch_link =
  __ENV__ === ENV.PRODUCTION
    ? "https://assets.adobedtm.com/467469cdd595/b1abdcb6d7b1/launch-ce1369fbe7a9.min.js"
    : __ENV__ === ENV.STAGING
    ? "https://assets.adobedtm.com/467469cdd595/b1abdcb6d7b1/launch-674ea929dd4f-staging.min.js"
    : __ENV__ === ENV.DEV
    ? "https://assets.adobedtm.com/467469cdd595/b1abdcb6d7b1/launch-d4b7a93ee6c7-development.min.js"
    : "";

const endpoint = apiBaseUrl;

const getAccessKey = () => {
  let cookie = localStorage.getItem("PB_Auth");
  if (cookie) {
    const { state } = JSON.parse(cookie);
    return state;
  }
  return "";
};

export const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    authorization: getAccessKey(),
  },
});

export const graphQLClientPrismic = new GraphQLClient("https://playbrush.cdn.prismic.io/graphql", {
  method: "GET",
});

export const axios_client = axios.create({
  baseURL: processENV.REACT_APP_KIDS_API_URL,
  // timeout: 1000,
  // headers: {'Content-Type':'application/json'}
});

export const encodeBase64 = (data: string) => {
  return Buffer.from(data).toString("base64");
};
export const decodeBase64 = (data: string) => {
  return Buffer.from(data, "base64").toString("ascii");
};
