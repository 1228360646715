import React from "react";
import { useCallback } from "react";

import {
  ArrayField,
  ArrayInput,
  AutocompleteArrayInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  EmailField,
  Labeled,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  ShowBase,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  ToolbarProps,
  useCreate,
  useUpdate,
  useNotify,
  useRedirect,
  FunctionField,
  ImageField,
  NumberInput,
  DeleteButton,
  BulkDeleteButton,
  DateTimeInput,
} from "react-admin";
import Currency from "../components/currency";
import DatagridUnSelect from "../components/datagridUnSelect";
import Grid from "@mui/material/Grid";

const ReferralCodeMessageFilters = [
  //   <TextInput label="ID" source="pk" alwaysOn />,
  <TextInput label="Suared Message" source="sharedMessage__contains" alwaysOn />,
  // <TextInput label="Description" source="description__contains" alwaysOn />,

  // <TextInput label="Email" source="email__contains" defaultValue="" />,
  // <DateInput label="Date from" source="created_at__gte" alwaysOn defaultValue="" />,
  // <DateInput label="Date to" source="created_at__lte" alwaysOn defaultValue="" />,
];

const ReferralCodeMessageBulkActionButtons = () => (
  <>
    <BulkDeleteButton label="Archive & Deactivate" />
  </>
);

const MyToolbar = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props}>
      {/* <DeleteButton label="Archive & Deactivate" /> */}
      <SaveButton />
    </Toolbar>
  );
};

// active?: Maybe<Scalars["Boolean"]>;
// category?: Maybe<Scalars["String"]>;
// couponCode?: Maybe<Scalars["String"]>;
// createdAt?: Maybe<Scalars["DateTime"]>;
// currency?: Maybe<Scalars["String"]>;
// description?: Maybe<Scalars["String"]>;
// id: Scalars["ID"];
// modifiedAt?: Maybe<Scalars["DateTime"]>;
// percentage?: Maybe<Scalars["Float"]>;
// stripeCouponCodeId?: Maybe<Scalars["String"]>;
// stripePromotionCodeId?: Maybe<Scalars["String"]>;
// value?: Maybe<Scalars["Int"]>;

export const ReferralCodeListMessage = () => (
  <List filters={ReferralCodeMessageFilters} bulkActionButtons={<ReferralCodeMessageBulkActionButtons />}>
    <Datagrid>
      {/* rowClick="edit" */}
      <DateField source="createdAt" label="Created" />
      <TextField source="sharedMessage" label="Shared Message" />

      <TextField source="bannerMessage" label="Game Page Banner Headline" />
      <DateField source="validTill" label="Valid Till" />
      <TextField source="amountPercents" label="% discount amount" />
      <TextField source="amountEur" label="Amount" />
      <TextField source="currency" label="Currency" />
      <TextField source="language" label="Language" />
      {/* <TextInput fullWidth label="Currency" source="currency" /> */}
      {/* <TextInput fullWidth label="Country" source="country" /> */}

      <EditButton />
    </Datagrid>
  </List>
);

export const ReferralCodeMessageEdit = () => {
  const [update, { isLoading, error }] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      try {
        await update(
          "referralCodeMessage",
          { id: values.id, data: values, previousData: values },
          { returnPromise: true }
        );
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );
  return (
    <Edit>
      <SimpleForm toolbar={<MyToolbar />}>
        <SimpleShowLayout>
          <TextField source="createdAt" label="Created At" />
          <Labeled label="Game Page Banner Headline">
            <TextInput source="bannerMessage" label="Game Page Banner Headline" />
          </Labeled>
          <TextInput fullWidth multiline rows={4} source="sharedMessage" InputLabelProps={{ shrink: true }} />
          <Labeled label="Valid Until">
            <DateTimeInput fullWidth label="Valid Until" source="validTill" defaultValue="" />
          </Labeled>
          <Labeled label="Discount Amount">
            <TextInput fullWidth label="Discount Amount" source="amountEur" />
          </Labeled>
          <Labeled label="% discount amount">
            <TextInput fullWidth label="% discount amount" source="amountPercents" />
          </Labeled>
          <Labeled label="Currency">
            <SelectInput
              source="currency"
              choices={[
                { id: "GBP", name: "GBP" },
                { id: "EUR", name: "EUR" },
                { id: "USD", name: "USD" },
              ]}
            />
            {/* <TextInput fullWidth label="Currency" source="currency" /> */}
          </Labeled>
          <SelectInput
            source="language"
            choices={[
              { id: "en", name: "en" },
              { id: "de", name: "de" },
            ]}
          />
        </SimpleShowLayout>
      </SimpleForm>
    </Edit>
  );
};

export const ReferralCodeMessageCreate = () => {
  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("referralCodeMessage", { data: values }, { returnPromise: true });
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );
  return (
    <Create>
      <SimpleForm>
        <Labeled label="Shared Message">
          <TextInput
            label="Shared Message"
            source="sharedMessage"
            fullWidth
            multiline
            rows={4}
            InputLabelProps={{ shrink: true }}
          />
        </Labeled>

        <Labeled label="Game Page Banner Headline">
          <TextInput fullWidth label="Game Page Banner Headline" source="bannerMessage" />
        </Labeled>

        <Labeled label="Valid Until">
          <DateTimeInput fullWidth label="Valid Until" source="validTill" />
        </Labeled>
        <Labeled label="Amount">
          <TextInput fullWidth label="Discount Amount" source="amountEur" />
        </Labeled>
        <Labeled label="Currency">
          <SelectInput
            source="currency"
            choices={[
              { id: "GBP", name: "GBP" },
              { id: "EUR", name: "EUR" },
              { id: "USD", name: "USD" },
            ]}
          />
          {/* <TextInput fullWidth label="Currency" source="currency" /> */}
        </Labeled>
        <Labeled label="% discount amount">
          <TextInput fullWidth label="% discount amount" source="amountPercents" />
        </Labeled>
        <Labeled label="Language">
          <SelectInput
            source="language"
            choices={[
              { id: "en", name: "en" },
              { id: "de", name: "de" },
            ]}
          />
        </Labeled>
      </SimpleForm>
    </Create>
  );
};
